

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import CountUp from 'react-countup';
import { COLORS } from '../../../Constants';

const Powerfulltools = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    // document.title = `About Us - OfferBorn`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section style={{ marginTop: 50 ,backgroundColor:'white'}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Powerful Tools
                </h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" >

                        <figure class="hero-banner">
                            <img src={require('../../img/tools2.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Dynamic & Percentage Based Payout
</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Now you can set dynamic Payout or percentage based payout system for offers and affiliates, as per offer wise or group wise easily. We understand different advertisers have different type of payment structure and we are ready to adapt fast with ever changing affiliate industry standards Optimize Payouts Today
                                
                            </p>



                        </div>
                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/pricing1.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>CAP Limit Function
</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            If you don't want the affiliates to go on a particular offer, you can apply a cap on it. This will stop them from going on it. A cap is applied when you want to prevent an affiliate from taking up an offer. There can be various reasons behind putting up that cap. This function of OfferBorn saves a lot of time and energy for both parties. Maintain Your Quality Standards
                                
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'ltr',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/tracking-invoice.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Automatic Affiliate Invoice Creation
</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            No need to create manual invoices for your affiliate, just set automatic Invoice Creation and all invoices will be automatically created from time to time, saving a huge amount of time for Network & Affiliate managers. Simplify Your Invoicing Process
                              
                                
                            </p>



                        </div>
                    </div>
                </section>

            



                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/multi-currency.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Multi - Currency

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Now you can run and work with offers & advertisers in multiple currency. We accept almost all major currencies. Go Global with Confidence
                                
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'ltr',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/smart-alert-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Smart Alert System

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            The smart alert system gives notifications in case of any new activity which happens in the system. This feature of OfferBorn makes you aware of what is really going on in your system so that you can take the required actions when needed. You will simply get a pop-up, in case any new activity happens in your system. Activate Instant Alerts
                              
                                
                            </p>



                        </div>
                    </div>
                </section>







            





            </div>
        </section>
    )
}

export default Powerfulltools