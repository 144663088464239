

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import CountUp from 'react-countup';
import { COLORS } from '../../../Constants';

const Reposrtsmanagpage = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    // document.title = `About Us - OfferBorn`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section style={{ marginTop: 50 ,backgroundColor:'white'}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Smart Communication & Reports
                </h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" >

                        <figure class="hero-banner">
                            <img src={require('../../img/reports2.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Reporting Features

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            You can get access to 80+ reporting features with email automation and many more.
                            <br/>
                            <br/>
                            Stay connected with your partners every time and everywhere. There is absolutely no issue with interacting with your associates through email, as we provide various email automation features. This will facilitate the reporting process and make things easier. Discover In-Depth Insights
                                
                            </p>



                        </div>
                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/mannual-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Real Time Reports

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            You get an opportunity to choose among 80+ comparisons and detailed reports for all your networks. Get to use the real-time data to build up your strategies and operate your networks towards growth. React with Real-Time Data
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'ltr',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/email.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Email Marketing for
Affiliates

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Send different types of emails from promotional to offers emails to all your affiliates one by one, in groups, or all at once. Our smart email marketing tool helps you do that in just a few clicks. Boost Your Affiliate Engagement
                                
                            </p>



                        </div>
                    </div>
                </section>

            



                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/invoice.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Automatic Affiliate Invoice creation



</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            OfferBorn offers a cutting-edge feature that simplifies your financial operations like never before. Our platform automates the creation of affiliate invoices, allowing you to manage your affiliate payouts effortlessly. What sets this feature apart is its flexibility in defining payment terms, including Net3, Net7, Net10, Net15, and Net30. Simplify Your Invoicing Process
                            </p>



                        </div>
                    </div>
                </section>

              






            





            </div>
        </section>
    )
}

export default Reposrtsmanagpage