import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'

import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { fetchcampaign } from '../../../Redux/Actions/Agency/campaignagencyaction'

const Maincampaign = () => {

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)
   
    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(10)
    const [search,setsearch] =useState('')
  
    const [openmodal,setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)
  
   
  
      const [myid, setmyid] = useState(null)
  
      const testwithadd = (name) => {
        const datata = userinfo?.accesspermision?.filter((val) => val?.name == name)[0]
        return datata
    }
    useEffect(() => {
      callforAPI()
    }, [limit])
  
    const callforAPI = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/employee/getcampaigns?limit=${limit}&search=${search}`,{headers:{
          "Authorization":`Beaer ${token}`
      }}).then((ress) => {
  
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
      setloading(false)
  
    }
  
    const handlepaginate = async (number) => {
      // console.log(number)
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/employee/getcampaigns?limit=${limit}&search=${search}&page=${number - 1}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
        console.log('api', ress.data)
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
      setloading(false)
    }
  
    const onNextClick = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/employee/getcampaigns?limit=${limit}&search=${search}&page=${statsdata.page + 1}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
  
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
  
      setloading(false)
    }
  
    const handleChnageSelect = async (e) => {
      setlimit(e)
  }
  
    const onPrevClick = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/employee/getcampaigns?limit=${limit}&search=${search}&page=${statsdata.page - 1}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
  
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
      setloading(false)
    }



    const TABLE_HEAD = ["ID", "Title", "Status", "Advertiser", "Category", "Visibility", "Model", "PO", "REV", "Action"];

    const onEditUser = (id) => {
        navigate(`/p/${platformurl}/employee/updatecamp/${id}`)
    }

    const onDeleteUser = (id) => {
        setopenpop(true)

        setmyid(id)
    }

    const handleDeleteAdv = async () => {
        setloading(true)
        await gpeapi.delete(`/${platformurl}/employee/deletecampaign/${myid}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                toast.success('Campaigns Deleted Done')

                callforAPI()


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setopenpop(false)
        setmyid(null)
        setloading(false)
    }

    

    const handleTitleclik = (id) => {
        navigate(`/p/${platformurl}/employee/viewcamp/${id}`)
    }


    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
            {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



            <ToastContainer />




            <Mypopup handleoutopen={() => setmyid(null)} title={`Are you Sure`} des={`you want to delete id ${myid}?`} open={openpop} setopenpop={setopenpop} onConfirm={handleDeleteAdv} />



            <p className='text-2xl font-medium'>MANAGE CAMPAIGNS</p>

{
    testwithadd('Campaigns')?.create && 
            <div>
                <Mybtn onClick={() => navigate(`/p/${platformurl}/employee/createcamp`)} title={'ADD NEW'} padding={'0px 15px'} radius={12} fontsize={14} />
            </div>
}


            {
                loading ? <div className='flex justify-center items-center'>
                    <Spinner className="h-10 w-10  text-blue-500/10" />
                </div> : (
                    statsdata && statsdata?.docs?.length == 0 ? <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography> :
                        <div>
                            <CardBody className="overflow-scroll px-0">
                                <table className="w-full min-w-max table-auto text-left">
                                    <thead>
                                        <tr>
                                            {TABLE_HEAD.map((head) => (
                                                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70"
                                                    >
                                                        {head}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statsdata && statsdata?.docs?.map(
                                            (val, index) => {
                                                const isLast = index === statsdata?.docs?.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                return (
                                                    <tr key={index}>
                                                        <td className={classes}>


                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                {val?.id}
                                                            </Typography>

                                                        </td>
                                                        <td className={classes}>
                                                            <Typography onClick={() => handleTitleclik(val.id)} variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                {val?.title}
                                                            </Typography>
                                                        </td>

                                                        <td className={classes}>
                                                            <div className="w-max">
                                                                <Chip
                                                                    size="sm"
                                                                    variant="ghost"
                                                                    value={val?.status}
                                                                    color={
                                                                        val?.status === "active" ? "green" : val?.status === "pending" ? "amber" : "red"
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                {val?.advertiser}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.category}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.visibility}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.campaignmodel}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                ₹{val?.payout}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                ₹{val?.revenue}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            {
                                                                testwithadd('Campaigns')?.update &&
                                                            <Tooltip content="Edit">
                                                                <IconButton onClick={() => onEditUser(val.id)} variant="text" color="blue-gray">
                                                                    <PencilIcon className="h-4 w-4" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            }
                                                            {
                                                                testwithadd('Campaigns')?.delete &&
                                                            <Tooltip content="Delete">
                                                                <IconButton onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                                    <TrashIcon className="h-4 w-4" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            },
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>

                            <Mypaginate
                                onNextClick={onNextClick}
                                onPrevClick={onPrevClick}
                                paginate={handlepaginate}
                                currentPage={statsdata?.page}
                                totalData={statsdata?.total}
                                perPage={statsdata?.limit}
                            />



                        </div>
                )
            }






        </div>
    )
}

export default Maincampaign