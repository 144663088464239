
import React, { useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";

import { useState } from 'react'
import { COLORS } from '../Constants';
import { Authcontext } from '../Context/Authcontext';
import { useSelector } from 'react-redux';





const Mysidebar = ({menus}) => {

    const {platformurl} = useParams()

    const basicdata = useSelector((state)=>state.basicReducer)[0]
  
    const { toggle, settoggle } = useContext(Authcontext)

    const [open, setOpen] = useState(true);

    const [opensub, setopensub] = useState({
        name:'',
        isopen:false
    })

    // const menus = [
    //     { name: "dashboard", link: "/", icon: MdOutlineDashboard },
    //     {
    //         name: "campaigns", link: "/", icon: AiOutlineUser, submenu: [
    //             { name: "all campaigns", link: "/" },
    //             { name: "approved campaigns", link: "/" },
    //             { name: "need approval", link: "/" },
    //         ]
    //     },
    //     { name: "reports", link: "/", icon: FiMessageSquare , submenu: [
    //         { name: "conversion report", link: "/" },
    //         { name: "clicks report", link: "/" },
    //         { name: "sub-id reports", link: "/" },
    //     ]},
    //     { name: "analytics", link: "/", icon: TbReportAnalytics, margin: true },
    //     { name: "File Manager", link: "/", icon: FiFolder },
    //     { name: "Cart", link: "/", icon: FiShoppingCart },
    //     { name: "Saved", link: "/", icon: AiOutlineHeart, margin: true },
    //     { name: "Setting", link: "/", icon: RiSettings4Line },
    // ];

    // useEffect(() => {
    //     setOpen(toggle)


    // }, [toggle])

    // console.log(opensub)

    useEffect(()=>{
        settoggle(!open)
    },[open])

    return (
        <div>





            {/* {loading && <Loadingmy />} */}

            <div class="navigation" >

                


                <div
                style={{backgroundColor:COLORS.primary,display:'flex',flexDirection:'column',position:'relative',marginBottom:80}}
                    className={` min-h-screen ${open ? "w-60" : "w-16"
                        } duration-500 text-gray-100 px-4`}
                >
                    {/* <div className="py-3 flex justify-end">
                        <HiMenuAlt3
                            size={26}
                            className="cursor-pointer"
                            onClick={() => setOpen(!open)}
                        />
                    </div> */}

                    <div>

                    <div className='flex justify-center'>
                        <p className={` uppercase text-white font-bold ${open? platformurl?.length>15?'text-base':'text-xl':'text-xs'}  `}>{platformurl}</p>
                    </div>

                    <div  className="mt-4 flex flex-col gap-4 relative">
                        {menus?.map((menu, i) => (
                            menu?.submenu ? (
                                <div key={i} className={opensub?.name==menu?.name && opensub?.isopen && 'bg-gray-700 rounded-md'}>
                                    <div
                                        onClick={() => setopensub({name:menu?.name,isopen:opensub?.name==menu?.name ?!opensub?.isopen : true})}
                                        key={i}
                                        className={` ${menu?.margin && "mt-5"
                                            } group cursor-pointer inline-flex justify-between w-full text-sm  gap-3.5 font-medium p-2 hover:bg-gray-700 rounded-md`}
                                    >
                                        <div style={{ display: 'flex' }} className='text-sm  gap-3.5'>
                                            <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                                            <h2
                                                style={{
                                                    transitionDelay: `${i + 3}00ms`,
                                                }}
                                                className={`capitalize whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden "
                                                    }`}
                                            >
                                                {menu?.name}
                                            </h2>

                                        </div>

                                        <div >{React.createElement( opensub?.name==menu?.name && opensub?.isopen ? IoIosArrowDown :IoIosArrowForward , { size: "20" })}</div>



                                    </div>

                                    {
                                        opensub?.name==menu?.name && opensub?.isopen &&
                                        <div className={`group cursor-pointer flex flex-col justify-between w-full text-sm  gap-3.5 font-medium p-2 bg-gray-700 rounded-md`}>
                                            {menu?.submenu?.map((val,i)=>(
                                                <Link
                                                to={val?.link}
                                                key={i}
                                                className={`
                                                group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-400 rounded-md capitalize`}
                                            >
                                                {val?.name}
                                            </Link>
                                            ))}
                                        </div>
                                    }

                                   

                                </div>

                            ) :
                                <Link
                                    to={menu?.link}
                                    key={i}
                                    className={` ${menu?.margin && "mt-5"
                                        } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-700 rounded-md`}
                                >
                                    <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                                    <h2
                                        style={{
                                            transitionDelay: `${i + 3}00ms`,
                                        }}
                                        className={`capitalize whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden "
                                            }`}
                                    >
                                        {menu?.name}
                                    </h2>
                                    <h2
                                        className={`${open && "hidden"
                                            } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  capitalize`}
                                    >
                                        {menu?.name}
                                    </h2>
                                </Link>
                        ))}
                    </div>
                    </div>

                    <div style={{position:'fixed',bottom:0,left:0,right:0,width:230}} className='bg-white flex justify-center p-2'>
                        <a href='https://offerborn.com/' target='_blank' className={` cursor-pointer capitalize text-black  ${open?'text-xs':'text-xs'}  `}> powered by <span className=' font-bold text-[#292e57]'>OfferBorn</span></a>
                    </div>



                    
                </div>
            </div>

        </div>
    )
}

export default Mysidebar
