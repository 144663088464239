import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Myselect from '../../../COmponents/Myselect'
import { COLORS } from '../../../Constants'

const GoalComponew = ({ setmodal, id, onaddgoalFun,data,onupdategoal }) => {

    const [formValues, setFormValues] = useState({ id: '', title: "", value: "", currency: '', rev: '', po: '' })

    // console.log(data)
    const handleChnageGaols = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormValues({ ...formValues, [name]: value })
    }
    const handleChnageSelectGoal = (e, name) => {
        setFormValues({ ...formValues, [name]: e })

    }

    useEffect(()=>{
        data && setFormValues(data)
    },[id])

    function makeid(length) {
        let result = '';
        const characters = '1234567890123456789012345678901234567890abcdefghi0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    

    useEffect(()=>{
        id==null && setFormValues({ ...formValues, id: makeid(22) })
    },[])

    // console.log(id)

   

    const handleaddGoal = async () => {
        if (formValues.title !== '') {
            if (formValues.value !== '') {
                if (formValues.currency !== '') {
                    if (formValues.rev !== '') {
                        if (formValues.po !== '') {
                            onaddgoalFun && onaddgoalFun(formValues)

                        } else {
                            toast.warning('Please Enter Payout')

                        }

                    } else {
                        toast.warning('Please Enter Revenue')

                    }

                } else {
                    toast.warning('Please select Currency')

                }

            } else {
                toast.warning('Please Enter value')

            }


        } else {
            toast.warning('Please Enter Title')

        }
    }

    const handleGaolUpdate = async () => {
        onupdategoal && onupdategoal(formValues)
    }

    return (
        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        {id ? 'Update' : 'Add'} Goal

                    </p>
                    <p>Deatils</p>

                </div>


                <div className="p-6 ">




                    <Myinput type={'text'} label={'Goal Title'} width={'100%'} name={'title'} value={formValues.title} onChange={handleChnageGaols} placeholder={'Install'} required={true} />

                    <Myinput type={'email'} label={'Goal Value'} width={'100%'} name={'value'} value={formValues.value} onChange={handleChnageGaols} required={true} placeholder={'install'} />

                    <Myselect value={formValues.currency} data={[{ label: 'INR', value: 'inr' }]} width={'100%'} placeholder={'Select Currency'} name={'currency'} onChange={(e) => handleChnageSelectGoal(e, 'currency')} />
                    <Myinput type={'number'} label={'REVENUE'} required={true} placeholder={'Charged from advertiser. Eg: 15'} width={'100%'} onChange={handleChnageGaols} value={formValues.rev} name='rev' />

                    <Myinput type={'number'} label={'PAYOUT'} required={true} placeholder={'Pay To Publisher Eg: 10'} width={'100%'} onChange={handleChnageGaols} value={formValues.po} name='po' />


                </div>


                {
                    id ? <Mybtn onClick={handleGaolUpdate} title={'Update Goal'} />
                        : <Mybtn onClick={handleaddGoal} title={'Create Goal'} />
                }
            </div>
        </div>
    )
}

export default GoalComponew