




import { Option, Select } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { CheckboxToggle } from 'react-rainbow-components';

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Myselect from '../../../COmponents/Myselect'
import NewMySelect from '../../../COmponents/NewMySelect'
import { COLORS } from '../../../Constants'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { fetchpublisher } from '../../../Redux/Actions/Agency/publisheragencyaction'

const Addemployeebyemployee = ({ setmodal, id }) => {

    const token = localStorage.getItem('token')

    const DataTable = [
        "Module Permission", 'Read', "Update", "Create", "Delete"
    ]

    const dispatch = useDispatch()
    const { platformurl } = useParams()
    const [loading, setloading] = useState(false)
    const ac_manag = [
        { label: 'Manish Singh', value: `12- Manish Singh` },
        { label: 'Rahul Singh', value: `15- Rahul Singh` },
    ]

     const jobrole_data = [
        { label: 'Publisher Manager', value: `publisher_manager` },
        { label: 'Advertiser Manager', value: `advertiser_manager` },
        { label: 'Accountant', value: `accountant` },
    ]

    const ac_statusdta = [
        { label: 'Active', value: 'active' },
        { label: 'Pending', value: 'pending' },
        { label: 'Disabled', value: 'disabled' },
        { label: 'Rejected', value: 'rejected' },
    ]

    const [mydta, setmydta] = useState({
        name: '',
        email: '',
        password: '',
        mobile: '',
        job_role: '',
        notes: '',
        skype:'',
        ac_manager: '',
        country: '',
        ac_status: '',
        address:'',
        accesspermision: [
            {
                name: 'Campaigns',
                read: false,
                update: false,
                create: false,
                delete: false
            },
            {
                name: 'Publisher',
                read: false,
                update: false,
                create: false,
                delete: false
            },
            {
                name: 'Advertiser',
                read: false,
                update: false,
                create: false,
                delete: false
            },
            {
                name: 'Employee',
                read: false,
                update: false,
                create: false,
                delete: false
            },
            {
                name: 'Reports',
                read: false,
                update: false,
                create: false,
                delete: false
            },
        ]

    })

    useEffect(() => {
        id && updateForm()
    }, [id])

    // console.log(mydta)

    const updateForm = async () => {
        const res = await gpeapi.get(`/${platformurl}/employee/getemployees/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress.data?.user)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))

    }

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }

    const handleUpdate = async () => {
        setloading(true)
        await gpeapi.put(`/${platformurl}/employee/updateemployee/${id}`, mydta,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res) => {
            if (res.data.success) {
                toast.success('Employee Deatils Updated')
                setmodal(false)
                


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const onToggleupdate = (e, i, value) => {
        const name = e.target.name
        setmydta({accesspermision:Object.values({ ...mydta.accesspermision, [i]: { ...mydta.accesspermision[i], [name]: !value } })})


    }

    const handleCreateAdv = async () => {

        if (mydta.name !== '') {
            if (mydta.email !== '') {
                if (mydta.mobile !== '') {
                    if (mydta.password !== '') {
                        setloading(true)
                        await gpeapi.post(`/${platformurl}/employee/createemployee`, mydta,{headers:{
                            "Authorization":`Beaer ${token}`
                        }}).then((res) => {
                            if (res.data.success) {
                                toast.success('Employee Account Created Successful')
                                setmodal(false)
                                


                            }

                        }).catch((e) => {
                            toast.error(e?.response?.data?.msg)

                        })
                        setloading(false)


                    } else {
                        toast.warning('Please Enter Password')
                    }

                } else {
                    toast.warning('Please Enter Mobile Number')
                }

            } else {
                toast.warning('Please Enter Email ID')
            }

        } else {
            toast.warning('Please Enter Full Name')
        }

    }

    // console.log(mydta)
    return (


        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        {id ? 'Update' : 'Add'} an Employee

                    </p>
                    <p>Deatils</p>

                </div>


                <div className="p-6 ">




                    <Myinput type={'text'} label={'Full Name'} width={'100%'} name={'name'} value={mydta.name} onChange={handleChnage} required={true} placeholder={'The name of your Employee'} />

                    <Myinput type={'email'} label={'Email ID'} width={'100%'} name={'email'} value={mydta.email} onChange={handleChnage} required={true} placeholder={'Enter Unique email of your Employee'} />

                    <Myinput label={'Mobile Number'} width={'100%'} name={'mobile'} value={mydta.mobile} onChange={handleChnage} required={true} placeholder={'Enter Mobile Number of your Employee'} />

                    <Myinput label={'Password'} type={'password'} width={'100%'} name={'password'} value={mydta.password} onChange={handleChnage} required={true} placeholder={'Enter Employee account password'} />

                    <Myselect value={mydta.ac_status} data={ac_statusdta} label={'Account Status'} width={'100%'} placeholder={'Select Account Status'} name={'ac_status'} onChange={(e) => handleChnageSelect(e, 'ac_status')} />

                    {/* <Myselect value={mydta?.ac_manager} data={ac_manag} label={'Account Manager'} width={'100%'} name={'ac_manager'} placeholder='Select Account Manager' onChange={(e) => handleChnageSelect(e, 'ac_manager')} /> */}

                    <Myselect value={mydta?.job_role} data={jobrole_data} label={'Job Role'} width={'100%'} name={'job_role'} placeholder='Select Job Role' onChange={(e) => handleChnageSelect(e, 'job_role')} />


                    

                    

                    <Myinput label={'Address'} width={'100%'} name={'address'} value={mydta.address} onChange={handleChnage} placeholder={'Enter Address'} />

                    <Myinput label={'Country'} width={'100%'} name={'country'} value={mydta.country} onChange={handleChnage} placeholder={'Enter Country'} />

                    <Myinput label={'Skype'} width={'100%'} name={'skype'} value={mydta.skype} onChange={handleChnage} placeholder={'Enter Skype ID'} />

                    

                    <Myinput multiline={true} tips={'The content will not be displayed to Employee'} label={'Notes'} width={'100%'} name={'notes'} value={mydta.notes} onChange={handleChnage} />





                </div>

                <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                        <tr>
                            {DataTable.map((val, i) => (
                                <th key={i} scope="col" class="px-6 py-3">
                                    {val}
                                </th>
                            ))}

                        </tr>

                    </thead>
                    <tbody>

                        {mydta.accesspermision?.map((val, i) => (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {val.name}
                                </th>
                                <td class="px-6 py-4">
                                   

                                    <CheckboxToggle
                                        id="checkbox-toggle-component-1"
                                        name='read'
                                        value={val.read}
                                        onChange={(e) => onToggleupdate(e, i, val.read)}
                                    />


                                </td>

                                <td class="px-6 py-4">
                                    <CheckboxToggle
                                        id="checkbox-toggle-component-1"
                                        name='update'
                                        value={val.update}
                                        onChange={(e) => onToggleupdate(e, i, val.update)}
                                    />
                                </td>

                                <td class="px-6 py-4">
                                    <CheckboxToggle
                                        id="checkbox-toggle-component-1"
                                        name='create'
                                        value={val.create}
                                        onChange={(e) => onToggleupdate(e, i, val.create)}
                                    />
                                </td>

                                <td class="px-6 py-4">
                                    <CheckboxToggle
                                        id="checkbox-toggle-component-1"
                                        name='delete'
                                        value={val.delete}
                                        onChange={(e) => onToggleupdate(e, i, val.delete)}
                                    />
                                </td>
                            </tr>

                        ))}

                    </tbody>
                </table>
            </div>

                {
                    id ? <Mybtn loading={loading} onClick={handleUpdate} title={'Update Employee'} />
                        : <Mybtn loading={loading} onClick={handleCreateAdv} title={'Create Employee'} />
                }
            </div>
        </div>



    )
}

export default Addemployeebyemployee