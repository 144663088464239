

import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'

import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { fetchcampaign } from '../../../Redux/Actions/Agency/campaignagencyaction'
import { fetchpricing } from '../../../Redux/Actions/pricingaction'

const Mainbasicdet = () => {
  const dispatch = useDispatch()
  const { toggle } = useContext(Authcontext)
  const navigate = useNavigate()
  const advertiserdta = useSelector((state) => state.basicReducer)

  // console.log(advertiserdta)


  const TABLE_HEAD = ["ID", "App-Name", "App-URL", "Server-URL", "Active", "Maintenance", "Version", "Action"];

  const onEditUser = (id) => {
    navigate(`/offerborn/amd/admin/updatedetails/${id}`)
  }

  return (
    <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
      {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



      <ToastContainer />








      <p className='text-2xl font-medium'>MANAGE SETTING</p>

      <div>
        <Mybtn onClick={() => navigate(`/admin/createdetails`)} title={'ADD NEW'} padding={'0px 15px'} radius={12} fontsize={14} />
      </div>


      {

        advertiserdta && advertiserdta.length == 0 ? <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography> :
          <div>
            <CardBody className="overflow-scroll px-0">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {advertiserdta && advertiserdta?.map(
                    (val, index) => {
                      const isLast = index === advertiserdta.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={index}>
                          <td className={classes}>


                            <Typography variant="small" color="blue-gray" className="font-bold">
                              {val?.id}
                            </Typography>

                          </td>
                          <td className={classes}>
                            <Typography variant="small" color="blue-gray" className="font-medium cursor-pointer ">
                              {val?.appname}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography variant="small" color="blue-gray" className="font-medium cursor-pointer ">
                              {val?.appurl}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                              {val?.serverurl}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={val?.isactive ? 'TRUE' : 'FALSE'}
                                color={
                                  val?.isactive ? "green" : "red"
                                }
                              />
                            </div>
                          </td>

                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={val?.maintenancemode ? 'TRUE' : 'FALSE'}
                                color={
                                  val?.maintenancemode ? "green" : "red"
                                }
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                              {val?.version}

                            </Typography>
                          </td>


                          




                          <td className={classes}>
                            <Tooltip content="Edit">
                              <IconButton onClick={() => onEditUser(val.id)} variant="text" color="blue-gray">
                                <PencilIcon className="h-4 w-4" />
                              </IconButton>
                            </Tooltip>

                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </CardBody>





          </div>

      }






    </div>
  )
}

export default Mainbasicdet