
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Mycard from '../../../COmponents/Mycard'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'
import { useSelector } from 'react-redux'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import easyinvoice from 'easyinvoice';

import { useNavigate, useParams } from 'react-router-dom'
import Mybtn from '../../../COmponents/Mybtn'
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import gpeapi from '../../../apis/gpeapi'
import Usageinagency from '../../Components/Usageinagency'

const Billinginfo = () => {
    const token = localStorage.getItem('token')

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const { toggle } = useContext(Authcontext)
    const basicData = useSelector((state) => state.basicReducer)[0]
    const { platformurl } = useParams()
    const navigate = useNavigate()

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const plandata = useSelector((state) => state.pricingReducer).filter((val) => val.id == userinfo.planid)[0]
    const TABLE_HEAD = ["Invoice No.", "Invoice Date", "Amount", "Usage", "Payment", "Print"];


    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()


    const handleviewplans = () => {
        navigate(`/p/${platformurl}/agency/plans`)

    }



    const genratedate1 = (date) => {
        const d = new Date(date);
        let month = months[d.getMonth()];
        let year = d.getFullYear()
        let datem = d.getDate()
        return `${datem} ${month} ${year}`
    }

    const [downloading, setdownloading] = useState({
        id:'',
        loading:false
    })
    useEffect(() => {
        callforAPI()
    }, [])

    const callforAPI = async () => {
        setloading(true)

        const res = await gpeapi.get(`/${platformurl}/agency/getinvoices`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const onEditUser = async (val) => {

        setdownloading({
            id:val.id,
            loading:true
        })

        const callanydate1 = (days, date) => {
            var mysevendays = new Date(date);
            mysevendays.setDate(mysevendays.getDate() + days);
            return mysevendays.toISOString().split('T')[0]
        }

        var strmm = `${val?.txndata?.invoiceinfo?.company_name} ${val?.invoiceid} ${new Date()}`

        var invoicenamemm = strmm?.replace(/\s/g, "_")

        var data = {
            // Customize enables you to provide your own templates
            // Please review the documentation for instructions and examples
            "customize": {
                //  "template": fs.readFileSync('template.html', 'base64') // Must be base64 encoded html 
            },
            "images": {
                // The logo on top of your invoice
                "logo": basicData?.bigicon,
                // The invoice background
                "background": "https://public.easyinvoice.cloud/img/watermark-draft.jpg"
            },
            // Your own data
            "sender": {
                "company": basicData?.company_name,
                "address": basicData?.address,
                "zip": basicData?.pincode,
                "city": basicData?.city,
                "country": basicData?.country,
                "custom1": `Email: ${basicData?.invoice_email}`,
                "custom2": `GST: ${basicData?.taxid}`,
                "custom3": `Service: SaaS (Software as a Service)`
            },
            // Your recipient
            "client": {
                "company": val?.txndata?.invoiceinfo?.company_name,
                "address": val?.txndata?.invoiceinfo?.address,
                "zip": val?.txndata?.invoiceinfo?.pincode,
                "city": val?.txndata?.invoiceinfo?.city,
                "country": val?.txndata?.invoiceinfo?.country,
                "custom1": `GST: ${val?.txndata?.invoiceinfo?.taxid}`,
                "custom2": `Partner ID - ${userinfo?.id}`,
                // "custom3": "custom value 3"
            },
            "information": {
                // Invoice number
                "number": val?.invoiceid,
                // Invoice data
                "date": callanydate1(0, val?.createdAt),
                // Invoice due date
                "due-date": callanydate1(0, userinfo?.extendplanenddate)
            },
            // The products you would like to see on your invoice
            // Total values are being calculated automatically
            "products": [
                {
                    "quantity": 1,
                    "description": val?.txndata?.txnname,
                    "tax-rate": 18,
                    "price": val?.txndata?.amount
                },

            ],
            // The message you would like to display on the bottom of your invoice
            // "bottom-notice": "Kindly pay your invoice within 15 days.",
            // Settings to customize your invoice
            "settings": {
                "currency": "INR", // See documentation 'Locales and Currency' for more info. Leave empty for no currency.
                // "locale": "nl-NL", // Defaults to en-US, used for number formatting (See documentation 'Locales and Currency')        
                // "margin-top": 25, // Defaults to '25'
                // "margin-right": 25, // Defaults to '25'
                // "margin-left": 25, // Defaults to '25'
                // "margin-bottom": 25, // Defaults to '25'
                // "format": "A4", // Defaults to A4, options: A3, A4, A5, Legal, Letter, Tabloid
                // "height": "1000px", // allowed units: mm, cm, in, px
                // "width": "500px", // allowed units: mm, cm, in, px
                // "orientation": "landscape", // portrait or landscape, defaults to portrait
            },
            // Translate your invoice to your preferred language
            "translate": {
                // "invoice": "FACTUUR",  // Default to 'INVOICE'
                "number": "Invoice No.", // Defaults to 'Number'
                "date": "Invoice Date", // Default to 'Date'
                // "due-date": "Verloopdatum", // Defaults to 'Due Date'
                // "subtotal": "Subtotaal", // Defaults to 'Subtotal'
                "products": "Monthly Subscription Plan", // Defaults to 'Products'
                // "quantity": "Aantal", // Default to 'Quantity'
                // "price": "Prijs", // Defaults to 'Price'
                // "product-total": "Totaal", // Defaults to 'Total'
                // "total": "Totaal", // Defaults to 'Total'
                "vat": "GST" // Defaults to 'vat'
            },
        };


        const invocedta = await easyinvoice.createInvoice(data, function (result) {
            easyinvoice.download(`${invoicenamemm}.pdf`, result.pdf)
        });

        setdownloading({
            id:val.id,
            loading:false
        })


    }

    // console.log(statsdata)

    return (
        <div className={toggle ? "main active" : "main"}>




            <ToastContainer />


            <div className='sm:flex sm:flex-col md:flex md:flex-row md:flex-wrap'>




                <Mycard classname={'sm:w-full md:w-[48%] border border-[#292e57] border-spacing-1'} compo={
                    <div>
                        <div className='flex justify-between items-center border-b p-5 pb-1'>


                            <div class="font-medium dark:text-white">
                                <div className='uppercase'>Plan information</div>

                            </div>








                        </div>

                        <div className='p-5 pt-2'>

                            <div className='grid grid-cols-5  gap-4  border-b pb-2 pt-1'>

                                <p className='text-sm col-span-2'>Plan Name</p>

                                <p className='text-sm col-span-3 uppercase'>{plandata?.name}</p>
                            </div>

                            <div className='grid grid-cols-5  gap-4  border-b pb-2 pt-1'>

                                <p className='text-sm col-span-2'>{plandata?.isfree && 'Trail'} End Date</p>

                                <p className='text-sm col-span-3 capitalize'>{genratedate1(userinfo?.planenddate)}</p>
                            </div>


                            <div className='justify-center grid grid-cols-5 border-b gap-4 pb-2 pt-2'>

                                <p className='text-sm col-span-2'>Included Conversions</p>

                                <p className='text-sm col-span-3'>{plandata?.conversions}</p>
                            </div>

                            <div className='justify-center grid grid-cols-5 border-b  gap-4 pb-2 pt-2'>

                                <p className='text-sm col-span-2'>Included Clicks</p>

                                <p className='text-sm col-span-3'>{plandata?.clicks}</p>
                            </div>

                            <div className='justify-center grid grid-cols-5 border-b gap-4 pb-2 pt-2'>

                                <p className='text-sm col-span-2'>Included Offers</p>

                                <p className='text-sm col-span-3'>{plandata?.offers}</p>
                            </div>

                            <div className='justify-center grid grid-cols-5 border-b gap-4 pb-2 pt-2'>

                                <p className='text-sm col-span-2'>Included Publishers</p>

                                <p className='text-sm col-span-3'>{plandata?.publishers}</p>
                            </div>

                            <div className='justify-center grid grid-cols-5 border-b gap-4 pb-2 pt-2'>

                                <p className='text-sm col-span-2'>Included Advertisers</p>

                                <p className='text-sm col-span-3'>{plandata?.advertisers}</p>
                            </div>

                            <div className='justify-center grid grid-cols-5  gap-4 pb-2 pt-2'>

                                <p className='text-sm col-span-2'>Included Employees</p>

                                <p className='text-sm col-span-3'>{plandata?.employees}</p>
                            </div>

                            <div className='mt-5'>
                                <Mybtn onClick={handleviewplans} title={'View Pricing'} />

                            </div>


















                        </div>




                    </div>
                } />

                <Usageinagency/>

                <Mycard classname={'sm:w-full md:w-[96%] border border-[#292e57] border-spacing-1'} compo={
                    <div>
                        <div className='flex justify-between items-center border-b p-5 pb-1'>


                            <div class="font-medium dark:text-white">
                                <div className='uppercase'>Invoices</div>

                            </div>








                        </div>

                        <div className='p-5 pt-2'>

                            {
                                loading ? <Spinner /> :





                                    <CardBody className="overflow-scroll px-0">
                                        <table className="w-full min-w-max table-auto text-left">
                                            <thead>
                                                <tr>
                                                    {TABLE_HEAD.map((head) => (
                                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal leading-none opacity-70 uppercase"
                                                            >
                                                                {head}
                                                            </Typography>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {!statsdata?.length ? (
                                                    <div className='flex justify-center'>
                                                        No Data Found
                                                    </div>
                                                ) : statsdata?.map(
                                                    (val, index) => {
                                                        const isLast = index === statsdata?.docs?.length - 1;
                                                        const classes = isLast ? "p-4 w-2" : "p-4 w-2  border-b border-blue-gray-50";

                                                        return (
                                                            <tr key={index}>

                                                                <td className={classes}>
                                                                    <Typography variant="small" color="black" className="font-normal">
                                                                        {val?.invoiceid}


                                                                    </Typography>
                                                                </td>

                                                                <td className={classes}>
                                                                    <Typography variant="small" color="blue-gray" className="font-normal  ">
                                                                        {genratedate1(val?.date)}
                                                                    </Typography>
                                                                </td>


                                                                <td className={classes}>
                                                                    <Typography variant="small" color="black" className="font-medium ">
                                                                        ₹{val?.txndata?.amount}

                                                                    </Typography>
                                                                </td>
                                                                <td className={classes}>
                                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                                        {genratedate1(val?.fromdate)} to {genratedate1(val?.enddate)}

                                                                    </Typography>
                                                                </td>
                                                                <td className={classes}>
                                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                                        Completed

                                                                    </Typography>
                                                                </td>




                                                                <td className={classes}>
                                                                    <Button onClick={() => onEditUser(val)} className='shadow-none' size="sm" style={{ backgroundColor: COLORS.primary }}> {downloading.id==val?.id && downloading.loading ? <Spinner /> : 'DOWNLOAD'}</Button>
                                                                </td>


                                                            </tr>
                                                        );
                                                    },
                                                )}
                                            </tbody>
                                        </table>
                                    </CardBody>

                            }

                        </div>
                    </div>



                } />









            </div>
        </div>
    )
}

export default Billinginfo