import { Tooltip } from '@material-tailwind/react'
import React from 'react'
import { useState } from 'react'
import { COLORS } from '../../../Constants'
import { Label, Radio } from 'flowbite-react';
import Myselect from '../../../COmponents/Myselect';
import Myinput from '../../../COmponents/Myinput';
import Mybtn from '../../../COmponents/Mybtn';
import { toast } from 'react-toastify';
import gpeapi from '../../../apis/gpeapi';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const Addpostbackpub = ({setmodal,id}) => {
  const token = localStorage.getItem('token')
  const { platformurl } = useParams()


  const [loading,setloading] = useState(false)

  const macros = [

    { params: '{p1}', content: 'Tracking Parameter 1 used to track your publishers software click id' },
    { params: '{p2}', content: 'Tracking Parameter 2' },
    { params: '{p3}', content: 'Tracking Parameter 3' },
    { params: '{p4}', content: 'Tracking Parameter 4' },
    { params: '{gaid}', content: 'GAID' },
]

  const [mydta, setmydta] = useState({
    postbacks:''
  })

const [event, setevent] = useState([
    { name: 'Conversion', value: 'conversion' },
])

const isValidURL = (string) => {
  try { return Boolean(new URL(string)); }
  catch (e) { return false; }
}

useEffect(()=>{
  if(id){
    setmydta({postbacks:id?.data})
  }
},[])

const handleCreateAdv =async ()=>{
  if(isValidURL(mydta.postbacks)){
    setloading(true)

    const res = await gpeapi.post(`/${platformurl}/addpostbackpublisher`, mydta, {
      headers: {
          "Authorization": `Beaer ${token}`
      }
  }).then((ress) => {
      toast.success(ress.data.msg)
      setmodal(false)
      window.location.reload('')

      
  }).catch((e) => {
      toast.error(e?.response?.data?.msg)

  })
    setloading(false)
  }else{
    toast.warn('Please Enter Valid URL')
  }
}

const handleupdate = async()=>{
  if(isValidURL(mydta.postbacks)){
    setloading(true)

    const res = await gpeapi.put(`/${platformurl}/updatepostbackpublisher/${id?.id}`, mydta, {
      headers: {
          "Authorization": `Beaer ${token}`
      }
  }).then((ress) => {
      toast.success(ress.data.msg)
      setmodal(false)
      window.location.reload('')
      
  }).catch((e) => {
      toast.error(e?.response?.data?.msg)

  })
    setloading(false)

  }else{
    toast.warn('Please enter Valid URL')
  }

}
  return (
    <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        Add New PostBack

                    </p>


                </div>
                <div className="p-2 ">

                    <div className='grid grid-cols-2 gap-1 m-8 mt-1 mb-4'>
                        <label htmlFor="default-input" class="block  text- text-left font-medium text-gray-900 dark:text-gray-300">Event</label>
                        <div className="flex items-center gap-2">
                            {event?.map((val) => (
                                <>
                                    <Radio
                                      

                                      checked={val.value}
                                        id={val.value}
                                        name='objectivedta'
                                        value={val.value}
                                        
                                    />
                                    <Label className='text-sm font-normal' htmlFor="united-state">
                                        {val.name}
                                    </Label>


                                </>

                            ))}

                        </div>
                    </div>

                   

                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1'>

                        <Myselect  value={'Postback URL'}  width={'100%'} label={'Type'} required={true}   data={[{ label: 'Postback URL', value: 'postback_url' }]} />
                    </div>

                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>



                        <Myinput width={'100%'} label={'Data'} required={true} multiline={true} onChange={(e)=>setmydta({postbacks:e.target.value})} name={'data'} value={mydta.postbacks} />


                    </div>

                    
                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1'>

                        <div>
                            <p className='font-medium m-2 text-xs'>Most Used URL tokens</p>

                            <div style={{ width: '200%' }} className='flex flex-wrap'>

                                {
                                    macros?.map((val, i) => (
                                        <Tooltip key={i} content={val.content}>


                                            <span onClick={()=>setmydta({postbacks:mydta.postbacks+val.params})}  className='text-sm cursor-pointer p-1 m-1 pl-4 pr-4 bg-[#ebeced] rounded-xl border-2' key={i}>{val.params}</span>
                                        </Tooltip>
                                    ))
                                }
                            </div>


                        </div>
                    </div>




                  

                </div>






{id?(
  <Mybtn loading={loading} onClick={handleupdate} title={'Update'} />

):(
  <Mybtn loading={loading} onClick={handleCreateAdv} title={'Save'} />

)}

            </div>
        </div>
  )
}

export default Addpostbackpub