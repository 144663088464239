import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const Privacypoliciy = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    document.title = `Privacy Policy - OfferBorn`

    useEffect(()=>{
      window.scrollTo(0,0)
  },[])
  return (
    <section style={{marginTop:50}} class="section contact" id="contact" aria-label="contact">
    <div class="container">

      <h2 class="h3 section-title font-bold">Privacy Policy</h2>

      <p class="section-text">
      
      </p>


      <h1 >Privacy Policy for OfferBorn</h1>

      <p>
        {basicData?.privacy_policy}
      </p>

 </div>
      </section>
  )
}

export default Privacypoliciy