


import { CardHeader, Chip, IconButton, Spinner, Tooltip, Typography } from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Mycard from '../../../COmponents/Mycard'
import { Authcontext } from '../../../Context/Authcontext'
import { FaEdit, FaCopy } from 'react-icons/fa';
import { SiTarget } from 'react-icons/si';
import { COLORS } from '../../../Constants'
import gpeapi from '../../../apis/gpeapi'
import Myselect from '../../../COmponents/Myselect'
import { useDispatch, useSelector } from 'react-redux'
import { fetchpublisher } from '../../../Redux/Actions/Agency/publisheragencyaction'
import Myinput from '../../../COmponents/Myinput'
import Page404Found from '../../../COmponents/Page404Found'
import Mymodal from '../../../COmponents/Mymodal'

import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import Mypopup from '../../../COmponents/Mypopup'



const Viewadvcamp = () => {
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const { id } = useParams()
    const { toggle } = useContext(Authcontext)
    const [loading, setloading] = useState(false)
    const { platformurl } = useParams()
    const navigate = useNavigate()
    const [found, setfound] = useState(null)
    const basicDta = useSelector((state) => state.basicReducer)[0]

    const [mydta, setmydta] = useState()
    const [mypostback, setmypostback] = useState(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${mydta?.security_token}`)

    const [mypostback1, setmypostback1] = useState(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${mydta?.security_token}`)



    const [advloading, setadvloading] = useState(false)
    const [tparams, settparams] = useState({
        sub1: '',
        sub2: '',
        sub3: '',
        sub4: ''
    })

    const handlechnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        settparams({ ...tparams, [name]: value })
    }









    const [goadta, setgoadta] = useState([])

    const [mygoal, setmygoal] = useState('')
    var select_goal = []




    useEffect(() => {
        callforApi()

    }, [])



    const callforApi = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/advcampaigns/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress?.data?.user)
            var mydttt = ress?.data?.user
            setfound(true)
            setmypostback(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${ress?.data?.user?.security_token}`)
            setmypostback1(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${ress?.data?.user?.security_token}`)

            var mydttt = ress?.data?.user


            if (ress?.data?.success) {

                select_goal?.length == 0 && mydttt?.goals?.map((val, i) => select_goal.push({ label: val.title, value: val.value }))

                setgoadta(select_goal)
            }


            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)
            setfound(false)
        })
        setloading(false)

    }




    const handleupdatetrackigparams = async () => {
        setmypostback(`${mypostback1}${tparams.sub1 && `&sub1=${tparams.sub1}`}${tparams.sub2 && `&sub2=${tparams.sub2}`}${tparams.sub3 && `&sub3=${tparams.sub3}`}${tparams.sub4 && `&sub4=${tparams.sub4}`}`)
    }





    // console.log(mydta)

    return (
        <div className={toggle ? "main active" : "main"}>



            <ToastContainer />

            {loading ? <div className='flex justify-center items-center'>
                <Spinner className="h-10 w-10  text-blue-500/10" />
            </div>
                :


                found ? <div className='sm:flex sm:flex-col md:flex md:flex-row md:flex-wrap'>

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div className='flex justify-between items-center border-b p-5 pb-1'>
                                <Typography variant='h5' >
                                    Details
                                </Typography>


                            </div>

                            <div className='p-5 pt-2'>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                    <p className='text-sm'>ID</p>

                                    <p className='text-sm col-span-3'>{mydta?.id}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                    <p className='text-sm uppercase'>Title</p>

                                    <p className='text-sm col-span-3'>{mydta?.title}</p>
                                </div>
                                <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm uppercase'>Model</p>

                                    <p className='text-sm col-span-3'>{mydta?.campaignmodel}</p>
                                </div>


                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Status</p>

                                    <Chip

                                        size="sm"
                                        variant="ghost"
                                        value={mydta?.status}
                                        color={
                                            mydta?.status === "active" ? "green" : mydta?.status === "pending" ? "amber" : "red"
                                        }
                                    />

                                    {/* <p className='text-sm col-span-3'>{mydta?.visibility}</p> */}
                                </div>









                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>URL</p>

                                    <p className='text-sm col-span-3  text-blue-500 cursor-pointer'>{mydta?.campaignurl}</p>
                                </div>



                            </div>


                        </div>
                    } />


                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Postback Setup
                                    </Typography>


                                </div>

                                <div className='p-5 pt-2'>

                                    <div className='grid grid-cols-4  gap-4   pb-2 pt-2'>
                                        {advloading ? <Spinner /> :


                                            mydta?.goals &&
                                            <div className='text-sm col-span-4 text-blue-400'>
                                                <Myselect value={mygoal} data={goadta} width={'100%'} placeholder={'Select Goal'} onChange={(e) => {
                                                    if (!mypostback.includes(`goal=${e}`)) {
                                                        setmygoal(e)
                                                        setmypostback(mypostback + `&goal=${e}`)
                                                        setmypostback1(mypostback1 + `&goal=${e}`)

                                                    }
                                                }} />
                                            </div>
                                        }
                                    </div>

                                    <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>
                                        {advloading ? <Spinner /> :
                                            <div className='text-sm col-span-4'>



                                                <div>
                                                    <p className='text-lg'>Generated Postback</p>
                                                </div>
                                                <Myinput width={'100%'} line={5} value={mypostback} multiline={true} type={'text'} onChange={(e) => setmypostback(e.target.value)} />
                                            </div>
                                        }
                                    </div>

                                    <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>

                                        <div className='text-sm col-span-4'>

                                            <div>
                                                <p className='text-md'>Macros</p>
                                            </div>

                                            <div className='flex '>

                                                <Myinput width={'50%'} value={tparams.sub1} placeholder={'Macro 1'} name='sub1' type={'text'} onChange={handlechnage} />
                                                <Myinput width={'50%'} value={tparams.sub2} placeholder={'Macro 2'} name='sub2' type={'text'} onChange={handlechnage} />



                                            </div>

                                            <div className='flex'>
                                                <Myinput width={'50%'} value={tparams.sub3} placeholder={'Macro 3'} name='sub3' type={'text'} onChange={handlechnage} />
                                                <Myinput width={'50%'} value={tparams.sub4} placeholder={'Macro 4'} name='sub4' type={'text'} onChange={handlechnage} />
                                            </div>

                                            <div className=' flex justify-end'>
                                                <p onClick={handleupdatetrackigparams} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>

                                                    <p className='font-semibold text-white text-sm'>
                                                        UPDATE
                                                    </p>

                                                </p>
                                            </div>


                                        </div>

                                    </div>




                                </div>


                            </div>
                        </div>
                    } />






                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Cost
                                    </Typography>


                                </div>

                                <div className='p-5 pt-2'>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-3 font-bold'>NAME</p>

                                        {/* <p className='text-sm font-bold text-center'>VALUE</p> */}
                                        {/* <p className='text-sm font-bold text-center'>REVENUE</p> */}
                                        <p className='text-sm font-bold text-center'>COST</p>
                                    </div>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-3 '>Default</p>

                                        {/* <p className='text-sm text-center'>default</p> */}
                                        {/* <p className='text-sm text-center'>₹{mydta?.campaign?.revenue}</p> */}
                                        <p className='text-sm text-center'>₹{mydta?.revenue}</p>
                                    </div>

                                    {
                                        mydta?.goals && mydta?.goals?.map((val, i) => (
                                            <div key={i} className='grid grid-cols-6  gap-4  border-b pb-2 pt-1 justify-center items-center'>

                                                <p className='text-sm col-span-3 ' style={{ color: COLORS.primary }}>{val.title} <p className='text-xs '>{val?.id}</p></p>

                                                {/* <p className='text-sm text-center'>{val?.value}</p> */}
                                                {/* <p className='text-sm text-center'>₹{val?.rev}</p> */}
                                                <p className='text-sm text-center'>₹{val?.rev}</p>
                                            </div>
                                        ))
                                    }




                                </div>


                            </div>
                        </div>
                    } />



                </div >
                    :
                    <Page404Found />
            }
        </div >

    )
}

export default Viewadvcamp