
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import CountUp from 'react-countup';
import { COLORS } from '../../../Constants';

const Trackingpage = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    // document.title = `About Us - OfferBorn`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section style={{ marginTop: 50 ,backgroundColor:'white'}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Tracking & Analytics
                </h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" >

                        <figure class="hero-banner">
                            <img src={require('../../img/work-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Affiliate Manager
Management System</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Manage all the affiliates smoothly and effortlessly through our dedicated affiliate manager dashboard.There is absolutely no confusion in controlling and managing the affiliates with the help of our astounding dashboard.
                                <br />
                                <br />
                                
                            </p>



                        </div>
                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/management-2.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Manage Offers,
Affiliates & Advertisers</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            You can be incharge of your offers, affiliates, and advertisers simultaneously by seeing all of them together on a single dashboard.
You can see all the stats related to them conveniently on one dashboard. Take Charge Today
                                <br />
                                <br />
                                
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'ltr',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/fast-redirect.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Fast Re-Direction</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Every click must redirect to the advertiser's page within no time. You can do this in an accelerated way, with OfferBorn. There is absolutely no hassle during the process of redirection. This means fast actions and fast results, saving a lot of time and energy and giving a quality user experience. Speed Up Your Redirects
                              
                                
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/affiliate-dashboard.gif')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>User Friendly Affiliate Dashboard
</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            With OfferBorn you get a user-friendly affiliate dashboard that allows you to understand your affiliate network in an easy and simple way. It is all possible with the help of quick short reports. The feature of user-friendly affiliate dashboard allows you to manage your affiliates without any hassle in an effective and efficient way. Dive into User-Friendly Management
                              
                                
                            </p>



                        </div>
                    </div>
                </section>







                <section className='mt-20'>

                    {/* <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p> */}

                    <div style={{ backgroundColor: '#045385' }} className='flex p-3 shadow-lg flex-col  justify-around items-center bg-white rounded-lg sm:flex-row md:flex-row xl:flex-row 2xl:flex-row  '>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <CountUp
                                start={0}
                                end={100}
                                duration={2.75}
                                separator=" "
                                style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
                                suffix="+"
                            />

                            <p className='text-xl text-white'>Global Clients</p>
                        </div>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <CountUp
                                start={0}
                                end={4}
                                duration={2.75}
                                separator=" "
                                style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
                                suffix="+"
                            />

                            <p className='text-xl text-white'>Years Experience</p>
                        </div>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <CountUp
                                start={0}
                                end={97}
                                duration={2.75}
                                separator=" "
                                style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
                                suffix="%"
                            />

                            <p className='text-xl text-white'>Positive Feedbacks</p>
                        </div>



                    </div>


                </section>

                <section className='mt-20'>

                    <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p>

                    <div className='flex justify-center items-center'>
                        <div class="service-card" style={{ border: `1px solid ${COLORS.primary}`, borderRadius: 25, width: '40%' }}>

                            <img style={{ height: 40, width: 40, margin: 'auto' }} src='https://affise.com/wp-content/uploads/2022/05/india.png' />



                            <h3 class="h3">
                                <a href="#" class="text-lg">India</a>
                            </h3>

                            <p class="text-base">
                                {basicData?.address} {basicData?.pincode}
                            </p>
                            <p class="text-base">
                                {basicData?.contact_mobile}
                            </p>


                        </div>
                    </div>


                </section>






            </div>
        </section>
    )
}

export default Trackingpage