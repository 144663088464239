

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Mypaginate from '../../../../../../../../COmponents/Mypaginate';
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify'
import gpeapi from '../../../../../../../../apis/gpeapi';
import { Authcontext } from '../../../../../../../../Context/Authcontext';

const Approvedcamppub = () => {
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const { platformurl,pubid } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)

     
    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(10)
    const [search, setsearch] = useState('')
  
    const [openmodal, setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)
  
  
  
    const [myid, setmyid] = useState(null)
  
    useEffect(() => {
      callforAPI()
    }, [limit])
  
    const callforAPI = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/${pubid}/admpubagn/pubcampaigns?limit=${limit}&search=public`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
      setloading(false)
  
    }
  
    const handlepaginate = async (number) => {
      // console.log(number)
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/${pubid}/admpubagn/pubcampaigns?limit=${limit}&search=public&page=${number - 1}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
        console.log('api', ress.data)
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
      setloading(false)
    }
  
    const onNextClick = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/${pubid}/admpubagn/pubcampaigns?limit=${limit}&search=public&page=${statsdata.page + 1}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
  
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
  
      setloading(false)
    }
  
    const handleChnageSelect = async (e) => {
      setlimit(e)
    }
  
    const onPrevClick = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/${pubid}/admpubagn/pubcampaigns?limit=${limit}&search=public&page=${statsdata.page - 1}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((ress) => {
  
  
        setstatdata(ress?.data?.TotalUsers)
      }).catch((e) => {
        toast.error(e?.response?.data?.msg)
  
      })
      setloading(false)
    }

   


    const TABLE_HEAD = ["ID","PREVIEW", "TITLE", "MODEL", "STATUS", "PAYOUT", "CATEGORIES"];
   

    const handleTitleclik = (id) => {
      navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/viewcamp/${id}`)


    }

  return (
    <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
    {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



    <ToastContainer />






    {
        loading ? <div className='flex justify-center items-center'>
            <Spinner className="h-10 w-10  text-blue-500/10" />
        </div> : (
            statsdata && statsdata?.docs?.length == 0 ? <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography> :
                <div>
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {statsdata && statsdata?.docs?.map(
                                    (val, index) => {
                                        const isLast = index === statsdata?.docs?.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>
                                                <td className={classes}>


                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        {val?.id}
                                                    </Typography>

                                                </td>
                                                <td className={classes}>
                                                   <img className='w-5 ' src={val?.campaign?.icon}/>
                                                </td>
                                                <td className={classes}>
                                                    <Typography onClick={() => handleTitleclik(val.campid)} variant="small" color="blue" className="font-medium cursor-pointer ">
                                                        {val?.campaign?.title}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {val?.campaign?.campaignmodel}

                                                    </Typography>
                                                </td>

                                                

                                                

                                                

                                                <td className={classes}>
                                                    <div className="w-max">
                                                        <Chip
                                                            size="sm"
                                                            variant="ghost"
                                                            value={val?.status=='public'?'Active':val?.status=='pending'?'Approval Pending':'Need Approval'}
                                                            color={
                                                                val?.status === "public" ? "green" : val?.status === "pending" ? "amber" : "red"
                                                            }
                                                        />
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        ₹{val?.campaign?.payout}

                                                    </Typography>
                                                </td>
                                                
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {val?.campaign?.category}

                                                    </Typography>
                                                </td>
                                               
                                                
                                                
                                              
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>

                    <Mypaginate
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        paginate={handlepaginate}
                        currentPage={statsdata?.page}
                        totalData={statsdata?.total}
                        perPage={statsdata?.limit}
                        Totalpage={statsdata?.pages}
                    />



                </div>
        )
    }






</div>
  )
}

export default Approvedcamppub