import React, { useContext } from 'react'
import { useState } from 'react'
import { COLORS } from '../Constants'
import { Authcontext } from '../Context/Authcontext'
import { Button } from '@material-tailwind/react'
import { Link, useNavigate, useParams } from 'react-router-dom'


const Header = () => {

    const { toggle, settoggle } = useContext(Authcontext)


    const userInfo =  JSON.parse(localStorage.getItem('userInfo'))

    

    const [show, setshow] = useState(false)

    // console.log(show)

    const { platformurl } = useParams()
    const navigate = useNavigate()


    const handlelogout = ()=>{
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        localStorage.clear()
        alert('Log-Out')
        window.location.reload('/')
    }

    // console.log(userInfo)

    const diffrecedate = (enddate)=>{

        var date1 = new Date(enddate)
        var date2 = new Date()
       
        var Difference_In_Time1 = date2.getTime() - date1.getTime();
      
        var Difference_In_Days = Math.abs(Difference_In_Time1 / (1000 * 3600 * 24)).toFixed();

        return Difference_In_Days
       

    }

    const handleclickc = ()=>{
        navigate(`/p/${platformurl}/agency/billing`)

    }

    const onClickOutsideListener = () => {
        setshow(false)
        document.removeEventListener("click", onClickOutsideListener)
      }
    

    return (
        <div  style={{background:COLORS.primary, zIndex:1006,position:'fixed',top:0,left:0,right:0,}}>

            <div class="topbar">
                <div onClick={() => settoggle(!toggle)} class="toggle">
                    <ion-icon style={{color:'white'}} name="menu-outline"></ion-icon>
                </div>

                {
                    userInfo?.role=='agency'?(

                        <div>

<Button onClick={handleclickc} className="rounded-full bg-red-500 shadow-none hover:shadow-none">{userInfo?.extendplanended?'Expired': userInfo?.planended? `${diffrecedate(userInfo?.extendplanenddate)} Days Left For Over-Due` :`${diffrecedate(userInfo?.planenddate)} Days Left`}</Button>
                            
                        </div>

                    ):(

                    
                <div class="search">
                    <label>
                        <input type="text" placeholder="Search here" />
                        <ion-icon name="search-outline"></ion-icon>
                    </label>
                </div>

                )}

                <div onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListener)
          }} onClick={() => setshow(!show)} class="user">
                    <img src="https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png" alt="ac_profile" />


                </div>


            </div>


            {show&& <div class="myprofildrop bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4">
                <div class="px-4 py-3">
                    <span class="block text-sm">{userInfo?.name}</span>
                    <span class="block text-sm font-medium text-gray-900 truncate">{userInfo?.email}</span>
                </div>
                <ul class="py-1" aria-labelledby="dropdown">
                    <li>
                        <Link to={userInfo?.role=='agency'?`/p/${platformurl}/agency/chats`:userInfo?.role=='publisher'?`/p/${platformurl}/publisher/chats`:'#'} class=" text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">{userInfo?.role=='agency'?`Chat Publisher`:userInfo?.role=='publisher'?`Chat Agency`:''}</Link>
                    </li>
                   
                    <li>
                        <a  onClick={handlelogout} class=" myaaaa text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Sign out</a>
                    </li>
                </ul>
            </div>}
        </div>

    )
}

export default Header