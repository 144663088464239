

import { faCoffee, faHandPointer,faMoneyBillTransfer,faUserCheck } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailsCardcom from '../../../COmponents/DetailsCardcom'
import { Authcontext } from '../../../Context/Authcontext'

import gpeapi from '../../../apis/gpeapi'
import { toast } from 'react-toastify'
import { Spinner } from 'react-rainbow-components'
import Homestatsagency from '../Stats/Homestatsagency'
import NoInternetConnection from '../../../NoInternetConnection'
import { COLORS } from '../../../Constants'
import Usageinagency from '../../Components/Usageinagency'

const Mainhomeagency = () => {
  const token = localStorage.getItem('token')
  const userinfo = JSON.parse(localStorage.getItem('userInfo'))


  const { toggle } = useContext(Authcontext)

  const {platformurl} = useParams()

  const [loading, setloading] = useState(false)
  const [statsdata, setstatdata] = useState()

  useEffect(() => {
      callforAPI()
       
    }, [])

  const callforAPI = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/agencydashboardcount`, {
          headers: {
              "Authorization": `Beaer ${token}`
          }
      }).then((ress) => {
          setstatdata(ress?.data?.data)
      }).catch((e) => {
          toast.error(e?.response?.data?.msg)

      })
      setloading(false)

  }

 
  return (
    <div className={toggle ? "main active" : "main"}>


      

      <div className='m-5'>
        {/* <Homestats /> */}
        
        <span style={{backgroundColor:COLORS.primary,margin:'auto'}} className='text-base text-center p-2  font-bold pl-8 pr-8 text-white rounded-full'>Welcome {userinfo?.companyname}!</span>
      </div>

      <div>
        <Homestatsagency />
      </div>

      {
        loading? <Spinner/> :(

        
      <div style={{display:'flex',flexWrap:'wrap'}}>

        {
          statsdata?.map((val,i)=>(

            <DetailsCardcom  key={i} name={val?.name} icon={val.name=='conversion'?faUserCheck:val.name=='payout'?faMoneyBillTransfer: val?.name=='revenue'?faMoneyBillTransfer:faHandPointer} m={val?.name=='payout' || val?.name=='revenue'? '₹'+val?.mtd :val?.mtd} t={val?.name=='payout' || val?.name=='revenue'?'₹'+val?.today:val?.today} y={val?.name=='payout' || val?.name=='revenue'?'₹'+val?.yesterday:val?.yesterday}/>
          ))
        }
        
      </div>

      )
      }
      

      <div>

        <Usageinagency/>

      </div>

      {/* <NoInternetConnection/> */}

      
      
      </div>
  )
}

export default Mainhomeagency