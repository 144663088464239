import { createContext, useState } from "react";


export const Authcontext = createContext()

export const Authprovider = ({children})=>{

    const [testdt,settestdt] =useState(true)

    const [userInfo,setuserInfo] = useState(null)
    const [userToken,setuserToken] = useState(null)

    const [toggle, settoggle] = useState(false)


    return (
        <Authcontext.Provider value={{testdt,toggle, settoggle,setuserInfo,userInfo,setuserToken,userToken}} >
            {children}
        </Authcontext.Provider>
    )
}