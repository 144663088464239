import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Mypaginate from '../../../COmponents/Mypaginate'
import { Authcontext } from '../../../Context/Authcontext'
import { fetchpubcampaign } from '../../../Redux/Actions/Pub/campaignspubaction'
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { ToastContainer } from 'react-toastify'

const Maincampaigns = () => {
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)

    const [reduloading, setreduloading] = useState(true)
    useEffect(() => {
        callforReducer()
    }, [])

    const callforReducer = async () => {
        setreduloading(true)
        await dispatch(fetchpubcampaign(platformurl))
        setreduloading(false)
    }

    const advertiserdta = useSelector((state) => state.pubcampaignReducer)


    const TABLE_HEAD = ["ID","PREVIEW", "TITLE", "MODEL", "STATUS", "PAYOUT", "CATEGORIES"];
    const handlepaginate = async (number) => {
        // console.log(number)
        setreduloading(true)
        await dispatch(fetchpubcampaign(platformurl, number - 1))
        setreduloading(false)
    }

    const onNextClick = async () => {
        setreduloading(true)
        await dispatch(fetchpubcampaign(platformurl, advertiserdta[0]?.page + 1))
        setreduloading(false)
    }

    const onPrevClick = async () => {
        setreduloading(true)
        await dispatch(fetchpubcampaign(platformurl, advertiserdta[0]?.page - 1))
        setreduloading(false)
    }

    const handleTitleclik = (id) => {
        navigate(`/p/${platformurl}/publisher/viewcamp/${id}`)
    }

  return (
    <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
    {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



    <ToastContainer />






    {
        reduloading ? <div className='flex justify-center items-center'>
            <Spinner className="h-10 w-10  text-blue-500/10" />
        </div> : (
            advertiserdta && advertiserdta[0]?.docs?.length == 0 ? <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography> :
                <div>
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {advertiserdta && advertiserdta[0]?.docs?.map(
                                    (val, index) => {
                                        const isLast = index === advertiserdta[0]?.docs?.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>
                                                <td className={classes}>


                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        {val?.id}
                                                    </Typography>

                                                </td>
                                                <td className={classes}>
                                                   <img className='w-5 ' src={val?.campaign?.icon}/>
                                                </td>
                                                <td className={classes}>
                                                    <Typography onClick={() => handleTitleclik(val.campid)} variant="small" color="blue" className="font-medium cursor-pointer ">
                                                        {val?.campaign?.title}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {val?.campaign?.campaignmodel}

                                                    </Typography>
                                                </td>

                                                

                                                

                                                

                                                <td className={classes}>
                                                    <div className="w-max">
                                                        <Chip
                                                            size="sm"
                                                            variant="ghost"
                                                            value={val?.status=='public'?'Active':val?.status=='pending'?'Approval Pending':'Need Approval'}
                                                            color={
                                                                val?.status === "public" ? "green" : val?.status === "pending" ? "amber" : "red"
                                                            }
                                                        />
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        ₹{val?.campaign?.payout}

                                                    </Typography>
                                                </td>
                                                
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {val?.campaign?.category}

                                                    </Typography>
                                                </td>
                                               
                                                
                                                
                                              
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>

                    <Mypaginate
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        paginate={handlepaginate}
                        currentPage={advertiserdta[0]?.page}
                        totalData={advertiserdta[0]?.total}
                        perPage={advertiserdta[0]?.limit}
                        Totalpage={advertiserdta[0]?.pages}
                    />



                </div>
        )
    }






</div>
  )
}

export default Maincampaigns