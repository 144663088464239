
import { CardHeader, Chip, IconButton, Spinner, Tooltip, Typography } from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Mycard from '../../../../../COmponents/Mycard'
import { FaEdit, FaCopy } from 'react-icons/fa';
import { SiTarget } from 'react-icons/si';
import gpeapi from '../../../../../apis/gpeapi'

import { useDispatch, useSelector } from 'react-redux'
import Myinput from '../../../../../COmponents/Myinput'
import Page404Found from '../../../../../COmponents/Page404Found'
import Mymodal from '../../../../../COmponents/Mymodal'
import Addpostbackpub from './Addpostbackpub'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import Mypopup from '../../../../../COmponents/Mypopup'
import { Authcontext } from '../../../../../Context/Authcontext'
import { fetchpublisher } from '../../../../../Redux/Actions/Agency/publisheragencyaction'
import { COLORS } from '../../../../../Constants'



const Viewcampaign = () => {
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const { id } = useParams()
    const { toggle } = useContext(Authcontext)
    const [loading, setloading] = useState(false)
    const { platformurl ,pubid} = useParams()
    const navigate = useNavigate()
    const [found, setfound] = useState(null)

    const [mydta, setmydta] = useState()
    const [advloading, setadvloading] = useState(false)
    const [tparams, settparams] = useState({
        p1: '',
        p2: '',
        p3: '',
        p4: ''
    })

    const handlechnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        settparams({ ...tparams, [name]: value })
    }

    const [viewpostback, setviewpostback] = useState('')
    const [viewpostbackmodal, setviewpostbackmodal] = useState(false)
    const [myid, setmyid] = useState(null)

    // const [pubid, setpubid] = useState('')
    const [pubtracking, setpubtracking] = useState('')
    const [comment, setcomment] = useState('')
    const [mypostback, setmypostback] = useState('')
    const [modal, setmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)

    useEffect(() => {
        callforReducer()

    }, [])

    const callforReducer = async () => {
        setadvloading(true)
        await dispatch(fetchpublisher(platformurl))

        setadvloading(false)
    }

    useEffect(() => {
        setpubtracking(mydta?.mycampaignurl)
    }, [mydta])

    const advertidata = []



    const [goadta, setgoadta] = useState([])




    const advertiserdta = useSelector((state) => state.publisherAgencyReducer)[0]?.docs?.map((val) => advertidata.push({ label: `${val.name} (${val.company})`, value: val.id }))

    useEffect(() => {
        callforApi()

    }, [])



    const callforApi = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/${pubid}/pubagn/pubcampaigns/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress?.data?.user)
            var mydttt = ress?.data?.user
            setfound(true)


            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)
            setfound(false)
        })


        setloading(false)

    }

    const handleupdatetrackigparams = async () => {
        setpubtracking(`${mydta?.mycampaignurl}${tparams.p1 && `&p1=${tparams.p1}`}${tparams.p2 && `&p2=${tparams.p2}`}${tparams.p3 && `&p3=${tparams.p3}`}${tparams.p4 && `&p4=${tparams.p4}`}`)
    }

    const onsendrequest = async () => {
        if (comment !== '') {
            const res = await gpeapi.post(`/${platformurl}/${pubid}/pubagn/sendrequestcamp/${mydta?.campid}`, { comment: comment, status: 'pending' }, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }).then((ress) => {

                toast.success(ress.data.msg)
                window.location.reload('')
            }).catch((e) => {
                toast.error(e?.response?.data?.msg)

            })
        } else {
            toast.warn('Please Enter Comment ')
        }

    }

    const openpostback = () => {
        setmodal(true)
    }

    const onDeleteUser = async (postbackid) => {
        setopenpop(true)
        setmyid(postbackid)


    }

    const handleDeleteAdv = async () => {
        setopenpop(false)
        const res = await gpeapi.delete(`/${platformurl}/${pubid}/pubagn/deletepostbackpub/${mydta?.campid}/${myid}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            toast.success(ress.data.msg)
            window.location.reload('')
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
    }

    const handleviwpostback = (myurl) => {
        setviewpostback(myurl)
        setviewpostbackmodal(true)
    }



    // console.log(mydta)

    return (
        <div className={toggle ? "main active" : "main"}>

            <Mymodal width={'40%'} Components={<Addpostbackpub setmodal={setmodal} mygoals={mydta?.campaign?.goals} id={mydta?.campid} />} open={modal} setopenmodal={setmodal} />

            <Mymodal width={'60%'} Components={<div className='mt-6 m-5 p-2'>
                <p>{viewpostback}</p>
            </div>} open={viewpostbackmodal} setopenmodal={setviewpostbackmodal} />

            <Mypopup handleoutopen={() => setmyid(null)} title={`Are you Sure`} des={`you want to delete this postback?`} open={openpop} setopenpop={setopenpop} onConfirm={handleDeleteAdv} />

            <ToastContainer />

            {loading ? <div className='flex justify-center items-center'>
                <Spinner className="h-10 w-10  text-blue-500/10" />
            </div>
                :


                found ? <div className='sm:flex sm:flex-col md:flex md:flex-row md:flex-wrap'>

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div className='flex justify-between items-center border-b p-5 pb-1'>
                                <Typography variant='h5' >
                                    Details
                                </Typography>


                            </div>

                            <div className='p-5 pt-2'>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                    <p className='text-sm'>ID</p>

                                    <p className='text-sm col-span-3'>{mydta?.campid}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                    <p className='text-sm uppercase'>Title</p>

                                    <p className='text-sm col-span-3'>{mydta?.campaign?.title}</p>
                                </div>
                                <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm uppercase'>Model</p>

                                    <p className='text-sm col-span-3'>{mydta?.campaign?.campaignmodel}</p>
                                </div>


                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Status</p>

                                    <Chip

                                        size="sm"
                                        variant="ghost"
                                        value={mydta?.campaign?.status}
                                        color={
                                            mydta?.campaign?.status === "active" ? "green" : mydta?.campaign?.status === "pending" ? "amber" : "red"
                                        }
                                    />

                                    {/* <p className='text-sm col-span-3'>{mydta?.visibility}</p> */}
                                </div>







                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Devices</p>

                                    <p className='text-sm col-span-3'>{mydta?.campaign?.devices}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Category</p>

                                    <p className='text-sm col-span-3'>{mydta?.campaign?.category}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Preview URL</p>

                                    <p className='text-sm col-span-3  text-blue-500 cursor-pointer'>{mydta?.campaign?.previewurl}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Description</p>

                                    <p className='text-sm col-span-3 '>{mydta?.campaign?.des}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>KPI</p>

                                    <p className='text-sm col-span-3 '>{mydta?.campaign?.kpi}</p>
                                </div>


                            </div>


                        </div>
                    } />

                    {
                        mydta?.status == 'public' ? (
                            <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                                <div>
                                    <div className='flex justify-between items-center border-b p-5 pb-1'>
                                        <Typography variant='h5' >
                                            Tracking Link
                                        </Typography>


                                    </div>

                                    <div className='p-5 pt-2'>


                                        <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>

                                            <div className='text-sm col-span-4'>

                                                <div className='flex justify-between items-center'>
                                                    <p className='text-lg'>Generated Link</p>

                                                    <Tooltip content="Copy Tracking Link" >
                                                        <p>

                                                            <FaCopy onClick={() => {
                                                                navigator.clipboard.writeText(pubtracking)
                                                                toast.success('Tracking Link Copied')
                                                            }} className="h-4 w-4 cursor-pointer" />
                                                        </p>

                                                    </Tooltip>
                                                </div>
                                                <Myinput width={'100%'} value={pubtracking} multiline={true} type={'text'} onChange={(e) => setpubtracking(e.target.value)} />
                                            </div>

                                        </div>

                                        <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>

                                            <div className='text-sm col-span-4'>

                                                <div>
                                                    <p className='text-md'>Tracking Params</p>
                                                </div>

                                                <div className='flex '>

                                                    <Myinput width={'50%'} value={tparams.p1} placeholder={'P1'} name='p1' type={'text'} onChange={handlechnage} />
                                                    <Myinput width={'50%'} value={tparams.p2} placeholder={'P2'} name='p2' type={'text'} onChange={handlechnage} />



                                                </div>

                                                <div className='flex'>
                                                    <Myinput width={'50%'} value={tparams.p3} placeholder={'P3'} name='p3' type={'text'} onChange={handlechnage} />
                                                    <Myinput width={'50%'} value={tparams.p4} placeholder={'P4'} name='p4' type={'text'} onChange={handlechnage} />
                                                </div>

                                                <div className=' flex justify-end'>
                                                    <p onClick={handleupdatetrackigparams} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>

                                                        <p className='font-semibold text-white text-sm'>
                                                            UPDATE
                                                        </p>

                                                    </p>
                                                </div>


                                            </div>

                                        </div>


                                    </div>


                                </div>
                            } />
                        ) : (
                            <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                                <div>
                                    <div className='flex justify-between items-center border-b p-5 pb-1'>
                                        <Typography variant='h5' >
                                            Request campaign access
                                        </Typography>


                                    </div>

                                    {
                                        mydta?.status == 'pending' ? (
                                            <div className='p-5 pt-2'>
                                                <p className='text-sm'>Request Approval Pending</p>
                                            </div>
                                        ) : (
                                            <div className='p-5 pt-2'>

                                                <p className='text-xs text-gray-500'>You need Approval to Run this campaign. </p>
                                                <p className='text-sm'>Questionnaire : How you will run this campaign ?</p>

                                                <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>

                                                    <div className='text-sm col-span-4'>

                                                        <div className='flex justify-between items-center'>
                                                            <p className='text-md'>Your Response</p>


                                                        </div>
                                                        <Myinput line={5} width={'100%'} value={comment} multiline={true} type={'text'} onChange={(e) => setcomment(e.target.value)} />
                                                    </div>

                                                    <div className='w-fit flex justify-end'>
                                                        <p onClick={onsendrequest} style={{ backgroundColor: COLORS.primary }} className='w-fit pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>

                                                            <p className='font-semibold w-max text-white text-sm'>
                                                                Send Request
                                                            </p>

                                                        </p>
                                                    </div>

                                                </div>



                                            </div>

                                        )
                                    }





                                </div>
                            } />
                        )
                    }





                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Payout and Goals
                                    </Typography>


                                </div>

                                <div className='p-5 pt-2'>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-3 font-bold'>GOAL NAME</p>

                                        <p className='text-sm font-bold text-center'>VALUE</p>
                                        {/* <p className='text-sm font-bold text-center'>REVENUE</p> */}
                                        <p className='text-sm font-bold text-center'>PAYOUT</p>
                                    </div>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-3 '>Default</p>

                                        <p className='text-sm text-center'>default</p>
                                        {/* <p className='text-sm text-center'>₹{mydta?.campaign?.revenue}</p> */}
                                        <p className='text-sm text-center'>₹{mydta?.campaign?.payout}</p>
                                    </div>

                                    {
                                        mydta?.campaign?.goals && mydta?.campaign?.goals?.map((val, i) => (
                                            <div key={i} className='grid grid-cols-6  gap-4  border-b pb-2 pt-1 justify-center items-center'>

                                                <p className='text-sm col-span-3 ' >{val.title}</p>

                                                <p className='text-sm text-center'>{val?.value}</p>
                                                {/* <p className='text-sm text-center'>₹{val?.rev}</p> */}
                                                <p className='text-sm text-center'>₹{val?.po}</p>
                                            </div>
                                        ))
                                    }




                                </div>


                            </div>
                        </div>
                    } />

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Postback Setup
                                    </Typography>

                                    <p onClick={openpostback} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>
                                        <Tooltip content="Add Postback">
                                            <p className='font-semibold text-white text-sm'>
                                                Add Postback
                                            </p>
                                        </Tooltip>
                                    </p>


                                </div>

                                <div className='p-5 pt-2'>



                                    <div className='grid flex-col grid-cols-1  gap-4   pb-2 pt-2'>
                                        {
                                            mydta?.postbacks ? (
                                                <>
                                                    <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                                        <p className='text-sm  font-bold'>EVENT</p>

                                                        <p className='text-sm font-bold text-center'>CODE</p>
                                                        <p className='text-sm font-bold text-center'>STATUS</p>
                                                        <p className='text-sm font-bold text-center'>ACTION</p>
                                                    </div>

                                                    {
                                                        mydta?.postbacks && mydta?.postbacks?.map((val, i) => (
                                                            <div key={i} className='grid grid-cols-4  gap-4  border-b pb-2 pt-1 justify-center items-center'>

                                                                <p className='text-sm uppercase' >{val.event} <span className='text-xs lowercase '>{val?.goal}</span></p>

                                                                <p onClick={() => handleviwpostback(val.data)} className='text-sm text-center text-blue-700 font-semibold cursor-pointer'>VIEW</p>
                                                                <Chip

                                                                    size="sm"
                                                                    variant="ghost"
                                                                    value={'ACTIVE'}
                                                                    color={
                                                                        "green"
                                                                    }
                                                                />
                                                                <Tooltip content="Delete">
                                                                    <IconButton className='text-center' onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                                        <TrashIcon className="h-4 w-4" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        ))
                                                    }
                                                </>

                                            ) : (
                                                <p>No Data Found</p>

                                            )
                                        }
                                    </div>




                                </div>


                            </div>
                        </div>
                    } />

                </div >
                    :
                    <Page404Found />
            }
        </div >

    )
}

export default Viewcampaign