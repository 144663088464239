import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Authcontext } from '../../../Context/Authcontext'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import SabpaisaPaymentGateway from '../../../SabpaisaPaymentGateway'
import gpeapi from '../../../apis/gpeapi'
import { Spinner } from '@material-tailwind/react'

const Checkoutagency = () => {

    const navigate = useNavigate()

    const { toggle } = useContext(Authcontext)
    const { platformurl } = useParams()
    const basicdta = useSelector((state) => state.basicReducer)[0]
    const token = localStorage.getItem('token')

    const { state } = useLocation()


    // console.log(state)
    const [isOpen, setIsOpen] = useState(false);

    const [mydta, setmydta] = useState({
        name: '',
        email: '',
        mobile: '',
        company_name: '',
        taxid: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        country: 'India'
    })

    const [plandata,setplandata] = useState(null)

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))


    // console.log(userinfo)
    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    useEffect(() => {
        callforfirst()
    }, [])

    const callforfirst = () => {
        setmydta({ ...mydta, name: userinfo?.name, email: userinfo?.email, mobile: userinfo?.mobile, company_name: userinfo?.companyname, taxid: userinfo?.taxid })
        state && setplandata(state)

    }

    const location = useLocation()

    const resutfromResponse = location?.search?.replace('?', "");

    const pairs = resutfromResponse.split("&")


    const [loading, setloading] = useState(false)
    const obj = {}

    const keyValuePairs = pairs.map((pair, index) => {
        const [key, value] = pair.split("=")
        const decodedValue = decodeURIComponent(value)
        obj[key] = decodedValue
        return { id: index + 1, [key]: decodedValue }
    })


    // console.log(location?.search)
    // console.log(Boolean(location?.search))
    // console.log(Boolean(obj.status), 'obj')

    useEffect(() => {
        callforobjupdate()
    }, [obj])


    const callforobjupdate = async() => {
        // console.log(obj,'myobjjj')
        if (obj?.status == 'SUCCESS' || obj?.status == 'FAILED') {

            navigate(`/p/${platformurl}/agency/paymentdone`, { state: obj })
        }

   

            // if (obj?.status) {

            //     const userinfo = JSON.parse(sessionStorage.getItem('mydta'))
            //     const planinfo = JSON.parse(sessionStorage.getItem('plandata'))
            
            
            //             const dtaa = {
            //                 paymentinfo:obj,
            //                 invoiceinfo:userinfo,
            //                 planinfo:planinfo,
            //                 planid:planinfo?.id,
            //                 refid:obj?.clientTxnId
            //             }
            
            
            
            //             setloading(true)
            //             if(obj?.status=='SUCCESS'){
            //                 await gpeapi.post(`/${platformurl}/agency/createpaymenthis/`,dtaa,{headers:{
            //                     "Authorization":`Beaer ${token}`
            //                 }}).then((res) => {
            //                     if(res.data.success){
            //                         obj.status='SUCCESS'
            //                     }else{
            //                       obj.status='FAILED'
            //                     }
                
            //                 }).catch((e) => {
            //                     obj.status='FAILED'
            //                 })
            //             }
                        
            //             setloading(false)
            //             navigate(`/${platformurl}/agency/paymentdone`, { state: obj })
            
            //         }
            
    

        

    }


    const callforsaveloca = ()=>{
        sessionStorage.removeItem('mydta')
        sessionStorage.removeItem('plandata')
       
        sessionStorage.setItem('mydta',JSON.stringify(mydta))
        sessionStorage.setItem('plandata',JSON.stringify(state))
       
        
    }

    // console.log(obj)


    //   console.log(obj?.status)
    //   console.log(keyValuePairs[11]?.value)




    const handleproceedpay = async (e) => {

        if (mydta.name !== '') {
            if (mydta.email !== '') {
                if (mydta.mobile !== '') {
                    if (mydta.company_name !== '') {
                        if (mydta.address !== '') {
                            if (mydta.city !== '') {
                                if (mydta.state !== '') {
                                    if (mydta.pincode !== '') {
                                        if (mydta.country !== '') {

                                            callforsaveloca()


                                            setIsOpen(true);
                                            e.preventDefault()
                                            const btn = document.getElementById('renderSabPaisa');
                                            btn?.click();

                                            // console.log(e)




                                        } else {
                                            toast.warn('Please Enter Country Name')
                                        }
                                    } else {
                                        toast.warn('Please Enter Postal Code')
                                    }
                                } else {
                                    toast.warn('Please Enter State')
                                }
                            } else {
                                toast.warn('Please Enter City')
                            }

                        } else {
                            toast.warn('Please Enter Full Address')
                        }
                    } else {
                        toast.warn('Please Enter Company Name')
                    }
                } else {
                    toast.warn('Please Enter Mobile')
                }
            } else {
                toast.warn('Please Enter Email')
            }
        } else {
            toast.warn('Please Enter Name')
        }

    }




    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>


            <SabpaisaPaymentGateway amount={state?.price + (state?.price * 18) / 100} payerName={mydta.name} payerEmail={mydta.email} payerMobile={mydta.mobile} isOpen={isOpen} />


            <ToastContainer />
            {/* <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
                <a href="#" className="text-2xl font-bold text-gray-800">sneekpeeks</a>
                <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
                    <div className="relative">
                        <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
                            <li className="flex items-center space-x-3 text-left sm:space-x-4">
                                <a className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#"
                                ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
                                    ></a>
                                <span className="font-semibold text-gray-900">Shop</span>
                            </li>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <li className="flex items-center space-x-3 text-left sm:space-x-4">
                                <a className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2" href="#">2</a>
                                <span className="font-semibold text-gray-900">Shipping</span>
                            </li>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <li className="flex items-center space-x-3 text-left sm:space-x-4">
                                <a className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white" href="#">3</a>
                                <span className="font-semibold text-gray-500">Payment</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}

            {
                loading?<Spinner/>:
                state &&

                <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
                    <div className="px-4 pt-8">
                        <p className="text-xl font-medium">Order Summary</p>
                        <p className="text-gray-400">Check your items. And select a Address</p>
                        <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">

                            <div className="flex flex-col rounded-lg bg-white sm:flex-row">
                                <img className="m-2 h-14 w-14 rounded-md border object-cover object-center" src={basicdta?.icon} alt={basicdta?.appname} />
                                <div className="flex w-full flex-col px-4 py-4">
                                    <span className="font-semibold">{state?.name}</span>
                                    {
                                        state?.note?.split('\n')?.map((mvva) => (
                                            <span className="float-right text-gray-400">{mvva}</span>
                                        ))
                                    }
                                    <p className="text-lg font-bold">₹{state?.price}</p>
                                </div>
                            </div>

                        </div>


                    </div>

                    {/* <form > */}
                    <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
                        <p className="text-xl font-medium">Billing information</p>
                        <p className="text-gray-400">Fill the form below in order to complete the purchase.</p>
                        <div className="">
                            <label for="name" className="mt-4 mb-2 block text-sm font-medium">Full Name</label>
                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.name} type="text" id="name" name="name" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter your Full Name" />

                            </div>

                            <label for="email" className="mt-4 mb-2 block text-sm font-medium">Email</label>

                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.email} type="text" id="email" name="email" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="your.email@gmail.com" />

                            </div>

                            <label for="mobile" className="mt-4 mb-2 block text-sm font-medium">Mobile</label>
                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.mobile} type="number" id="mobile" name="mobile" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter your Mobile Number" />

                            </div>

                            <label for="company" className="mt-4 mb-2 block text-sm font-medium">Company Name</label>
                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.company_name} type="text" id="company_name" name="company_name" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter your Company Name" />

                            </div>

                            <label for="tax" className="mt-4 mb-2 block text-sm font-medium">VAT/GST (Tax ID)</label>
                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.taxid} type="text" id="taxid" name="taxid" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter your VAT/GST (Tax ID)" />

                            </div>

                            <label for="address" className="mt-4 mb-2 block text-sm font-medium">Address</label>
                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.address} type="text" id="address" name="address" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter your Full Address" />

                            </div>


                            <div className='flex'>

                                <div>
                                    <label for="address" className="mt-4 mb-2 block text-sm font-medium">City</label>
                                    <div className="relative">
                                        <input onChange={handleChnage} value={mydta.city} type="text" id="address" name="city" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="City" />

                                    </div>
                                </div>

                                <div>
                                    <label for="address" className="mt-4 mb-2 block text-sm font-medium">State</label>
                                    <div className="relative">
                                        <input onChange={handleChnage} value={mydta.state} type="text" id="address" name="state" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="State" />

                                    </div>
                                </div>

                                <div>
                                    <label for="address" className="mt-4 mb-2 block text-sm font-medium">Zip/Postal Code </label>
                                    <div className="relative">
                                        <input onChange={handleChnage} value={mydta.pincode} type="text" id="address" name="pincode" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Zip/Postal Code" />

                                    </div>
                                </div>





                            </div>







                            <label for="card-holder" className="mt-4 mb-2 block text-sm font-medium">Country</label>
                            <div className="relative">
                                <input onChange={handleChnage} value={mydta.country} type="text" id="card-holder" name="country" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Enter Your Country Name" />

                            </div>




                            {/* <!-- Total --> */}
                            <div className="mt-6 border-t border-b py-2">
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-gray-900">Subtotal</p>
                                    <p className="font-semibold text-gray-900">₹{state?.price}</p>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-gray-900">TAX (18 %)</p>
                                    <p className="font-semibold text-gray-900">₹{(state?.price * 18) / 100}</p>
                                </div>
                            </div>
                            <div className="mt-6 flex items-center justify-between">
                                <p className="text-sm font-medium text-gray-900">Total</p>
                                <p className="text-2xl font-semibold text-gray-900">₹{state?.price + (state?.price * 18) / 100}</p>
                            </div>
                        </div>
                        <button onClick={handleproceedpay} className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">Proceed to Payment</button>
                    </div>

                    {/* </form> */}
                </div>

            }




        </div>
    )
}

export default Checkoutagency