import { CFormInput } from '@coreui/react';
import React from 'react'
import { Input } from 'react-rainbow-components';


const Myinput = ({tips,line,max, label,disabled, placeholder, readonly,onChange, value, name, type,width,required ,classname,multiline}) => {

    const containerStyles = {
        maxWidth: 950,
        margin: '25px 10px',
        borderRadius: 5

    };

    

    // const containerStyles = {
    //     maxWidth: 700,
    //     position:'relative'
    // };
    return (

        // width:width?width==0?null:width:250


        <div class="mb-4 m-1" className={classname} style={{position:'relative',width:width?width==1?null:width:250}}>
        <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label} {required && <span style={{color:'red',textAlign:'center',marginLeft:5}}> *</span>} </label>

        {
            multiline?(
                <textarea readOnly={readonly} rows={line} name={name} type={type || 'text'} placeholder={placeholder} value={value==null?'':value} onChange={onChange} id="default-input" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "/>
            ):(
                <input disabled={disabled} maxLength={max}  readOnly={readonly} style={{borderRadius:5}} required={required} name={name} type={type || 'text'} placeholder={placeholder} value={value==null?'':value} onChange={onChange}  class="bg-white border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"/>
            )
        }
        <p className='text-xs text-gray-500'>{tips}</p>
       
    </div>


    )
}

export default Myinput

