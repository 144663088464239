import React, { useContext, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useNavigate, useParams } from 'react-router-dom'

import { Spinner } from '@material-tailwind/react'
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,

} from "@material-tailwind/react";
import { DatePicker, MonthlyCalendar } from 'react-rainbow-components'
import { Empty, Spin, Switch } from 'antd'

// import DatePicker from "react-datepicker";


import { Authcontext } from '../../../../../../../../Context/Authcontext'
import gpeapi from '../../../../../../../../apis/gpeapi';
import Mybtn from '../../../../../../../../COmponents/Mybtn';
import Myinput from '../../../../../../../../COmponents/Myinput';
import Mydatepick from '../../../../../../../../COmponents/Mydatepick';


const Createinvoicepub = () => {

    const token = localStorage.getItem('token')
    const {platformurl,pubid} = useParams()

    

    // useEffect(() => {
    //     setplatformname(getSubdomain(window.location.host))
    // }, [])
    const navigate = useNavigate()

    const TABLE_HEAD = ["Item (Campaign)", "Quantity (Conversion)", "Price (Payout)", "Total"];


    const { toggle } = useContext(Authcontext)
    const [newchatmodel, setnewchatmodel] = useState(false)
    const [statsdata, setstatdata] = useState([])
    const [loading, setloading] = useState(false)
    const [fetchload, setfetchload] = useState(false)



    const [range, setrange] = useState(null)
    const [monthrange, setmonthrange] = useState(null)

    const [invoicetotal, setinvoicetotal] = useState(0)


    const [showinput, setshowinput] = useState({
        index: null,
        enable: false
    })



    const [addgst, setaddgst] = useState(true)


    const handlefetchdata = async () => {

        if (range == null) {
            toast.warning('Please Select Date')

        } else {
            setfetchload(true)
            const res = await gpeapi.post(`/${platformurl}/${pubid}/genraterepoforinvoicemonthpubadmin`, { date: range }, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }).then((ress) => {
                toast.success(ress.data.msg)
                setstatdata(ress?.data?.TotalUsers.docs)
            }).catch((e) => {
                toast.error(e?.response?.data?.msg)

            })
            setfetchload(false)
        }

    }


    useEffect(() => {
        callforcountTotal()
    }, [statsdata, addgst])


    const callforcountTotal = async () => {
        const valdata = statsdata?.map((val, i) => val.payout * val.conversion)
        const totaldta = valdata.length && valdata?.reduce((prev, cur) => prev + cur)
        const getgst = totaldta * 18 / 100

        if (addgst) {

            setinvoicetotal(totaldta + getgst)
        } else {
            setinvoicetotal(totaldta)

        }

    }


    const handlechnagepo = (e, i) => {
        // console.log(e.target.value)
        // console.log(i)
        let newArr = [...statsdata]
        newArr[i][e.target.name] = e.target.value
        setstatdata(newArr)
    }

    // console.log(statsdata)

    const handlegenerateinvoice = async () => {

        let dtaa = {
            date: range,
            invoicedata: statsdata,
            total: invoicetotal,
            addgst: addgst

        }

        setloading(true)
        const res = await gpeapi.post(`/${platformurl}/${pubid}/createcampinvoicepubadmin`, dtaa, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            toast.success(ress.data.msg)
            setTimeout(() => {
                navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/manageinvoice`)

                // navigate('/publisher/manageinvoice')
            }, 3000);
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)



    }

    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />




            <div>

                <div className='flex justify-between items-center m-2'>

                    <p className='font-semibold text-lg'>CREATE INVOICE</p>

                    <div className='sm:w-full md:w-[40%] flex '>

                        <div className=''>

                            <Mydatepick value={monthrange} placeholder={'Select Month'} onChange={(e) => {

                                setmonthrange(e.target.value)
                                setrange(`${e.target.value}-14`)
                            }} />



                            <div className='flex flex-wrap justify-center mt-1'>

                                {/* {
                                        calendertypetda?.map((val) => (

                                            <p onClick={() => handlecalendtypeclk(val.value)} style={{ backgroundColor: 'gray' }} className='text-xs text-white m-0.5 cursor-pointer p-0.5 capitalize rounded-md font-semibold'>{val.label}</p>
                                        ))
                                    } */}
                            </div>

                        </div>



                        <div>

                            <Button disabled={fetchload} className='ml-1 mr-1' onClick={handlefetchdata} size="sm">{fetchload ? <Spinner /> : 'Fetch'}</Button>
                        </div>


                    </div>



                </div>










            </div>



            <div className='bg-white '>
                {
                    statsdata.length ? <CardBody className="overflow-scroll px-0">
                        <table id='table' className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {!statsdata?.length ? (
                                    <td colSpan={TABLE_HEAD.length}>

                                        <div className='flex justify-center text-center m-2'>
                                            <Empty />
                                        </div>
                                    </td>
                                ) : (
                                    <>

                                        {statsdata?.map(
                                            (val, index) => {
                                                const isLast = index === statsdata?.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                return (
                                                    <tr key={index}>

                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                {val?.campaign} <span className='text-xs text-black'>{val.event && `(${val.event})`}</span>
                                                            </Typography>
                                                        </td>

                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal ">
                                                                {val?.conversion}

                                                            </Typography>
                                                        </td>


                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal ">
                                                                ₹{val?.payout}  {
                                                                    showinput.index == index && showinput.enable ? <span className='ml-10 text-sm font-semibold text-blue-500 cursor-pointer' onClick={() => setshowinput({ enable: false, index: null })}>Done</span> : <span className='ml-10 text-sm font-semibold text-blue-500 cursor-pointer' onClick={() => setshowinput({ enable: true, index: index })}>Edit</span>

                                                                }

                                                            </Typography>

                                                            {
                                                                showinput.index == index && showinput.enable && <Myinput type={'number'} value={val.payout} name={'payout'} onChange={(e) => handlechnagepo(e, index)} placeholder={'Optional for Update Payout'} />
                                                            }
                                                        </td>


                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                ₹{val?.conversion * val.payout}

                                                            </Typography>
                                                        </td>



                                                    </tr>
                                                );
                                            },
                                        )}

                                        {
                                            addgst ? <tr >

                                                <td className='p-4'>
                                                    <Typography variant="small" color="black" className="font-semibold uppercase">
                                                        GST (18%)
                                                    </Typography>
                                                </td>


                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                    </Typography>
                                                </td>

                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                    </Typography>
                                                </td>

                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        ₹{(invoicetotal * 18 / 100).toFixed(2)}

                                                    </Typography>
                                                </td>





                                            </tr> : null
                                        }

                                        <tr >

                                            <td className='p-4'>
                                                <Typography variant="small" color="black" className="font-semibold uppercase">
                                                    Total
                                                </Typography>
                                            </td>


                                            <td className='p-4'>
                                                <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                </Typography>
                                            </td>

                                            <td className='p-4'>
                                                <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                </Typography>
                                            </td>

                                            <td className='p-4'>
                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                    ₹{(invoicetotal).toFixed(2)}

                                                </Typography>
                                            </td>





                                        </tr>
                                    </>
                                )

                                }
                            </tbody>
                        </table>
                    </CardBody> : null
                }


                {
                    statsdata.length ? <div className='flex flex-col items-center justify-center '>


                        <p className='text-sm text-gray-500 m-7'>
                            * When you click generate invoice button then you can't edit invoice after invoice created
                        </p>

                        <div className='m-5'>
                            <span>Add GST (18%) : </span>
                            <Switch defaultChecked onChange={(e) => setaddgst(e)} />
                        </div>

                        <Mybtn loading={loading} onClick={handlegenerateinvoice} radius={10} title={'Generate Invoice and Send'} />
                    </div> : null
                }






            </div>







        </div>
    )
}

export default Createinvoicepub