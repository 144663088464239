import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Authcontext } from '../../../Context/Authcontext'
import { useSelector } from 'react-redux'
import gpeapi from '../../../apis/gpeapi'
import { Spinner } from '@material-tailwind/react'
import Mybtn from '../../../COmponents/Mybtn'
import { ToastContainer, toast } from 'react-toastify'

const Paymentpage = () => {
    const token = localStorage.getItem('token')

    const navigate = useNavigate()

    const { toggle } = useContext(Authcontext)
    const { platformurl } = useParams()
    const basicdta = useSelector((state) => state.basicReducer)[0]
    const [loading, setloading] = useState(false)

    const { state } = useLocation()

    useEffect(()=>{
        callforfirstt()
    },[])

    const callforfirstt = async ()=>{
        if (state?.status) {

            const userinfo = JSON.parse(sessionStorage.getItem('mydta'))
            const planinfo = JSON.parse(sessionStorage.getItem('plandata'))
        
        
                    const dtaa = {
                        paymentinfo:state,
                        invoiceinfo:userinfo,
                        planinfo:planinfo,
                        planid:planinfo?.id,
                        refid:state?.clientTxnId
                    }
        
        
        
                    if(state?.status=='SUCCESS'){
                        setloading(true)
                        await gpeapi.post(`/${platformurl}/agency/createpaymenthis/`,dtaa,{headers:{
                            "Authorization":`Beaer ${token}`
                        }}).then((res) => {
                            if(res.data.success){
                                toast.success('Payment Success')
                                
                            }else{
                              toast.error('Payment Failed')
                            }
            
                        }).catch((e) => {

                            toast.error('Payment Failed')

                         
                        })
                    }
                    
                    setloading(false)
                    
        
                }
    }


    const handlebacktohome = ()=>{
        navigate(`/p/${platformurl}/agency`)

    }
    // console.log(state)
  return (
    <div style={{ backgroundColor: 'white' }}  className={toggle ? "main active flex justify-center items-center" : "main flex justify-center items-center"}>

        <ToastContainer/>

        {
            loading?  <div className='border border-blue-gray-300 p-5 rounded-md flex flex-col justify-center items-center'>
            
           <Spinner/>
        </div>:
        

        <div className='border border-blue-gray-300 p-5 rounded-md flex flex-col justify-center items-center'>
            
            <img className='w-2/12 h-1/6' src={state?.status=='SUCCESS'?"https://cdn-icons-png.flaticon.com/512/5709/5709755.png":"https://printme.online/wp-content/uploads/2020/04/payment_fail_icon.png"}/>

            <h2 className='font-bold text-lg'>{state?.status=='SUCCESS'?"Payment Success":"Payment Failed"}</h2>

            <div>
                <Mybtn title={'Back To Home'} onClick={handlebacktohome}/>
            </div>

        </div>

        }


    </div>

  )
}

export default Paymentpage