import React from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'

const Howitwork = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

  return (
    <section style={{ marginTop: 50 }} class="section contact " id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>How it Works</h2>

                <p class="section-text">

                </p>


                

                <section className='mt-20'>

                    {/* <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p> */}

                    <div className='   flex p-3 shadow-lg flex-col  justify-around items-center bg-[#cad8ff] rounded-lg sm:flex-row md:flex-row xl:flex-row 2xl:flex-row  '>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <h1 style={{color:'white'}} className='h3 bg-[#292e57] px-5 py-1 rounded-full '>1</h1>

                            <p style={{color:COLORS.primary,fontWeight:'bold',fontSize:17,marginTop:5}} className='text-2xl '>Sign Up</p>
                            <p className=' text-[#104dd3] text-sm text-center'>Easily get started by signing up for OfferBorn 14-day free trial. Our software is available for networks</p>
                        </div>

                        <div className='flex flex-col justify-center p-5 items-center'>
                        <h1 style={{color:'white'}} className='h3 bg-[#292e57] px-5 py-1 rounded-full '>2</h1>


                            <p style={{color:COLORS.primary,fontWeight:'bold',fontSize:17,marginTop:5}} className='text-2xl'>Create Offers</p>
                            <p className='text-[#104dd3] text-sm text-center'>Create advertising offers or pull offers from other platforms in a flash. OfferBorn will instantly automate your advertising process</p>
                        </div>

                        <div className='flex flex-col justify-center p-5 items-center'>
                        <h1 style={{color:'white'}} className='h3 bg-[#292e57] px-5 py-1 rounded-full '>3</h1>


                            <p style={{color:COLORS.primary,fontWeight:'bold',fontSize:17,marginTop:5}} className='text-2xl'>See the Boosts</p>
                            <p className='text-[#104dd3] text-sm text-center'>Use our smart tools to track, analyze and optimize your affiliate performance. You'll see your business skyrocket in no time.</p>
                        </div>

                       



                    </div>


                </section>

                






            </div>
        </section>
  )
}

export default Howitwork