import React from 'react'

const Mycard = ({width,compo,classname}) => {
  return (
    <div  className={`rounded-md shadow-md  m-2 bg-white ${classname}`}>
        {compo}
    </div>
  )
}

export default Mycard