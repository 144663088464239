

import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'


import { PencilIcon, TrashIcon,ArrowRightIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Addpublisheragency from './Addpublisheragency'
import { fetchpublisher } from '../../../Redux/Actions/Agency/publisheragencyaction'


const Mainpublishersagency = () => {
    const token = localStorage.getItem('token')

    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)
    const [openmodal, setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)

    const [reduloading, setreduloading] = useState(true)

    const [myid, setmyid] = useState(null)

    const TABLE_HEAD = ["ID", "Name", "Company", "Status", "Manager", "Action"];




    useEffect(() => {
        callforReducer()
    }, [])

    const callforReducer = async () => {
        setreduloading(true)
        await dispatch(fetchpublisher(platformurl))
        setreduloading(false)
    }

    const advertiserdta = useSelector((state) => state.publisherAgencyReducer)
    // console.log(advertiserdta)
    const pageNumber = []

    for (let i = 1; i <= Math.ceil( advertiserdta[0]?.total / advertiserdta[0]?.limit); i++) {
        pageNumber.push(i)
    }

    const handlepaginate = async (number) => {
        // console.log(number-1)
        setreduloading(true)
        await dispatch(fetchpublisher(platformurl, number - 1))
        setreduloading(false)


    }

    const onNextClick = async () => {
        setreduloading(true)
        await dispatch(fetchpublisher(platformurl,  advertiserdta[0]?.page + 1))
        setreduloading(false)
    }

    const onPrevClick = async () => {
        setreduloading(true)
        await dispatch(fetchpublisher(platformurl, advertiserdta[0]?.page - 1))
        setreduloading(false)
    }

    const onEditUser = (id) => {
        setopenmodal(true)
        setmyid(id)
    }

    const onDeleteUser = (id) => {
        setopenpop(true)
        setmyid(id)
    }

    const handleDeleteAdv = async()=>{
        setreduloading(true)
        await gpeapi.delete(`/${platformurl}/agency/deletepublisher/${myid}`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res) => {
            if (res.data.success) {
                toast.success('Publisher Deleted Done')
                
                dispatch(fetchpublisher(platformurl))


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setopenpop(false)
        setmyid(null)
        setreduloading(false)

    }

    const onLoginas = (id)=>{
        // navigate(`/${platformurl}/agency/viewcamp/${id}`)
        window.open(`/p/${platformurl}/agency/loginaspub/${id}`)

    }

    // console.log(myid)
// console.log(advertiserdta )

    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
            {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



            <ToastContainer />


            <Mymodal handleoutopen={()=>setmyid(null)} Components={<Addpublisheragency id={myid} setmodal={setopenmodal} />} open={openmodal} setopenmodal={setopenmodal} />

            <Mypopup handleoutopen={()=>setmyid(null)} title={`Are you Sure`} des={`you want to delete id ${myid}?`  } open={openpop} setopenpop={setopenpop} onConfirm={handleDeleteAdv}/>



            <p className='text-2xl font-medium'>MANAGE PUBLISHERS</p>

            <div>
                <Mybtn onClick={() => setopenmodal(true)} title={'ADD NEW'} padding={'0px 15px'} radius={12} fontsize={14} />
            </div>


            {
                reduloading ? <div className='flex justify-center items-center'>
                    <Spinner className="h-10 w-10  text-blue-500/10" />
                </div> : (
                   advertiserdta && advertiserdta[0]?.docs?.length==0?<Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography>:
                    <div>
                        <CardBody className="overflow-scroll px-0">
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {advertiserdta && advertiserdta[0]?.docs?.map(
                                        (val, index) => {
                                            const isLast = index === advertiserdta[0]?.docs?.length - 1;
                                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                            return (
                                                <tr key={index}>
                                                    <td className={classes}>


                                                        <Typography variant="small" color="blue-gray" className="font-bold">
                                                            {val?.id}
                                                        </Typography>

                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.name}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.company}

                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="w-max">
                                                            <Chip
                                                                size="sm"
                                                                variant="ghost"
                                                                value={val?.ac_status}
                                                                color={
                                                                    val?.ac_status === "active" ? "green" : val?.ac_status === "pending" ? "amber" : "red"
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.ac_manager}

                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Tooltip content="Edit">
                                                            <IconButton onClick={() => onEditUser(val.id)} variant="text" color="blue-gray">
                                                                <PencilIcon className="h-4 w-4" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip content="Delete">
                                                            <IconButton onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                                <TrashIcon className="h-4 w-4" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip content="Loginas Publisher">
                                                    <IconButton onClick={() => onLoginas(val?.id)} variant="text" color="blue-gray">
                                                        <ArrowRightIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </CardBody>


                        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                            <Button onClick={onPrevClick} disabled={ advertiserdta[0]?.page + 1 == 1 ? true : false} variant="outlined" color="blue-gray" size="sm">
                                Previous
                            </Button>
                            <div className="flex items-center gap-2">

                                {pageNumber?.map((val, i) => (
                                    <IconButton key={i} onClick={() => handlepaginate(val)} variant={val - 1 == advertiserdta[0]?.page ? 'outlined' : 'text'} color="blue-gray" size="sm">
                                        {val}
                                    </IconButton>
                                ))}


                            </div>
                            <Button onClick={onNextClick} disabled={ advertiserdta[0]?.page ==  advertiserdta[0]?.pages} variant="outlined" color="blue-gray" size="sm">
                                Next
                            </Button>
                        </CardFooter>
                    </div>
                )
            }






        </div>
    )
}

export default Mainpublishersagency