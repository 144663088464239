import gpeapi from "../../../apis/gpeapi"
import { SET_AGENCYADV, SET_AGENCYPUB } from "../../actiontypes/usertypes";

const token = localStorage.getItem('token')

export const fetchadvertiser = (platformurl,page,limit,search,userid)=>{

    return async function (dispatch){
        // console.log(platformurl)

        const res = await gpeapi.get(`/${platformurl}/agency/getadvertisers?page=${page?page:0}&limit=${limit?limit:10}&search=${search?search:''}&userid=${userid?userid:''}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setAgencyAdv([res.data.TotalUsers]));


    }

}





export const setAgencyAdv = (data = null) => {
    if (data) {
        return {
            type: SET_AGENCYADV,
            payload: data,
        };
    }

    return {
        type: SET_AGENCYADV,
        payload: [],
    };
};