import React, { useEffect } from 'react'
import { COLORS } from '../../Constants'

const Thankyou = () => {

  document.title = `Welcome to the OfferBorn Family - OfferBorn`

  useEffect(()=>{
    window.scrollTo(0,0)
},[])
  return (
    <section style={{marginTop:50}} class="section contact" id="contact" aria-label="contact">
    <div class="container">

      {/* <h2 class="h3 section-title font-bold">Privacy Policy</h2> */}
      
      <p class="section-text">
      
      </p>


      {/* <h1 >Privacy Policy for OfferBorn</h1>

      <p>
        {basicData?.privacy_policy}
      </p> */}

      <div className='mt-5 flex flex-col justify-center items-center'>

        <img style={{height:100,width:100}} src={require('../img/tickicon.png')}/>

      <h2 style={{color:COLORS.primary,marginTop:10}} class="h3 section-title font-bold">Thank You for Registering!</h2>
      <h2 style={{color:COLORS.primary,marginTop:5}} class="font-normal text-lg">Please check you inbox for further communication and verification.

</h2>
      </div>



 </div>
      </section>
  )
}

export default Thankyou