
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import CountUp from 'react-countup';
import { COLORS } from '../../../Constants';

const Offermanagmentpage = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    // document.title = `About Us - OfferBorn`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section style={{ marginTop: 50 ,backgroundColor:'white'}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Offer Management

                </h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" >

                        <figure class="hero-banner" >
                            <img src={require('../../img/Easy-access.png')} width="200" height="100" alt="hero banner" class="w-60 border  border-yellow-300 rounded-full p-0" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Offer Access Control

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            You don't need to face any complications in going about with the offer. After understanding what are the different types of offers that have come up from the advertiser you can easily gain power over it and see to it who is the right affiliate for it and accordingly distribute the offer to them. All this can be done by following a few simple steps. Simplify Your Access
                            </p>



                        </div>
                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/Verified.png')} width="200" height="100" alt="hero banner" class="w-60" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Offer Approval System

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Approve offers to only verified or highly trusted publishers to create a standard for your network, move towards growth and prosperity, and make more revenue with trusted sources. This helps in creating branding for your business as top publishers/affiliates who have a loyal audience will be promoting your offer and this will eventually impact your overall revenue in a big way. Elevate Your Network
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'ltr',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/Remote-access.png')} width="200" height="100" alt="hero banner" class="w-60" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Offer Redirection Management

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            This process not only simplifies the user journey but also provides valuable data insights that can be leveraged for continuous improvement. Offer redirection management helps in hiding important links for your offers and advertiser's information. Redirect Smartly
                                
                            </p>



                        </div>
                    </div>
                </section>

            



                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/multi-currency.gif')} width="200" height="100" alt="hero banner" class="w-60" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Goals and Payout


</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            It is extremely important to create event and payout so you can create event like - install,register,deposit like this then you can also create diffrent diffrent payout for diffrent diffrent country and you can assign campaigns and offers
                            </p>



                        </div>
                    </div>
                </section>

              






            





            </div>
        </section>
    )
}

export default Offermanagmentpage