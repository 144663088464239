import { SET_AGENCYADV, SET_AGENCYPUB } from "../../actiontypes/usertypes";


const advertiserAgencyReducer = (state=[],action)=>{
    switch (action.type) {
        case SET_AGENCYADV:
            return [...action.payload]
            break;

           
    
        default:
           return state;
    }
}

export default advertiserAgencyReducer