
import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'

import { PencilIcon, TrashIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { fetchcampaign } from '../../../Redux/Actions/Agency/campaignagencyaction'
import { fetchpricing } from '../../../Redux/Actions/pricingaction'
import Mainaccountagency from '../../../Agency/Pages/Account/Mainaccountagency'

const Mainagencypublishers = () => {
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)
    const [openmodal, setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)

    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(20)
    const [search, setsearch] = useState('')


    useEffect(() => {
        callforAPI()
    }, [limit])

    const [showpaid, setshowpaid] = useState(false)

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/getagencypublishersadm?limit=${limit}&search=${search}&showpaid=${showpaid}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const callanydate = (date) => {

        return date?.split('T')[0]
    }

    const handlepaginate = async (number) => {
        // console.log(number)
        setloading(true)
        const res = await gpeapi.get(`/getagencypublishersadm?limit=${limit}&search=${search}&page=${number - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }

    const onNextClick = async () => {
        setloading(true)
        const res = await gpeapi.get(`/getagencypublishersadm?limit=${limit}&search=${search}&page=${statsdata.page + 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })

        setloading(false)
    }

    const handleChnageSelect = async (e) => {
        setlimit(e)
    }

    const onPrevClick = async () => {
        setloading(true)
        const res = await gpeapi.get(`/getagencypublishersadm?limit=${limit}&search=${search}&page=${statsdata.page - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }





    const [myid, setmyid] = useState(null)

    const TABLE_HEAD = ["ID", "Name", "AgencyID", "Status", "Date"];




    const handleTitleclik = (id) => {
        // navigate(`/${platformurl}/agency/viewcamp/${id}`)
    }

    // console.log(statsdata)

    const onLoginas = (id) => {
        // navigate(`/${platformurl}/agency/viewcamp/${id}`)
        window.open(`/offerborn/amd/admin/agencylogin/${id}`)

    }


    const handleshowpaid = () => {
        setshowpaid(!showpaid)
        callforAPI()

    }

    const getdatetimevia = (date) => {
        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var second = mydate.getSeconds();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        second = second < 10 ? '0' + second : second;
    
        var strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;
    
        return `${todate}-${month + 1}-${year} at ${strTime}`
      }

    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
            {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



            <ToastContainer />






            <p className='text-2xl font-medium'>MANAGE AGENCY Publishers</p>

           

            


            {
                loading ? <div className='flex justify-center items-center'>
                    <Spinner className="h-10 w-10  text-blue-500/10" />
                </div> : (
                    statsdata && statsdata?.docs?.length == 0 ? <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography> :
                        <div>
                            <CardBody className="overflow-scroll px-0">
                                <table className="w-full min-w-max table-auto text-left">
                                    <thead>
                                        <tr>
                                            {TABLE_HEAD.map((head) => (
                                                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70"
                                                    >
                                                        {head}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statsdata?.docs && statsdata?.docs?.map(
                                            (val, index) => {
                                                const isLast = index === statsdata?.docs?.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                return (
                                                    <tr key={index}>
                                                        <td className={classes}>


                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                {val?.id}
                                                            </Typography>

                                                        </td>
                                                        <td className={classes}>
                                                            <Typography  variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                {val?.name}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography onClick={() => handleTitleclik(val.id)} variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                {val?.agencyid}
                                                            </Typography>
                                                        </td>

                                                      

                                                        <td className={classes}>
                                                            <div className="w-max">
                                                                <Chip
                                                                    size="sm"
                                                                    variant="ghost"
                                                                    value={val?.ac_status}
                                                                    color={
                                                                        val?.ac_status == 'active' ? "green" : "red"
                                                                    }
                                                                />

                                                            </div>
                                                        </td>

                                                        
                                                        

                                                        <td className={classes}>
                                                            <Typography variant="small" color="black" className="font-normal ">
                                                                {getdatetimevia(val?.createdAt)} 

                                                              

                                                            </Typography>
                                                        </td>
                                                        








                                                    </tr>
                                                );
                                            },
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            <Mypaginate
                                onNextClick={onNextClick}
                                onPrevClick={onPrevClick}
                                paginate={handlepaginate}
                                currentPage={statsdata?.page}
                                totalData={statsdata?.total}
                                perPage={statsdata?.limit}
                                Totalpage={statsdata?.pages}
                            />





                        </div>
                )
            }






        </div>
    )
}

export default Mainagencypublishers

