import React from 'react'
import { COLORS } from '../../../Constants'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Ourmission from '../Ourmission'
import Howitwork from '../Howitwork'
import CountUp from 'react-countup'
// import '../../mgoble.css'
// import '../../mgloble.module.css'

// import mstyle from '../../mgloble.module.css'

const Mainhome = () => {

  const pricing = useSelector((state) => state.pricingReducer)[0]

  // console.log(pricing)

  return (
    <div>

      <section style={{ marginTop: 50 }} class="section hero" id="home" aria-label="hero">
        <div class="container">

          <div class="hero-content">

            {/* <p class="hero-subtitle">GwalaxMedia Marketing Agency</p> */}

            <h1 class="h3 hero-title" style={{ fontWeight: 800, color: COLORS.primary, }}>Best Affordable Performance Marketing Software in India</h1>

            <p class="hero-text capitalize">
              For affiliates, advertisers and ad networks to help them track, Optimise Your Marketing Performance Skilfully With Real-time Partner Analytics Data
            </p>

            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: 15 }}>
              <a href="/signup" class="btn btn-primary uppercase">Start 15 Days Free Trial</a>
            </div>
            {/* <a target='_blank' href="https://gwalaxmedia.offer18.com/m/signup_self_aff?r=&am=" class="btn btn-primary">FOR PUBLISHER</a> */}

          </div>

          <figure class="hero-banner">
            <img src={require('../../img/markethome1.png')} width="720" height="673" alt="hero banner" class="w-100" />
          </figure>

        </div>
      </section>

      <section className='section contact  mt-20'>
        <div className='flex justify-center  '>
          
          <img  className='bg-[#b5bfcc] p-6 rounded-xl' src={require('../../img/Offerborn_demo1.png')} width="1100" height="673" alt="hero banner"  />
        </div>

      </section>




      <Ourmission />

      <section className='section contact  mt-20'>

        {/* <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p> */}

        <div style={{ backgroundColor: '#045385' }} className='flex p-3 m-2 shadow-lg flex-col  justify-around items-center  rounded-3xl sm:flex-row md:flex-row xl:flex-row 2xl:flex-row  '>

          <div className='flex flex-col justify-center p-5 items-center'>
            <CountUp
              start={0}
              end={100}
              duration={5}
              separator=" "
              style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
              suffix="+"
            />

            <p className='text-xl text-white'>Global Clients</p>
          </div>

          <div className='flex flex-col justify-center p-5 items-center'>
            <CountUp
              start={0}
              end={4}
              duration={5}
              separator=" "
              style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
              suffix="+"
            />

            <p className='text-xl text-white'>Years Experience</p>
          </div>

          <div className='flex flex-col justify-center p-5 items-center'>
            <CountUp
              start={0}
              end={97}
              duration={5}
              separator=" "
              style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
              suffix="%"
            />

            <p className='text-xl text-white'>Positive Feedbacks</p>
          </div>



        </div>


      </section>

      <Howitwork />








    </div>
  )
}

export default Mainhome