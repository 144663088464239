

import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import Mysidebar from '../../COmponents/Mysidebar';

import { MdOutlineDashboard ,MdCampaign, MdOutlineCampaign, MdOutlineMan4} from "react-icons/md";
import { RiAdvertisementLine, RiSettings4Line } from "react-icons/ri";
import { FaHandsHelping, FaUsers } from "react-icons/fa";

import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown, IoMdPricetags } from "react-icons/io";
import { IoAnalyticsSharp } from "react-icons/io5";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";
import { TbFriends,TbCreditCard} from "react-icons/tb";

import Header from '../../COmponents/Header';



import Mainloginadmin from '../Pages/Login/Mainloginadmin';
import Maindashboard from '../Pages/Dashboard/Maindashboard';
import gpeapi from '../../apis/gpeapi';
import Mainbasicdet from '../Pages/BasicDet/Mainbasicdet';
import Addbasicdet from '../Pages/BasicDet/Addbasicdet';
import Mainpricing from '../Pages/Pricing/Mainpricing';
import Addpricing from '../Pages/Pricing/Addpricing';
import Mainagencyadm from '../Pages/Agencys/Mainagencyadm';
import Addagencyadm from '../Pages/Agencys/Addagencyadm';
import Mainmesageadm from '../Pages/Messages/Mainmesageadm';
import { BiMessageAltDetail, BiNetworkChart } from 'react-icons/bi';
import Mainhome from '../Pages/Home/Mainhome';
import Mainemployeeadm from '../Pages/Employee/Mainemployeeadm';
import Performancerepoagency from '../Pages/Reports/Performancerepoagency';
import Mainadmins from '../Pages/Admins/Mainadmins';
import Addadmins from '../Pages/Admins/Addadmins';
import Mainagencycampaign from '../Pages/Agency-Campaigns/Mainagencycampaign';
import Mainagencypublishers from '../Pages/Agency-Publishers/Mainagencypublishers';
import Mainagencyadvertiser from '../Pages/Agency-Advertiser/Mainagencyadvertiser';
import Mainagencyemployee from '../Pages/Agency-Employee/Mainagencyemployee';
import Mainagencyinvoices from '../Pages/Agency-Invoices/Mainagencyinvoices';


const Mainrouteadmin = () => {
  const token = localStorage.getItem('token')

  const [isvalid,setisvalid] = useState(false)
  const [loading,setloading] = useState(false)

  useEffect(()=>{
    token && checkvalidtoken()
  },[])


  const checkvalidtoken = ()=>{
    gpeapi.get('/checktokenadm',{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>{
    if(res.data.success){
     
    }else{
      localStorage.clear()
      window.location.reload('')
    }
  })
  // console.log('runnn')
  }

  

  const menus = [
    { name: "dashboard", link: `/offerborn/amd/admin/dashboard`, icon: MdOutlineDashboard },
    { name: "pricing", link: `/offerborn/amd/admin/pricing`, icon: IoMdPricetags },
    { name: "messages", link: `/offerborn/amd/admin/message`, icon: BiMessageAltDetail },

    { name: "admins", link: `/offerborn/amd/admin/admins`, icon: BiNetworkChart },
    { name: "agency", link: `/offerborn/amd/admin/agency`, icon: BiNetworkChart },
    { name: "employee", link: `/offerborn/amd/admin/employee`, icon: BiNetworkChart },

    {
      name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
        { name: "performance report", link: `/offerborn/amd/admin/performancerepo` },
        // { name: "daily report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/dailyreport` },
        // { name: "publishers report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/publisherreport` },
        // { name: "advertisers report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/advertiserreport` },
        // { name: "conversion report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/conversionreport` },
        // { name: "clicks report", link: "/" },
        // { name: "sub-id reports", link: "/" },
      ]
    },
  

    // {
    //   name: "campaigns", link: "/", icon: MdCampaign, submenu: [
    //     { name: "all campaigns", link: `/${platformurl}/agency/campaigns` },
    //     { name: "create campaign", link: `/${platformurl}/agency/createcamp` },
        
    //   ]
    // },
    // {
    //   name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
    //     { name: "campaigns report", link: "/" },
    //     { name: "publishers report", link: "/" },
    //     { name: "advertisers report", link: "/" },
    //     { name: "conversion report", link: "/" },
    //     { name: "clicks report", link: "/" },
    //     { name: "sub-id reports", link: "/" },
    //   ]
    // },
    // { name: "publishers", link: "/", icon: TbFriends,submenu:[
    //   { name: "all publishers", link: `/${platformurl}/agency/publishers` },
      

    // ] },
    // { name: "advertisers", link: "/", icon: FaHandsHelping,submenu:[
    //   { name: "all advertisers", link: `/${platformurl}/agency/advertisers` },
      

    // ]  },
    // { name: "Cart", link: "/", icon: FiShoppingCart },
    // { name: "Billing", link: "/", icon: TbCreditCard },
    { name: "agency invoice", link: "/offerborn/amd/admin/agencyinvoice", icon: RiSettings4Line },
    { name: "Setting", link: "/offerborn/amd/admin/setting", icon: RiSettings4Line },
    { name: "agency campaign", link: `/offerborn/amd/admin/agencycampaign`, icon: MdOutlineCampaign },
    { name: "agency publishers", link: `/offerborn/amd/admin/agencypublisher`, icon: FaUsers },
    { name: "agency advertiser", link: `/offerborn/amd/admin/agencyadvertiser`, icon: RiAdvertisementLine },
    { name: "agency employee", link: `/offerborn/amd/admin/agencyemployee`, icon: MdOutlineMan4 },
  ];


  return (

    
    <>

    

  

      {token && <>
        <Mysidebar menus={menus} />
        <div style={{marginTop:60}}>
        <Header/>
        </div>
      </>}

      <Routes>

        {
          token ? (
            <>
              <Route path='/*' element={<Mainhome />} />
              <Route path='/dashboard' element={<Mainhome />} />
              <Route path='/setting' element={<Mainbasicdet />} />
              <Route path='/createdetails' element={<Addbasicdet />} />
              <Route path='/updatedetails/:id' element={<Addbasicdet />} />
              <Route path='/pricing' element={<Mainpricing />} />
              <Route path='/employee' element={<Mainemployeeadm />} />
              <Route path='/addpricing' element={<Addpricing />} />
              <Route path='/updatepricing/:id' element={<Addpricing />} />


              <Route path='/agency' element={<Mainagencyadm />} />
              <Route path='/addagency' element={<Addagencyadm />} />
              <Route path='/updateagency/:id' element={<Addagencyadm />} />


              <Route path='/admins' element={<Mainadmins />} />
              <Route path='/addadmins' element={<Addadmins />} />
              <Route path='/updateadmins/:id' element={<Addadmins />} />

              <Route path='/message' element={<Mainmesageadm />} />
              <Route path='/performancerepo' element={<Performancerepoagency />} />



              <Route path='/agencyinvoice' element={<Mainagencyinvoices />} />


              <Route path='/agencycampaign' element={<Mainagencycampaign />} />
              <Route path='/agencypublisher' element={<Mainagencypublishers />} />
              <Route path='/agencyadvertiser' element={<Mainagencyadvertiser />} />
              <Route path='/agencyemployee' element={<Mainagencyemployee />} />




             
            </>

          ) : (
            <>
              <Route path='/*' element={<Mainloginadmin />} />
              <Route path='/login' element={<Mainloginadmin />} />
            </>
          )
        }

        {/* <Route path='/home' element={<Mainhome/>}/> */}

        {/* <Route path='/register' element={<Mainregister/>}/> */}


      </Routes>
      

    </>
  







  )
}

export default Mainrouteadmin