import React, { useEffect, useState } from 'react';
import { Chart, Dataset, Card, RadioButtonGroup, Spinner } from 'react-rainbow-components';
import styled from 'styled-components';
import gpeapi from '../../../../../apis/gpeapi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ChartHeading = styled.div`
    padding-left: 7px;
    padding-right: 7px;

    @media (max-width: 991px) {
        flex-direction: column;
        :nth-child(1) { text-align: center; }
    }
`;

const Title = styled.h2.attrs(props => {
    return props.theme.rainbow.palette;
})`
    font-size: 26px;
    color: ${props => props.text.label}
`;

const styles = {
    container: { width: '100%' },
    chart: {
        height: '300px'
    }
};

const chartTypes = [
    { value: 'line', label: 'Lines' },
    { value: 'bar', label: 'Bars' }
];



const chartTypeMap = {
    line: 'Lines',
    bar: 'Bar'
};

const CurrentChartType = styled.span.attrs(props => {
    return props.theme.rainbow.palette;
})`
    color: ${props => props.text.header}
`;


const Homestats = () => {
    const token = localStorage.getItem('token')
    const { platformurl ,pubid} = useParams()

    const [chartType, setChartType] = useState('bar');
    const containerStyles = {
        maxWidth: 600,
    };

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


    const data = {
        clicks: [23, 45, 123, 56, 65, 11, 54, 92, 26, 86],
        conversion: [66, 70, 80, 62, 60, 75, 120, 111, 90, 93],

    };



    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()

    useEffect(() => {
        callforAPI()

        // setloading(mloading)

    }, [])

    

  

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/${pubid}/pubagn/pubchartsreport`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setstatdata(ress?.data?.data)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }


    // const callforStat = async()=>{
    //     const mmdta = statsdata && statsdata[0]?.clicks?.map((val,i)=>{
    //         console.log(months.indexOf(val.month))
    //         mclicks.push(val?.numberofdocuments)
    //     })

    // }

    const [mydata, setmydata] = useState({
        clicks: '',
        conversion: ''
    })


    useEffect(() => {
        callforStat1()
    }, [statsdata])


    const callforStat1 = async () => {

        var mclicks = []
        var mconversion = []
        const mmdta = statsdata && statsdata[0]?.clicks?.map((val, i) => {
            mclicks[months.indexOf(val.month)] = val?.numberofdocuments
        })

        const mmdta2 = statsdata && statsdata[1]?.conversion?.map((val, i) => {
            mconversion[months.indexOf(val.month)] = val?.numberofdocuments
        })


        setmydata({ clicks: mclicks, conversion: mconversion })





    }

    // console.log(statsdata && statsdata[0]?.clicks?.map((val)=>val.numberofdocuments))

    return (
        <div className='bg-white mr-5 m-2 rounded-lg p-2'>

            <p className='m-2'>PERFORMANCE REPORT</p>

            {loading ? (
                <Spinner />
            ) : (



                <div className="rainbow-p-vertical_medium rainbow-m_auto" >
                    <div className="rainbow-align-content_center">

                        <Chart labels={months} type={chartType} style={styles.chart} maintainAspectRatio={false}>

                            <Dataset

                                title="Clicks"
                                values={mydata.clicks}
                                backgroundColor="#01b6f5"
                                borderColor="#01b6f5"
                            />
                            <Dataset
                                title="Conversion"
                                values={mydata.conversion}
                                backgroundColor="#544fc5"
                                borderColor="#544fc5"
                            />





                        </Chart>
                    </div>
                </div>

            )}
        </div>
    )
}

export default Homestats