

import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'

import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { fetchcampaign } from '../../../Redux/Actions/Agency/campaignagencyaction'
import { fetchpricing } from '../../../Redux/Actions/pricingaction'

const Mainpricing = () => {
    const token = localStorage.getItem('token')
const navigate = useNavigate()
    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)
    const [openmodal, setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)

    const [reduloading, setreduloading] = useState(true)
    useEffect(() => {
        callforReducer()
    }, [])

    const callforReducer = async () => {
        setreduloading(true)
        await dispatch(fetchpricing())
        setreduloading(false)
    }

    

    const advertiserdta = useSelector((state) => state.pricingReducer)

    const [myid, setmyid] = useState(null)

    const TABLE_HEAD = ["ID", "Name", "Price", "Days", "isFree","isCustom", "Conver", "Clicks","Offers","PUB","ADV", "Action"];

    const onEditUser = (id) => {
        navigate(`/offerborn/amd/admin/updatepricing/${id}`)
    }

    const onDeleteUser = (id) => {
        
        setmyid(id)
    }

    const handleDeleteAdv = async()=>{
      setreduloading(true)
        await gpeapi.delete(`/deletepricing/${myid}`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res) => {
            if (res.data.success) {
                toast.success('Pricing Deleted Done')
                
                dispatch(fetchpricing())


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setopenpop(false)
        setmyid(null)
        setreduloading(false)
    }

   

    const handleTitleclik = (id)=>{
        // navigate(`/${platformurl}/agency/viewcamp/${id}`)
    }

    // console.log(advertiserdta)


  return (
    <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
    {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



    <ToastContainer />


    

    <Mypopup handleoutopen={()=>setmyid(null)} title={`Are you Sure`} des={`you want to delete id ${myid}?`  } open={openpop} setopenpop={setopenpop} onConfirm={handleDeleteAdv}/>



    <p className='text-2xl font-medium'>MANAGE PRICING</p>

    <div>
        <Mybtn onClick={() => navigate(`/offerborn/amd/admin/addpricing`)} title={'ADD NEW'} padding={'0px 15px'} radius={12} fontsize={14} />
    </div>


    {
        reduloading ? <div className='flex justify-center items-center'>
            <Spinner className="h-10 w-10  text-blue-500/10" />
        </div> : (
            advertiserdta && advertiserdta.length==0?<Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography>:
            <div>
                <CardBody className="overflow-scroll px-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {advertiserdta && advertiserdta?.map(
                                (val, index) => {
                                    const isLast = index === advertiserdta.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={index}>
                                            <td className={classes}>


                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                    {val?.id}
                                                </Typography>

                                            </td>
                                            <td  className={classes}>
                                                <Typography onClick={()=>handleTitleclik(val.id)}  variant="small"  color="blue" className="font-medium cursor-pointer ">
                                                    {val?.name}
                                                </Typography>
                                            </td>
                                            <td  className={classes}>
                                                <Typography onClick={()=>handleTitleclik(val.id)}  variant="small"  color="blue" className="font-medium cursor-pointer ">
                                                    {val?.price}
                                                </Typography>
                                            </td>

                                            <td  className={classes}>
                                                <Typography onClick={()=>handleTitleclik(val.id)}  variant="small"  color="blue" className="font-medium cursor-pointer ">
                                                    {val?.days}
                                                </Typography>
                                            </td>
                                            
                                            <td className={classes}>
                                                <div className="w-max">
                                                    <Chip
                                                        size="sm"
                                                        variant="ghost"
                                                        value={val?.isfree?'TRUE':'FALSE'}
                                                        color={
                                                            val?.isfree  ? "green" :  "red"
                                                        }
                                                    />
                                                </div>
                                            </td>

                                            <td className={classes}>
                                                <div className="w-max">
                                                    <Chip
                                                        size="sm"
                                                        variant="ghost"
                                                        value={val?.iscustom?'TRUE':'FALSE'}
                                                        color={
                                                            val?.iscustom  ? "green" :  "red"
                                                        }
                                                    />
                                                </div>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.conversions}

                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.clicks}

                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.offers}

                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.publishers}

                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.advertisers}

                                                </Typography>
                                            </td>

                                            
                                           
                                           
                                            <td className={classes}>
                                                <Tooltip content="Edit">
                                                    <IconButton onClick={() => onEditUser(val.id)} variant="text" color="blue-gray">
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip content="Delete">
                                                    <IconButton onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                        <TrashIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>

               


              
            </div>
        )
    }






</div>
  )
}

export default Mainpricing