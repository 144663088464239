
import React, { useContext } from 'react'
import { Authcontext } from '../../../Context/Authcontext'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import gpeapi from '../../../apis/gpeapi'
import { toast } from 'react-toastify'
import { Spinner as Spinner1 } from "@material-tailwind/react";

import { DatePicker, Spinner } from 'react-rainbow-components'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,

} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import Mypaginate from '../../../COmponents/Mypaginate'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Myselect from '../../../COmponents/Myselect'
import { COLORS } from '../../../Constants'

const Advrepoagency = () => {

    const token = localStorage.getItem('token')

    const TABLE_HEAD = ["Advertiser", "Clicks", "Conversion", "Conversion Rate","Revenue","Payout","Profit"];


    const { toggle } = useContext(Authcontext)

    const { platformurl } = useParams()

    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(10)
    const [pdfdownloading, setpdfdownloading] = useState(false)
    const [range, setrange] = useState()

    const [mdate, setmdate] = useState({
        start: 0,
        end: 0
    })

    useEffect(() => {
        callforAPI()
      }, [limit])

    useEffect(() => {
        callforDatecalendar()
    }, [])

    const callforDatecalendar = () => {

        const now = new Date();

        const mrange = [new Date(now.getFullYear(), now.getMonth(), 1), new Date()]

        setrange(mrange)

        var date1 = new Date(now.getFullYear(), now.getMonth(), 1)
        var date2 = new Date()
        var date3 = new Date()

        var Difference_In_Time1 = date2.getTime() - date1.getTime();
        var Difference_In_Time2 = date2.getTime() - date3.getTime();
        var Difference_In_Days = Math.abs(Difference_In_Time1 / (1000 * 3600 * 24)).toFixed();
        var Difference_In_Days2 = Math.abs(Difference_In_Time2 / (1000 * 3600 * 24)).toFixed();

        setmdate({ start: Difference_In_Days - 1, end: Difference_In_Days2 })

    }

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/agencyreports?type=advertiser&limit=${limit}&start=${mdate.start}&end=${mdate.end}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const handleTitleclik = () => {

    }

    const handleChnageSelect = async (e) => {
        setlimit(e)
    }
    const handlepaginate = async (number) => {
        // console.log(number)
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/agencyreports?type=advertiser&limit=${limit}&page=${number - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }

    const onNextClick = async () => {
        setloading(true)



        const res = await gpeapi.get(`/${platformurl}/agencyreports?type=advertiser&limit=${limit}&page=${statsdata?.page + 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })

        setloading(false)
    }

    const onPrevClick = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/agencyreports?type=advertiser&limit=${limit}&page=${statsdata?.page - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            console.log('api', ress.data)
            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }

    const handlechangedate = async (e) => {
        var date1 = new Date(e[0])
        var date2 = new Date()
        var date3 = new Date(e[1])

        var Difference_In_Time1 = date2.getTime() - date1.getTime();
        var Difference_In_Time2 = date2.getTime() - date3.getTime();
        var Difference_In_Days = Math.abs(Difference_In_Time1 / (1000 * 3600 * 24)).toFixed();
        var Difference_In_Days2 = Math.abs(Difference_In_Time2 / (1000 * 3600 * 24)).toFixed();
        await setmdate({ start: Difference_In_Days - 1, end: Difference_In_Days2 })


    }

    const htmltoCsv = (html, filename) => {

        var data = []
    
        var rows = document.querySelectorAll('table tr')
    
        for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th')
    
          for (var j = 0; j < cols.length; j++) {
            row.push(cols[j].innerText)
          }
    
          data.push(row.join(','))
        }
    
        downloadcsvfile(data.join('\n'), filename)
      }
    
      const downloadcsvfile = (csv, filename) => {
        var csv_file, download_link;
        csv_file = new Blob([csv], { type: "text/csv" });
        download_link = document.createElement('a');
        download_link.download = filename;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = 'none';
        document.body.appendChild(download_link)
        download_link.click();
      }
    
      const downloadtabletocsv = async () => {
       
        var html = document.querySelector('table').outerHTML;
        await htmltoCsv(html, `Advertiserreport-agency-${new Date()}.csv`)
        // toast.success('Conversion Report Downloaded ')
        
      }

    const handlepdfdownload = ()=>{
        const pdf = new jsPDF()
       autoTable(pdf,{html:'#table'})
        pdf.save(`Advertiserreport-agency-${new Date()}.pdf`)
        
    }

    const handleprintrepo = ()=>{
        var tab = document.getElementById('table')
        var win = window.open('','','height=700,width=700')
        win.document.write(tab.outerHTML)
        win.document.close()
        win.print()
    }


    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>

            {
                loading ? <Spinner /> : (

                    <div>

                        <div className='flex justify-between items-center m-2'>

                            <p>.</p>

                            <div className='w-4/12 flex '>

                                <DatePicker
                                    id="datePicker-15"
                                    // label="DatePicker Label"
                                    placeholder="Select range of dates"
                                    selectionType="range"
                                    formatStyle="small"
                                    variant="double"
                                    borderRadius='semi-rounded'
                                    // onClick={handlechangedate}
                                    value={range}
                                    maxDate={new Date()}

                                    onChange={(e) => {
                                        setrange(e)
                                        handlechangedate(e)
                                    }}

                                />

                                <Button className='ml-1 mr-1' onClick={() => callforAPI()} size="sm">Submit</Button>

                            </div>



                        </div>

                        <div className='flex justify-between items-center m-2'>



                            <div className='flex items-center'>

                                <Button className='ml-1 mr-1 w-2/4 h-max' onClick={handlepdfdownload} style={{ backgroundColor: COLORS.primary, boxShadow: 'none' }}  size="sm">PDF</Button>

                                <Button className='ml-1 mr-1 w-2/4 h-max' onClick={downloadtabletocsv} style={{ backgroundColor: COLORS.primary, boxShadow: 'none' }}  size="sm">CSV</Button>

                                <Button className='ml-1 mr-1 w-2/4 h-max' onClick={handleprintrepo} style={{ backgroundColor: COLORS.primary, boxShadow: 'none' }}  size="sm">PRINT</Button>


                            </div>

                            <div>
                            <Myselect classname={'ml-1 mr-1 w-2/4 h-max'} placeholder={'Per Page'} value={limit} data={[{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }, { label: 50, value: 50 }, { label: 100, value: 100 }]} width={'100%'} onChange={(e) => handleChnageSelect(e, 'advertiserid')} />
                            </div>





                           






                        </div>


                        <CardBody className="overflow-scroll px-0">
                            <table id='table' className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!statsdata?.docs?.length ? (
                                        <div className='flex justify-center'>
                                            No Data Found
                                        </div>
                                    ) : (
                                        <>

                                            {statsdata?.docs?.map(
                                                (val, index) => {
                                                    const isLast = index === statsdata?.docs?.length - 1;
                                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={index}>

                                                            <td className={classes}>
                                                                <Typography onClick={() => handleTitleclik(val.camp_id)} variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                    {val?.campaign}
                                                                </Typography>
                                                            </td>


                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">
                                                                    {val?.clicks}

                                                                </Typography>
                                                            </td>
                                                            
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {val?.conversion}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {val?.conversionrate}%

                                                                </Typography>
                                                            </td>


                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    ₹{val?.revenue}

                                                                </Typography>
                                                            </td>

                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    ₹{val?.payout}

                                                                </Typography>
                                                            </td>

                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    ₹{val?.profit}

                                                                </Typography>
                                                            </td>
                                                            {/* <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                                    {val?.extendsconversion}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                                    {val?.pendingconversion}

                                                                </Typography>
                                                            </td> */}
                                                            {/* <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                                    ₹{val?.pendingpayout}

                                                                </Typography>
                                                            </td> */}

                                                        </tr>
                                                    );
                                                },
                                            )}

                                            <tr >

                                                <td className='p-4'>
                                                    <Typography variant="small" color="black" className="font-semibold uppercase">
                                                        {statsdata?.mtotal?.campaign}
                                                    </Typography>
                                                </td>


                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">
                                                        {statsdata?.mtotal?.clicks}

                                                    </Typography>
                                                </td>
                                                
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {statsdata?.mtotal?.conversion}

                                                    </Typography>
                                                </td>
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {statsdata?.mtotal?.conversionrate}%

                                                    </Typography>
                                                </td>
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        ₹{statsdata?.mtotal?.revenue}

                                                    </Typography>
                                                </td>
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        ₹{statsdata?.mtotal?.payout}

                                                    </Typography>
                                                </td>
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        ₹{statsdata?.mtotal?.profit}

                                                    </Typography>
                                                </td>
                                                {/* <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        {statsdata?.mtotal?.extendsconversion}

                                                    </Typography>
                                                </td>
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        {statsdata?.mtotal?.pendingconversion}

                                                    </Typography>
                                                </td>
                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        ₹{statsdata?.mtotal?.pendingpayout}

                                                    </Typography>
                                                </td> */}

                                            </tr>
                                        </>
                                    )

                                    }
                                </tbody>
                            </table>
                        </CardBody>

                        <Mypaginate
                            onNextClick={onNextClick}
                            onPrevClick={onPrevClick}
                            paginate={handlepaginate}
                            currentPage={statsdata?.page}
                            totalData={statsdata?.total}
                            perPage={statsdata?.limit}
                            Totalpage={statsdata?.pages}

                        />



                    </div>
                )
            }


        </div>
    )
}

export default Advrepoagency