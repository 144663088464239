import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const Tandc = () => {

    
    const basicData = useSelector((state) => state.basicReducer)[0]
    
    document.title = `Terms & Conditions - OfferBorn`
    useEffect(()=>{
      window.scrollTo(0,0)
  },[])

  return (
    <section style={{marginTop:50}} class="section contact" id="contact" aria-label="contact">
    <div class="container">

      <h2 class="h3 section-title font-bold">Terms & Conditions</h2>

      <p class="section-text">
      
      </p>


      <h1 >Terms & Conditions for OfferBorn</h1>

      <p>
        {basicData?.tandc}
      </p>

 </div>
      </section>
  )
}

export default Tandc