import React from 'react'
import { useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useState } from 'react'

import { useEffect } from 'react'


import { Empty, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { PencilIcon, TrashIcon, ArrowDownCircleIcon } from "@heroicons/react/24/solid";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import easyinvoice from 'easyinvoice'
import Mypaginate from '../../../../../../../../COmponents/Mypaginate'
import gpeapi from '../../../../../../../../apis/gpeapi'
import { Authcontext } from '../../../../../../../../Context/Authcontext'
import { useParams } from 'react-router-dom'

const MainInvoicepub = () => {

    const token = localStorage.getItem('token')
    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const {platformurl,pubid} = useParams()

    // useEffect(() => {
    //     setplatformname(getSubdomain(window.location.host))
    // }, [])
    const { toggle } = useContext(Authcontext)
    const [openmodal, setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)

    const [reduloading, setreduloading] = useState(true)

    const [myid, setmyid] = useState(null)

    const TABLE_HEAD = ["ID", "INV-ID", "Title", "Status", "Total","Paymentid","Comment","Paid Date", "Action"];

    const getdatetimevia = (date) => {
        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var second = mydate.getSeconds();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        second = second < 10 ? '0' + second : second;

        var strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;

        return `${todate}-${month + 1}-${year} at ${strTime}`
    }

    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(10)
    const [search, setsearch] = useState('')





    useEffect(() => {
        platformurl && callforAPI()
    }, [limit, platformurl])

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/${pubid}/getcampinvoicepubadmin?limit=${limit}&search=${search}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const handlepaginate = async (number) => {
        // console.log(number)
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/${pubid}/getcampinvoicepubadmin?limit=${limit}&search=${search}&page=${number - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            // console.log('api', ress.data)
            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }

    const onNextClick = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/${pubid}/getcampinvoicepubadmin?limit=${limit}&search=${search}&page=${statsdata.page + 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })

        setloading(false)
    }

    const handleChnageSelect = async (e) => {
        setlimit(e)
    }

    const onPrevClick = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/${pubid}/getcampinvoicepubadmin?limit=${limit}&search=${search}&page=${statsdata.page - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }


    const [downloading, setdownloading] = useState({
        id: '',
        loading: false
    })


    const onDownloadInvoice = async (val) => {

        // console.log(val)

        setdownloading({
            id: val.id,
            loading: true
        })

        const callanydate1 = (days, date) => {
            var mysevendays = new Date(date);
            mysevendays.setDate(mysevendays.getDate() + days);
            return mysevendays.toISOString().split('T')[0]
        }

        // console.log(val)

        var strmm = `${val?.pubdata?.company} ${val?.invoiceid} ${new Date()}`


        var invoicenamemm = strmm?.replace(/\s/g, "_")

        var data = {
            // Customize enables you to provide your own templates
            // Please review the documentation for instructions and examples
            "customize": {
                //  "template": fs.readFileSync('template.html', 'base64') // Must be base64 encoded html 
            },
            "images": {
                // The logo on top of your invoice
                // "logo": val?.agencydata?.img ? gpeapi.getUri()+val?.agencydata?.img:'' ,
                // The invoice background
                "background": val?.ispaid?'https://res.cloudinary.com/dcpn2tcwm/image/upload/v1703734593/New_Project_zvfxzh.png': ""
            },
            // Your own data
            "sender": {
                "company": val?.pubdata?.company,
                "address": val?.pubdata?.address,
                "zip": val?.pubdata?.pincode,
                "city": val?.pubdata?.city,
                "country": val?.pubdata?.country,
                "custom1": `Name: ${val.pubdata?.name}`,
                "custom2": `Email: ${val.pubdata?.email}`,
                "custom3": `GST: ${val?.pubdata?.taxid}`,
                // "custom3": "custom value 3"
            },
            // Your recipient
            "client": {
                "company": val.agencydata.company,
                "address": val.agencydata?.address,
                "zip": val.agencydata?.pincode,
                "city": val.agencydata?.address,
                "country": val.agencydata?.country,
                "custom1": `Email: ${val.agencydata?.email}`,
                "custom2": `GST: ${val.agencydata?.gst}`,
                // "custom3": `Service: SaaS (Software as a Service)`
            },


            "information": {
                // Invoice number
                "number": val?.invoiceid,
                // Invoice data
                "date": callanydate1(0, val?.createdAt),
                // Invoice due date
                "due-date": callanydate1(15, val?.createdAt),
                // "isPaid":val?.ispaid?'YES':'NO'
            },
            // The products you would like to see on your invoice
            // Total values are being calculated automatically
            "products":
                val?.invoicedata?.map((mval,i)=>{
                    return (
                        {
                            "quantity": mval?.conversion,
                            "description": `${mval.campaign}${mval?.event ?` (${mval.event})`:''}`,
                            "tax-rate": val?.addgst?18:0,
                            "price": mval?.payout
                        }
                    )
                }),
                // [
                //     {
                //         "quantity": 1,
                //         "description": 'jh',
                //         "tax-rate": 18,
                //         "price": 8
                //     }
                // ],

            // The message you would like to display on the bottom of your invoice
            "bottom-notice": `
            <b>Bank Details:-</b>
            <p>Bank Name: ${val?.pubdata?.payment_method?.bankname}</p>
            <p>Holder Name: ${val?.pubdata?.payment_method?.bankholdername}</p>
            <p>Account Number: ${val?.pubdata?.payment_method?.banknumber}</p>
            <p>IFSC Code: ${val?.pubdata?.payment_method?.bankifsc}</p>
            <p>Branch Name: ${val?.pubdata?.payment_method?.bankbranch}</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            Kindly pay your invoice within 15 days.`,
            // Settings to customize your invoice
            "settings": {
                "currency": "INR", // See documentation 'Locales and Currency' for more info. Leave empty for no currency.
                // "locale": "nl-NL", // Defaults to en-US, used for number formatting (See documentation 'Locales and Currency')        
                "margin-top": 25, // Defaults to '25'
                // "margin-right": 25, // Defaults to '25'
                // "margin-left": 25, // Defaults to '25'
                // "margin-bottom": 25, // Defaults to '25'
                // "format": "A4", // Defaults to A4, options: A3, A4, A5, Legal, Letter, Tabloid
                // "height": "1000px", // allowed units: mm, cm, in, px
                // "width": "500px", // allowed units: mm, cm, in, px
                // "orientation": "landscape", // portrait or landscape, defaults to portrait
            },
            // Translate your invoice to your preferred language
            "translate": {
                // "invoice": "FACTUUR",  // Default to 'INVOICE'
                "number": "Invoice No.", // Defaults to 'Number'
                "date": "Invoice Date", // Default to 'Date'
                // "due-date": "Verloopdatum", // Defaults to 'Due Date'
                // "subtotal": "Subtotaal", // Defaults to 'Subtotal'
                "products": "Campaigns", // Defaults to 'Products'
                // "quantity": "Aantal", // Default to 'Quantity'
                // "price": "Prijs", // Defaults to 'Price'
                // "product-total": "Totaal", // Defaults to 'Total'
                // "total": "Totaal", // Defaults to 'Total'
                "vat": "GST" // Defaults to 'vat'
            },
        };


        const invocedta = await easyinvoice.createInvoice(data, function (result) {
            easyinvoice.download(`${invoicenamemm}.pdf`, result.pdf)
        });

        setdownloading({
            id: val.id,
            loading: false
        })
    }






    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>

            <ToastContainer />

            <p className='text-2xl font-medium '>MANAGE INVOICES</p>




            <Spin spinning={loading}>

                <div>
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    statsdata && statsdata?.docs?.length == 0 ? (
                                        <td colSpan={TABLE_HEAD.length}>

                                            <div className='flex justify-center text-center m-2'>
                                                <Empty />
                                            </div>
                                        </td>
                                    ) :

                                        statsdata && statsdata?.docs?.map(
                                            (val, index) => {
                                                const isLast = index === statsdata?.docs?.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                return (
                                                    <tr key={index}>
                                                        <td className={classes}>


                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                {val?.id}
                                                            </Typography>

                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.invoiceid}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.invoicetitle}

                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <div className="w-max">
                                                                <Chip
                                                                    size="sm"
                                                                    variant="ghost"
                                                                    value={val?.status}
                                                                    color={
                                                                        val?.status === "paid" ? "green" : val?.status === "validated" ? "blue" : val?.status === "raised" ? 'amber' : "red"
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                {val?.total} {val.currency}

                                                            </Typography>
                                                        </td>


                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.paymentid}

                                                            </Typography>
                                                        </td>

                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {val?.comment}

                                                            </Typography>
                                                        </td>

                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                { val?.paiddate?getdatetimevia(val?.paiddate):''}

                                                            </Typography>
                                                        </td>


                                                        <td className={classes}>
                                                            <Tooltip content="Download Invoice">

                                                                <IconButton onClick={() => onDownloadInvoice(val)} variant="text" color="blue-gray">

                                                                    <Spin spinning={downloading.id == val.id && downloading.loading} indicator={<LoadingOutlined style={{ fontSize: 20 }} />} >
                                                                        <ArrowDownCircleIcon className="h-6 w-6" />
                                                                    </Spin>

                                                                </IconButton>

                                                            </Tooltip>

                                                            {/* <Tooltip content="Delete">
                                                                    <IconButton onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                                        <TrashIcon className="h-4 w-4" />
                                                                    </IconButton>
                                                                </Tooltip> */}
                                                        </td>
                                                    </tr>
                                                );
                                            },
                                        )}
                            </tbody>
                        </table>
                    </CardBody>

                    <Mypaginate
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        paginate={handlepaginate}
                        currentPage={statsdata?.page}
                        totalData={statsdata?.total}
                        perPage={statsdata?.limit}
                    />
                </div>


            </Spin>










        </div>
    )
}

export default MainInvoicepub