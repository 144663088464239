


import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import Mysidebar from '../../COmponents/Mysidebar';

import { MdOutlineDashboard ,MdCampaign, MdPayment} from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { FaFileInvoiceDollar, FaHandsHelping } from "react-icons/fa";

import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoAnalyticsSharp } from "react-icons/io5";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";
import { TbFriends,TbCreditCard} from "react-icons/tb";

import Header from '../../COmponents/Header';



import Mainloginadmin from '../Pages/Login/Mainloginadmin';
import Maindashboard from '../Pages/Dashboard/Maindashboard';
import gpeapi from '../../apis/gpeapi';
import Mainbasicdet from '../Pages/BasicDet/Mainbasicdet';
import Addbasicdet from '../Pages/BasicDet/Addbasicdet';
import Mainpricing from '../Pages/Pricing/Mainpricing';
import Addpricing from '../Pages/Pricing/Addpricing';
import Mainagencyadm from '../Pages/Agencys/Mainagencyadm';
import Addagencyadm from '../Pages/Agencys/Addagencyadm';
import { BiSolidUserCheck } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import Mainhomeagency from '../Pages/Agencys/Login/Pages/Home/Mainhomeagency';
import CreateCamp from '../Pages/Agencys/Login/Pages/Campaigns/CreateCamp';
import ViewCamp from '../Pages/Agencys/Login/Pages/Campaigns/ViewCamp';
import Maincampaign from '../Pages/Agencys/Login/Pages/Campaigns/Maincampaign';
import Mainadvertiseragency from '../Pages/Agencys/Login/Pages/Advertisers/Mainadvertiseragency';
import Mainpublishersagency from '../Pages/Agencys/Login/Pages/Publishers/Mainpublishersagency';
import Mainemployeeagency from '../Pages/Agencys/Login/Pages/Employee/Mainemployeeagency';
import Mainaccountagency from '../Pages/Agencys/Login/Pages/Account/Mainaccountagency';
import Billinginfo from '../Pages/Agencys/Login/Pages/Finance/Billinginfo';
import Performancerepoagency from '../Pages/Agencys/Login/Pages/Reports/Performancerepoagency';
import Dailyrepoagency from '../Pages/Agencys/Login/Pages/Reports/Dailyrepoagency';
import Pubrepoagency from '../Pages/Agencys/Login/Pages/Reports/Pubrepoagency';
import Advrepoagency from '../Pages/Agencys/Login/Pages/Reports/Advrepoagency';
import Conversionrepoagency from '../Pages/Agencys/Login/Pages/Reports/Conversionrepoagency';
import Maininvoiceagency from '../Pages/Agencys/Login/Pages/Invoices/Maininvoiceagency';
import Viewcampinvoiceagency from '../Pages/Agencys/Login/Pages/Invoices/Viewcampinvoiceagency';



const Mainrouteloginagencyadm = () => {
  const token = localStorage.getItem('token')
  const {platformurl} = useParams()

  const [isvalid,setisvalid] = useState(false)
  const [loading,setloading] = useState(false)

  useEffect(()=>{
    token && checkvalidtoken()
  },[])


  const checkvalidtoken = ()=>{
    gpeapi.get('/checktokenadm',{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>{
    if(res.data.success){
     
    }else{
      localStorage.clear()
      window.location.reload('')
    }
  })
  // console.log('runnn')
  }

  

  const menus = [
    { name: "dashboard", link: `/offerborn/amd/admin/agencylogin/${platformurl}/dashboard`, icon: MdOutlineDashboard },
    {
      name: "campaigns", link: "/", icon: MdCampaign, submenu: [
        { name: "all campaigns", link: `/offerborn/amd/admin/agencylogin/${platformurl}/campaigns` },
        { name: "create campaign", link: `/offerborn/amd/admin/agencylogin/${platformurl}/createcamp` },
        
      ]
    },
    {
      name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
        { name: "campaigns report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/performancerepo` },
        { name: "daily report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/dailyreport` },
        { name: "publishers report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/publisherreport` },
        { name: "advertisers report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/advertiserreport` },
        { name: "conversion report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/conversionreport` },
        // { name: "clicks report", link: "/" },
        // { name: "sub-id reports", link: "/" },
      ]
    },
    { name: "publishers", link: "/", icon: TbFriends,submenu:[
      { name: "all publishers", link: `/offerborn/amd/admin/agencylogin/${platformurl}/publishers` },
      

    ] },
    { name: "advertisers", link: "/", icon: FaHandsHelping,submenu:[
      { name: "all advertisers", link: `/offerborn/amd/admin/agencylogin/${platformurl}/advertisers` },
      

    ]  },

    { name: "employees", link: "/", icon: BiSolidUserCheck,submenu:[
      { name: "all employees", link: `/offerborn/amd/admin/agencylogin/${platformurl}/employees` },
      

    ]  },

    { name: "publishers invoice", link: "/", icon: FaFileInvoiceDollar , submenu: [
      { name: "manage invoices", link:  `/offerborn/amd/admin/agencylogin/${platformurl}/manageinvoice`},
      // { name: "create invoice", link:  `/agency/createinvoice` },
      
  ]},



    { name: "account", link: `/offerborn/amd/admin/agencylogin/${platformurl}/account`, icon: CgProfile 
  },

  { name: "billing", link: `/offerborn/amd/admin/agencylogin/${platformurl}/billing`, icon: MdPayment 
  },
    // { name: "Cart", link: "/", icon: FiShoppingCart },
    // { name: "Billing", link: "/", icon: TbCreditCard },
    // { name: "Setting", link: "/", icon: RiSettings4Line },
  ];


  return (

    
    <>

    

  

      {token && <>
        <Mysidebar menus={menus} />
        <div style={{marginTop:60}}>
        <Header/>
        </div>
      </>}

      <Routes>

        {
          token ? (
            <>


<Route path='/*' element={<Mainhomeagency />} />
              <Route path='/dashboard' element={<Mainhomeagency />} />
              <Route path='/campaigns' element={<Maincampaign />} />

              <Route path='/createcamp' element={<CreateCamp />} />
              <Route path='/updatecamp/:id' element={<CreateCamp />} />
              <Route path='/viewcamp/:id' element={<ViewCamp />} />

              <Route path='/advertisers' element={<Mainadvertiseragency />} />
              <Route path='/publishers' element={<Mainpublishersagency />} />
              <Route path='/employees' element={<Mainemployeeagency />} />
              <Route path='/account' element={<Mainaccountagency />} />
              <Route path='/billing' element={<Billinginfo />} />

              <Route path='/performancerepo' element={<Performancerepoagency />} />
              <Route path='/dailyreport' element={<Dailyrepoagency />} />
              <Route path='/conversionreport' element={<Conversionrepoagency />} />
              <Route path='/publisherreport' element={<Pubrepoagency />} />
              <Route path='/advertiserreport' element={<Advrepoagency />} />


               {/* invoice  */}

               <Route path='/manageinvoice' element={<Maininvoiceagency />} />
              <Route path='/viewcampinvoice/:id' element={<Viewcampinvoiceagency />} />



              {/* <Route path='/*' element={<Maindashboard />} />
              <Route path='/dashboard' element={<Maindashboard />} /> */}
              {/* <Route path='/setting' element={<Mainbasicdet />} />
              <Route path='/createdetails' element={<Addbasicdet />} />
              <Route path='/updatedetails/:id' element={<Addbasicdet />} />
              <Route path='/pricing' element={<Mainpricing />} />
              <Route path='/addpricing' element={<Addpricing />} />
              <Route path='/updatepricing/:id' element={<Addpricing />} />


              <Route path='/agency' element={<Mainagencyadm />} />
              <Route path='/addagency' element={<Addagencyadm />} />
              <Route path='/updateagency/:id' element={<Addagencyadm />} /> */}
             
            </>

          ) : (
            <>
              <Route path='/*' element={<Mainloginadmin />} />
              <Route path='/login' element={<Mainloginadmin />} />
            </>
          )
        }

        {/* <Route path='/home' element={<Mainhome/>}/> */}

        {/* <Route path='/register' element={<Mainregister/>}/> */}


      </Routes>
      

    </>
  







  )
}

export default Mainrouteloginagencyadm