



import React, { useContext } from 'react'
import { Authcontext } from '../../../Context/Authcontext'
// import { Button } from 'react-rainbow-components';
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import gpeapi from '../../../apis/gpeapi'
import { ToastContainer, toast } from 'react-toastify'
import { Spinner } from 'react-rainbow-components'
import { Spinner as Spinner1 } from "@material-tailwind/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,

} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import Mypaginate from '../../../COmponents/Mypaginate'
import Mybtn from '../../../COmponents/Mybtn'
import Mydrwaer from '../../../COmponents/Mydrwaer'
import Filtercompo from './Filtercompo'
import { DatePicker } from 'react-rainbow-components';
import Myselect from '../../../COmponents/Myselect'
import Myinput from '../../../COmponents/Myinput'
import { COLORS } from '../../../Constants'

const Conversionrepoadv = () => {

    const token = localStorage.getItem('token')



    const [TABLE_HEAD, setTABLE_HEAD] = useState(["Conversion ID", "Campaign", "Conversion IP", "SUB1", "SUB2", "SUB3", "SUB4", "Created"])

    //   const MTABLE_HEAD = ["Conversion ID", "Click ID", "Conversion IP", "P1", "Campaign", "Created", "Status"];


    const { toggle } = useContext(Authcontext)
const navigate = useNavigate()
    const { platformurl } = useParams()

    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(10)
    const [range, setrange] = useState()

    const [localsearch, setlocalsearch] = useState('')

    const [mdate, setmdate] = useState({
        start: 0,
        end: 0
    })

    //   const [filterdta, setfilterdta] = useState({
    //     clickid: '',
    //     conversionid: '',
    //     sub1: false,
    //     sub2: false,
    //     sub3: false,
    //     sub4: false,


    //   })


    const [downlloading, setdownloading] = useState(false)

    //   const [opendrwaer, setopendrwaer] = useState(false)







    //   const handlesavefilter = async () => {

    //     setloading(true)
    //     const keyss = Object.keys(filterdta).filter(k => filterdta[k] === true)
    //     // console.log(keyss.sort())
    //     keyss.sort()?.map((val, i) => {
    //       const item = TABLE_HEAD
    //       !TABLE_HEAD.includes(val) && item.splice(4, 0, val)
    //       !TABLE_HEAD.includes(val) && setTABLE_HEAD(item)
    //     })

    //     const res = await gpeapi.get(`/${platformurl}/advreports?type=conversion&limit=${limit}&${filterdta.clickid !== '' && `userid=${filterdta.clickid}`}&${filterdta.conversionid !== '' && `search=${filterdta.conversionid}`}`, {
    //       headers: {
    //         "Authorization": `Beaer ${token}`
    //       }
    //     }).then((ress) => {


    //       setstatdata(ress?.data?.TotalUsers)
    //     }).catch((e) => {
    //       toast.error(e?.response?.data?.msg)

    //     })


    //     setloading(false)


    //   }
    //   // console.log(TABLE_HEAD)

    useEffect(() => {
        callforAPI()
    }, [limit])

    useEffect(() => {

        callforDatecalendar()
        // callforTest()
    }, [])






    const callforDatecalendar = () => {

        const now = new Date();

        const mrange = [new Date(now.getFullYear(), now.getMonth(), 1), new Date()]

        setrange(mrange)

        var date1 = new Date(now.getFullYear(), now.getMonth(), 1)
        var date2 = new Date()
        var date3 = new Date()

        var Difference_In_Time1 = date2.getTime() - date1.getTime();
        var Difference_In_Time2 = date2.getTime() - date3.getTime();
        var Difference_In_Days = Math.abs(Difference_In_Time1 / (1000 * 3600 * 24)).toFixed();
        var Difference_In_Days2 = Math.abs(Difference_In_Time2 / (1000 * 3600 * 24)).toFixed();

        setmdate({ start: Difference_In_Days - 1, end: Difference_In_Days2 })

    }


    const callforAPI = async () => {
        setloading(true)
        setlocalsearch('')
        const res = await gpeapi.get(`/${platformurl}/advreports?type=conversion&limit=${limit}&start=${mdate.start}&end=${mdate.end}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const handleTitleclik = () => {

    }



    const handlepaginate = async (number) => {
        // console.log(number)
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/advreports?type=conversion&limit=${limit}&page=${number - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            console.log('api', ress.data)
            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }

    const onNextClick = async () => {
        setloading(true)



        const res = await gpeapi.get(`/${platformurl}/advreports?type=conversion&limit=${limit}&page=${statsdata.page + 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })

        setloading(false)
    }

    const onPrevClick = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/advreports?type=conversion&limit=${limit}&page=${statsdata.page - 1}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {


            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }

    const getdatetimevia = (date) => {
        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var second = mydate.getSeconds();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        second = second < 10 ? '0' + second : second;

        var strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;

        return `${todate}-${month + 1}-${year} at ${strTime}`
    }



    const handlechangedate = async (e) => {
        var date1 = new Date(e[0])
        var date2 = new Date()
        var date3 = new Date(e[1])

        var Difference_In_Time1 = date2.getTime() - date1.getTime();
        var Difference_In_Time2 = date2.getTime() - date3.getTime();
        var Difference_In_Days = Math.abs(Difference_In_Time1 / (1000 * 3600 * 24)).toFixed();
        var Difference_In_Days2 = Math.abs(Difference_In_Time2 / (1000 * 3600 * 24)).toFixed();
        await setmdate({ start: Difference_In_Days - 1, end: Difference_In_Days2 })


    }

    const handleChnageSelect = async (e) => {
        setlimit(e)
    }


    const filldata = statsdata?.docs?.filter((x) => {
        return localsearch !== "" ? x?.clickid?.includes(localsearch) || x?.sub1?.includes(localsearch) || x?.sub2?.includes(localsearch) || x?._id?.includes(localsearch) : x
    })


    const htmltoCsv = (html, filename) => {

        var data = []

        var rows = document.querySelectorAll('table tr')

        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll('td, th')

            for (var j = 0; j < cols.length; j++) {
                row.push(cols[j].innerText)
            }

            data.push(row.join(','))
        }

        downloadcsvfile(data.join('\n'), filename)
    }

    const downloadcsvfile = (csv, filename) => {
        var csv_file, download_link;
        csv_file = new Blob([csv], { type: "text/csv" });
        download_link = document.createElement('a');
        download_link.download = filename;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = 'none';
        document.body.appendChild(download_link)
        download_link.click();
    }

    const downloadtabletocsv = async () => {
        setdownloading(true)
        var html = document.querySelector('table').outerHTML;
        await htmltoCsv(html, `ConversionReport-adv-${new Date()}.csv`)
        // toast.success('Conversion Report Downloaded ')
        setdownloading(false)
    }


    const handlegotocamp = (id)=>{
        navigate(`/p/${platformurl}/advertiser/viewcamp/${id}`)
    }





    return (

        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>

            <ToastContainer />

            {/* <Mydrwaer handlesave={handlesavefilter} open={opendrwaer} header={'Report Filter'} setopenmodal={setopendrwaer} Components={<Filtercompo handlereste={handleresetfm} sdata={filterdta} setmdata={setfilterdta} />} /> */}

            {
                loading ? <Spinner /> : (

                    <div>



                        <div className='flex justify-between items-center m-2'>

                            {/* <Mybtn title={'Filter'} onClick={() => setopendrwaer(true)} /> */}
                            <p>.</p>

                            <div className='w-4/12 flex '>

                                <DatePicker
                                    id="datePicker-15"
                                    // label="DatePicker Label"
                                    placeholder="Select range of dates"
                                    selectionType="range"
                                    formatStyle="small"
                                    variant="double"
                                    borderRadius='semi-rounded'
                                    // onClick={handlechangedate}
                                    value={range}
                                    maxDate={new Date()}

                                    onChange={(e) => {
                                        setrange(e)
                                        handlechangedate(e)
                                    }}

                                />

                                <Button className='ml-1 mr-1' onClick={() => callforAPI()} size="sm">Submit</Button>

                            </div>



                        </div>

                        <div className='flex justify-between items-center m-2'>

                            <Myinput width={'30%'} placeholder={'Local Search'} value={localsearch} onChange={(e) => setlocalsearch(e.target.value)} />

                            <div className='w-max flex items-center'>

                                <Button className='ml-1 mr-1 w-2/4 h-max' onClick={downloadtabletocsv} style={{ backgroundColor: COLORS.primary, boxShadow: 'none' }} disabled={downlloading} size="sm"> {downlloading ? <Spinner1 /> : 'Export CSV'} </Button>


                                <Myselect classname={'ml-1 mr-1 w-2/4 h-max'} placeholder={'Per Page'} value={limit} data={[{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }, { label: 50, value: 50 }, { label: 100, value: 100 }]} width={'100%'} onChange={(e) => handleChnageSelect(e, 'advertiserid')} />


                            </div>



                        </div>



                        <CardBody className="overflow-scroll px-0">
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70 uppercase"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {!filldata?.length ? (
                                        <div className='flex justify-center'>
                                            No Data Found
                                        </div>
                                    ) : filldata?.map(
                                        (val, index) => {
                                            const isLast = index === statsdata?.docs?.length - 1;
                                            const classes = isLast ? "p-4 w-2" : "p-4 w-2  border-b border-blue-gray-50";

                                            return (
                                                <tr key={index}>

                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal  ">
                                                            {val?._id}
                                                        </Typography>
                                                    </td>


                                                    {/* <td className={classes}>
                            <Typography variant="small" color="blue" className="font-normal text-xs">
                              {val?.clickid}

                            </Typography>
                          </td> */}

                                                    <td className={classes}>
                                                        <Typography onClick={()=>handlegotocamp(val?.camp_id)} variant="small" color="blue" className="cursor-pointer font-medium">
                                                            (ID: {val.camp_id}) {val?.camp_name}


                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.ip}

                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.sub1}

                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.sub2}

                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.sub3}

                                                        </Typography>
                                                    </td>

                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {val?.sub4}

                                                        </Typography>
                                                    </td>









                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {getdatetimevia(val?.createdAt)}

                                                        </Typography>
                                                    </td>




                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </CardBody>

                        <Mypaginate
                            onNextClick={onNextClick}
                            onPrevClick={onPrevClick}
                            paginate={handlepaginate}
                            currentPage={statsdata?.page}
                            totalData={statsdata?.total}
                            perPage={statsdata?.limit}
                            Totalpage={statsdata?.pages}
                        />



                    </div>
                )
            }


        </div>
    )
}

export default Conversionrepoadv