
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import Mysidebar from '../../COmponents/Mysidebar';
import Mainloginagency from '../Pages/Login/Mainloginagency';
import { MdOutlineDashboard ,MdCampaign, MdPayment} from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import { BiSolidUserCheck } from "react-icons/bi";

import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoAnalyticsSharp } from "react-icons/io5";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";
import { TbFriends,TbCreditCard} from "react-icons/tb";
import Mainhomeagency from '../Pages/Home/Mainhomeagency';
import Header from '../../COmponents/Header';
import gpeapi from '../../apis/gpeapi';
import Page404Found from '../../COmponents/Page404Found';
import { Spinner } from '@material-tailwind/react';
import Maindashboard from '../Pages/DashBoard/Maindashboard';
import CreateCamp from '../Pages/Campaigns/CreateCamp';
import Mainadvertiseragency from '../Pages/Advertisers/Mainadvertiseragency';
import Mainpublishersagency from '../Pages/Publishers/Mainpublishersagency';
import Maincampaign from '../Pages/Campaigns/Maincampaign';
import ViewCamp from '../Pages/Campaigns/ViewCamp';
import Forgotpassword from '../Pages/Login/Forgotpassword';
import Performancerepoagency from '../Pages/Reports/Performancerepoagency';
import Dailyrepoagency from '../Pages/Reports/Dailyrepoagency';
import Conversionrepoagency from '../Pages/Reports/Conversionrepoagency';
import Pubrepoagency from '../Pages/Reports/Pubrepoagency';
import Advrepoagency from '../Pages/Reports/Advrepoagency';
import { CgProfile } from 'react-icons/cg';
import Mainaccountagency from '../Pages/Account/Mainaccountagency';
import Plansagency from '../Pages/Finance/Plansagency';
import Checkoutagency from '../Pages/Checkout/Checkoutagency';
import Paymentpage from '../Pages/Finance/Paymentpage';
import Billinginfo from '../Pages/Finance/Billinginfo';
import { ToastContainer, toast } from 'react-toastify';
import Mainemployeeagency from '../Pages/Employee/Mainemployeeagency';
import Mainchatagency from '../Pages/Chat/Mainchatagency';
import Chatscreenagency from '../Pages/Chat/Chatscreenagency';


const Mainrouteagency = () => {
  const token = localStorage.getItem('token')

  const [isvalid,setisvalid] = useState(null)
  const [loading,setloading] = useState(false)
  

  const {platformurl} = useParams()

  const [info,setinfo] = useState(null)

  useEffect(()=>{
    callForCheckPlatform()
    token && checkvalidtoken()
    
  },[])




  const callForCheckPlatform =async ()=>{
    setloading(true)
    await gpeapi.get(`/${platformurl}/checkagencyplatform`).then((res)=>setisvalid(res.data.success)).catch((e)=>setisvalid(e?.response?.data?.success))
    setloading(false)
  }




  const checkvalidtoken = ()=>{
    gpeapi.get(`/${platformurl}/checkagencytoken`,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>{
    if(res.data.success){
      setinfo(res.data.user)
      
       localStorage.setItem('userInfo', JSON.stringify(res.data.user));

       

       if(res.data.user?.extendplanended){
        toast.warning('Your Plan Ended Please Purchase a Plan to Continue Services')
       }

       if(res.data.user?.planended){
        toast.warning('Your Plan Ended Please Purchase a Plan to Continue Services')
       }

      
     
    }else{
      localStorage.clear()
      window.location.reload('')
    }
  })
  // console.log('runnn')
  }

  const menus = [
    { name: "dashboard", link: `/p/${platformurl}/agency/dashboard`, icon: MdOutlineDashboard },
    {
      name: "campaigns", link: "/", icon: MdCampaign, submenu: [
        { name: "all campaigns", link: `/p/${platformurl}/agency/campaigns` },
        { name: "create campaign", link: `/p/${platformurl}/agency/createcamp` },
        
      ]
    },
    {
      name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
        { name: "campaigns report", link: `/p/${platformurl}/agency/performancerepo` },
        { name: "daily report", link: `/p/${platformurl}/agency/dailyreport` },
        { name: "publishers report", link: `/p/${platformurl}/agency/publisherreport` },
        { name: "advertisers report", link: `/p/${platformurl}/agency/advertiserreport` },
        { name: "conversion report", link: `/p/${platformurl}/agency/conversionreport` },
        // { name: "clicks report", link: "/" },
        // { name: "sub-id reports", link: "/" },
      ]
    },
    { name: "publishers", link: "/", icon: TbFriends,submenu:[
      { name: "all publishers", link: `/p/${platformurl}/agency/publishers` },
      

    ] },
    { name: "advertisers", link: "/", icon: FaHandsHelping,submenu:[
      { name: "all advertisers", link: `/p/${platformurl}/agency/advertisers` },
      

    ]  },

    { name: "employees", link: "/", icon: BiSolidUserCheck,submenu:[
      { name: "all employees", link: `/p/${platformurl}/agency/employees` },
      

    ]  },



    { name: "account", link: `/p/${platformurl}/agency/account`, icon: CgProfile 
  },

  { name: "billing", link: `/p/${platformurl}/agency/billing`, icon: MdPayment 
  },
    // { name: "Cart", link: "/", icon: FiShoppingCart },
    // { name: "Billing", link: "/", icon: TbCreditCard },
    // { name: "Setting", link: "/", icon: RiSettings4Line },
  ];

  // console.log(info)


  return (

    isvalid==null?<Spinner style={{display:'none'}}/>:isvalid 
    ?
    <>

    <ToastContainer/>

    

  

      {token && <>
        <Mysidebar menus={menus} />
        <div style={{marginTop:60}}>
        <Header/>
        </div>
      </>}

      <Routes>

        {
          token ? info?.extendplanended ? (
            <>
              <Route path='/*' element={<Billinginfo />} />
              <Route path='/billing' element={<Billinginfo />} />

            </>
          ): (
            <>
              <Route path='/*' element={<Mainhomeagency />} />
              <Route path='/dashboard' element={<Mainhomeagency />} />
              <Route path='/createcamp' element={<CreateCamp />} />
              <Route path='/updatecamp/:id' element={<CreateCamp />} />
              <Route path='/viewcamp/:id' element={<ViewCamp />} />
              <Route path='/advertisers' element={<Mainadvertiseragency />} />
              <Route path='/publishers' element={<Mainpublishersagency />} />
              <Route path='/campaigns' element={<Maincampaign />} />
              <Route path='/employees' element={<Mainemployeeagency />} />

              <Route path='/chats' element={<Mainchatagency />} />
              <Route path='/startchat' element={<Chatscreenagency />} />


              <Route path='/account' element={<Mainaccountagency />} />
              <Route path='/billing' element={<Billinginfo />} />
              <Route path='/plans' element={<Plansagency />} />
              <Route path='/paymentdone' element={<Paymentpage />} />


              <Route path='/checkout' element={<Checkoutagency />} />


              <Route path='/performancerepo' element={<Performancerepoagency />} />
              <Route path='/dailyreport' element={<Dailyrepoagency />} />
              <Route path='/conversionreport' element={<Conversionrepoagency />} />
              <Route path='/publisherreport' element={<Pubrepoagency />} />
              <Route path='/advertiserreport' element={<Advrepoagency />} />

            </>

          ) : (
            <>
              <Route path='/*' element={<Mainloginagency />} />
              <Route path='/login' element={<Mainloginagency />} />
              <Route path='/forgot-password' element={<Forgotpassword />} />
            </>
          )
        }

        {/* <Route path='/home' element={<Mainhome/>}/> */}

        {/* <Route path='/register' element={<Mainregister/>}/> */}


      </Routes>
      

    </>
    :
    // <Spinner/>
    <Page404Found/>







  )
}

export default Mainrouteagency