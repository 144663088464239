import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
  } from "@material-tailwind/react";
import { COLORS } from '../Constants';

const Mypopup = ({handleoutopen,open,Components,setopenpop,width,onConfirm,title,des}) => {
    const [isOpen,setisOpen] = useState(false)

const handleOpen = ()=>{
    setisOpen(false)
    setopenpop(false)
    handleoutopen && handleoutopen()
}

useEffect(()=>{
    setisOpen(open)

},[open])

  return (
    <>
    
    <Dialog open={isOpen} handler={handleOpen} >
      <DialogHeader>{title}</DialogHeader>
      <DialogBody divider>
       {des}
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button variant="gradient" color={COLORS.primary} onClick={onConfirm}>
          <span>Confirm</span>
        </Button>
      </DialogFooter>
    </Dialog>
  </>
  )
}

export default Mypopup