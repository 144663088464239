

import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'



const Forgotpassadmemployee = () => {
    const navigate = useNavigate()
    const { platformurl } = useParams()
    const [loading, setloading] = useState(false)
    const [emailsent, setemailsent] = useState(false)
    const [openemailmodal, setopenemailmodal] = useState(false)
    const [mydta, setmydta] = useState({
        email: '',
        otp: '',
        password: '',
    })

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        setmydta({ ...mydta, [name]: value })
    }



    const handlelogin = async () => {
        await setloading(true)
        if (mydta.email !== '') {

            await gpeapi.post(`/forgotpasswordaemp`, mydta).then((res) => {
                toast.success(res.data.msg)
                if (res.data.success) {
                    setemailsent(true)
                }

            }).catch((e) => {
                toast.error(e?.response?.data.msg)
            })

        } else {
            toast.error('Please Enter Email ID')

        }
        await setloading(false)

    }

    const handlepasschange = async()=>{
        await setloading(true)
        if (mydta.email !== '') {

            await gpeapi.post(`/forgotpasswordaemp`, mydta).then((res) => {
                toast.success(res.data.msg)
                if (res.data.success) {
                    setemailsent(false)
                    navigate(`/offerborn/amd/employee/login`)

                }

            }).catch((e) => {
                toast.error(e?.response?.data.msg)
            })

        } else {
            toast.error('Please Enter Email ID')

        }
        await setloading(false)

    }


    return (


        <section className="bg-gray-50 dark:bg-gray-900">



            <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <ToastContainer />

                <div className="w-full bg-white rounded-lg  shadow-2xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex flex-col items-center justify-center m-4">
                        <a href="#" style={{ color: COLORS.primary }} className="font-bold flex items-center mb-6 text-2xl  text-gray-900 dark:text-white">
.
                        </a>
                        <p className='font-extralight '>Manager Forgot Password</p>
                    </div>




                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

                        <div className="space-y-4 md:space-y-6" action="#">

                            {
                                emailsent ? (
                                    <>
                                        <div>

                                            <input type="text" name="otp" id="password" placeholder="OTP" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required="" onChange={handleChnage} value={mydta.otp} />
                                        </div>
                                        <div>

                                            <input type="password" name="password" id="password" placeholder="New Password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required="" onChange={handleChnage} value={mydta.password} />
                                        </div>

                                        <div className='flex justify-end'>
                                            <Mybtn loading={loading} onClick={handlepasschange} title={'Submit'} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            {/* <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label> */}
                                            <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" placeholder="Email" required="" onChange={handleChnage} value={mydta.email} />
                                        </div>

                                        <div className='flex justify-end'>
                                            <Mybtn loading={loading} onClick={handlelogin} title={'Send OTP'} />
                                        </div>
                                    </>
                                )
                            }

                           




                            <div className='flex justify-center'>

                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Back to Login? <Link to={`/offerborn/amd/employee/login`} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Click Here</Link>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forgotpassadmemployee

