import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Authcontext } from '../../Context/Authcontext'
import gpeapi from '../../apis/gpeapi'
import Mycard from '../../COmponents/Mycard'
import { toast } from 'react-toastify'
import { COLORS } from '../../Constants'
import { Tooltip } from '@material-tailwind/react'

const Usageinagency = () => {

    const token = localStorage.getItem('token')
    const userinfo = JSON.parse(localStorage.getItem('userInfo'))


    const { toggle } = useContext(Authcontext)

    const { platformurl } = useParams()

    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()

    useEffect(() => {
        callforAPI()

    }, [])

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/agencyusage`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setstatdata(ress?.data?.data)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }


    return (
        <Mycard
            width={'45%'}
            classname={' border border-[#292e57] border-spacing-1 p-3 m-2 sm:w-full md:w-[47%]'}
            compo={
                <div>

                    <p className='font-semibold text-black text-base text-center'>This Month Usages</p>

                    <div className='mt-3'>

                        <p className='text-sm text-center m-1'>Plan Status: <span className='font-bold' style={{color:!statsdata?.planinfo?.isplanended?'green':'red'}}>{!statsdata?.planinfo?.isplanended?'ACTIVE':'EXPIRED'}</span></p>

                        {
                            !statsdata?.planinfo?.isplanended &&
                            <p className='text-sm text-center m-1'>Plan End in: <span style={{color:COLORS.primary}} className='font-bold'> {statsdata?.planinfo?.planendedin} DAYS</span></p>
                        }



                    </div>


                    <div className='mt-3'>

                        {
                            statsdata?.maindata?.map((val, i) => (
                                // <div key={i} className='flex m-2 p-1'>
                                //     <p className='text-xs capitalize'>{val?.name}</p>

                                // </div>

                                <div className='grid grid-cols-5  gap-1  border-b pb-2 pt-1'>

                                    <p className='text-xs font-medium capitalize'>{val?.name}</p>

                                    <Tooltip content={`Remaining : ${val?.left}`}>
                                                            
                                    <div style={{backgroundColor:COLORS.primary}} className='col-span-3 mr-2  rounded-full'>
                                        <div style={{width:val?.pr,height:'100%'}} className=' bg-blue-500 rounded-full'>

                                        </div>

                                    </div>
                                                        </Tooltip>



                                    {/* <p className='text-sm col-span-2'>5454</p> */}
                                    <p className='text-xs font-medium col-span-1 '>{val?.useage}/{val?.total}</p>
                                </div>
                            ))
                        }

                    </div>

                </div>
            }
        />
    )
}

export default Usageinagency