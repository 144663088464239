import React, { useContext, useEffect, useState } from 'react'
import { Authcontext } from '../../../Context/Authcontext'
import Mycard from '../../../COmponents/Mycard'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import Newchatpub from './Newchatpub'
import { ToastContainer, toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import { Spinner } from '@material-tailwind/react'
import { useNavigate, useParams } from 'react-router-dom'

const Mainchatagency = () => {

    const token = localStorage.getItem('token')
    const { platformurl } = useParams()
    const navigate = useNavigate()

    const { toggle } = useContext(Authcontext)
    const [newchatmodel, setnewchatmodel] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [loading,setloading] = useState(false)

    useEffect(() => {
        callforAPI()
      }, [])



    const callforAPI = async (show) => {
        show==true? setloading(false): setloading(true)
        const res = await gpeapi.get(`/${platformurl}/agency/getmessagewithid`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((ress) => {
    
          setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
          toast.error(e?.response?.data?.msg)
          
    
        })
        setloading(false)
    
      }
      const handlenavigate = (val)=>{
        navigate(`/p/${platformurl}/agency/startchat`,{state:{pubid:val}})

    }



    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer/>

            <Mymodal width={'70%'} Components={<Newchatpub setmodal={setnewchatmodel} />} open={newchatmodel} setopenmodal={setnewchatmodel} />


            <div style={{height:'90vh'}} className='rounded-md shadow-md  m-2 bg-white sm:w-full md:w-[95%] '>

                <div className='p-5'>

                    <Mybtn title={'NEW CHAT'} onClick={() => setnewchatmodel(true)} />

                </div>

                <div style={{overflow:'auto',maxHeight:'85vh'}}>

                    {
                        loading?<Spinner/>: statsdata?.docs?.length && statsdata?.docs?.map((val,i)=>(
                            <div onClick={()=>handlenavigate(val?.pubdata)} style={{borderWidth:0.5}} className='m-2 p-2 pl-3 rounded-full border-gray-500 border  items-center flex hover:bg-blue-gray-300 cursor-pointer'>
                             <img className='w-10 h-10 bg-white mr-4 rounded-full'  src="https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png" alt="ac_profile" />

                             <div>
                                <p className='text-lg font-bold capitalize'>{val?.pubdata?.name}</p>
                                <p className='text-xs '>{val?.message}</p>
                             </div>
                        </div>
                        ))

                    }

                </div>


            </div>



            


        </div>
    )
}

export default Mainchatagency