import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'
import { ToastContainer } from 'react-toastify'

const Productandsolution = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    document.title = `Solution - OfferBorn`

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <section style={{ marginTop: 50 }} class="section contact" id="contact" aria-label="contact">
            <ToastContainer />
            <div class="container">


                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>One Platform, Endless Possibilities</h2>

                <p class="section-text">
                    No matter what the use case is, we aim to solve our customers toughest challenges and help them create meaningful digital experiences.
                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src="https://i.pcmag.com/imagery/lineupitems/02FQKhDYy69cumBdDw2btai.fit_lim.size_1050x578.v1569506087.jpg" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Self Service Dashboard</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Manage all of your affiliates, advertisers, campaigns, creatives and invoicing in one place. OfferBorn lets you monitor the whole of your network activities in real-time

                            </p>



                        </div>



                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container " style={{direction:'rtl'}}>

                        <figure class="hero-banner">
                            <img src="https://offershub.com/wp-content/uploads/2019/10/undraw_master_plan_95wa.svg" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Media Buyers</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            OfferBorn lets you organize all of your campaigns and traffic sources in one dashboard. Many media buyers increased their revenue by atleast 30% with our real-time analytics, smart links, and traffic filtering.

                            </p>



                        </div>



                    </div>
                </section>



                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src="https://offershub.com/wp-content/uploads/2019/10/undraw_new_ideas_jdea.svg" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Advertisers</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Manage all of your campaigns and affiliates in one dashboard while providing a self-service dashboard for your affiliates. Easily monitor campaign performance in real-time, block/filter unwanted traffic and detect fraud activities..

                            </p>



                        </div>



                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container " style={{direction:'rtl'}}>

                        <figure class="hero-banner">
                            <img src="https://offershub.com/wp-content/uploads/2019/10/undraw_friends_online_klj6.svg" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Agencies</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            With OfferBorn, agencies can manage all of their publisher and advertiser sources with available campaigns in one dashboard. Agencies can monitor all the stats and transactions happening between publishers and advertisers in real-time.

                            </p>



                        </div>



                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src="https://offershub.com/wp-content/uploads/2019/10/undraw_online_organizer_ofxm.svg" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Email Marketers</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Use OfferBorn to track all of your email impressions, clicks and conversions in real-time. We let you analyze your performance in real time so you can optimize your campaigns and boost ROI.

                            </p>



                        </div>



                    </div>
                </section>




          






            </div>
        </section>
    )
}

export default Productandsolution