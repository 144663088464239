import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Mybtn from '../../../COmponents/Mybtn'
import { COLORS } from '../../../Constants'
import { ToastContainer, toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'

const Mainregister = () => {

    const { platformurl } = useParams()
    const [loading,setloading] =useState(false)
    const [emailnotverify,setemailnotverify] = useState(false)
    const [openemailmodal,setopenemailmodal] = useState(false)
    const navigate = useNavigate()
    const [mydta,setmydta] = useState({
        name:'',
        email:'',
        password:'',
        mobile:'',
        company:'',
        skype:'',
    })

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        setmydta({ ...mydta, [name]: value })
    }

   

    const handlelogin =async ()=>{
        await setloading(true)
        if (mydta.email !== '' && mydta.password !== '') {

           await gpeapi.post(`${platformurl}/advsignup`, mydta).then((res) => {
            if(res?.data.success){
            toast.success(res?.data.msg)

            setTimeout(() => {
                navigate(`/p/${platformurl}/advertiser/login`)
                
            }, 3000);
           

            }
           }).catch((e) => {

            toast.error(e?.response?.data.msg)

            })

        } else {
            toast.error('Please fill all Data')
           
        }
        await setloading(false)

    }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
   
    <ToastContainer />
        <div className="w-full bg-white rounded-lg shadow-2xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

        <div className="flex flex-col items-center justify-center m-4">
        <a href="#" style={{color:COLORS.primary}} className="font-bold flex items-center mb-6 text-2xl  text-gray-900 dark:text-white">

{(platformurl).toUpperCase()}   
</a>
        <p className='font-extralight '>Advertiser Register</p>
        </div>
       
            
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

            
                
                <div className="space-y-4 md:space-y-6" action="#">
                <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name <span className='text-red-700'>* </span></label>
                        <input type="text"  onChange={handleChnage} value={mydta.name} name="name" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" placeholder="Full Name" required=""/>
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email <span className='text-red-700'>* </span></label>
                        <input type="email"  onChange={handleChnage} value={mydta.email} name="email"  placeholder="Email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required=""/>
                    </div>

                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password <span className='text-red-700'>* </span></label>
                        <input type="password" onChange={handleChnage} value={mydta.password} name="password"  placeholder="Password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required=""/>
                    </div>

                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile <span className='text-red-700'>* </span></label>
                        <input type="text"  onChange={handleChnage} value={mydta.mobile} name="mobile"  placeholder="Mobile Number" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required=""/>
                    </div>

                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company Name </label>
                        <input type="text"  onChange={handleChnage} value={mydta.company} name="company"  placeholder="Company Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required=""/>
                    </div>

                    

                   
                    
                    <div className=' flex-col justify-center items-center'>

                                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                            By submitting this form you accept <Link to="/terms-&-conditions" className="text-blue-600 font-medium text-primary-600 hover:underline dark:text-primary-500">Terms and Conditions</Link> and <Link to="/privacy-policy" className="text-blue-600 font-medium text-primary-600 hover:underline dark:text-primary-500">Privacy Policy</Link>
                                        </p>

                                    </div>

                    <div className='flex justify-end'>
                        
                        <Mybtn loading={loading} onClick={handlelogin} title={'Register'} />

                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Already have account? <Link to={`/p/${platformurl}/advertiser/login`} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</Link>
                    </p>

                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                     <Link to={`/p/${platformurl}/publisher/login`} className="text-sm  text-gray-500 dark:text-gray-400 font-medium text-primary-600 hover:underline dark:text-primary-500">Are you an publisher?</Link>
                    </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  )
}

export default Mainregister