import React, { useEffect, useState } from 'react'
import Myinput from '../../../COmponents/Myinput'
import { CheckboxGroup } from 'react-rainbow-components';
import { Checkbox } from '@material-tailwind/react';
import Mybtn from '../../../COmponents/Mybtn';

const Filtercompo = ({ setmdata, sdata,handlereste }) => {

    const [mydta, setmydta] = useState(sdata)


    
    // useEffect(()=>{
    //     setmydta(sdata)
    // },[])

    useEffect(() => {
        setmdata(mydta)
    }, [mydta])




    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }



    return (
        <div>

            <div>

                <Myinput type={'text'} label={'Click ID'} width={'100%'} name={'clickid'} value={mydta.clickid} onChange={handleChnage} placeholder={'CLICK ID'} />

                <Myinput type={'text'} label={'Search'} width={'100%'} name={'conversionid'} value={mydta.conversionid} onChange={handleChnage} placeholder={'Search P1,P2,P3,P4,GAID'} />



                <div>
                    <Checkbox checked={mydta.p2} onChange={(e) => setmydta({ ...mydta, p2: !mydta.p2 })} value={mydta.p2} label='P2' />
                    <Checkbox checked={mydta.p3} onChange={(e) => setmydta({ ...mydta, p3: !mydta.p3 })} value={mydta.p3} label='P3' />
                    <Checkbox checked={mydta.p4} onChange={(e) => setmydta({ ...mydta, p4: !mydta.p4 })} value={mydta.p4} label='P4' />
                    <Checkbox checked={mydta.gaid} onChange={(e) => setmydta({ ...mydta, gaid: !mydta.gaid })} value={mydta.gaid} label='GAID' />
                    <Checkbox checked={mydta.event} onChange={(e) => setmydta({ ...mydta, event: !mydta.event })} value={mydta.event} label='EVENT(Goal)' />

                    <Mybtn title={'Reset'} onClick={handlereste}/>

                </div>


            </div>


        </div>
    )
}

export default Filtercompo