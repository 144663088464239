import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchpublisher } from '../../../Redux/Actions/Agency/publisheragencyaction'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '@material-tailwind/react'


const Newchatpub = () => {

    const [reduloading,setreduloading] = useState(false)

    const { platformurl } = useParams()
    const dispatch = useDispatch()
const navigate = useNavigate()
    useEffect(() => {
        callforReducer()
    }, [])
    const callforReducer = async () => {
        setreduloading(true)
        await dispatch(fetchpublisher(platformurl))
        setreduloading(false)
    }

    const advertiserdta = useSelector((state) => state.publisherAgencyReducer)

    // console.log(advertiserdta[0]?.docs)

    const handlenavigate = (val)=>{
        navigate(`/p/${platformurl}/agency/startchat`,{state:{pubid:val}})

    }
  return (
    <div>

        <p className='text-xl font-bold'>Select Publisher</p>
        
        <div className='m-5'>

            {
                reduloading?
                <Spinner/>:(
                    advertiserdta[0]?.docs?.map((val,i)=>(
                        <div onClick={()=>handlenavigate(val)} style={{borderWidth:0.5}} className='m-2 p-2 pl-3 rounded-full border-gray-500 border  items-center flex hover:bg-blue-gray-300 cursor-pointer'>
                             <img className='w-10 h-10 bg-white mr-4 rounded-full'  src="https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png" alt="ac_profile" />

                             <div>
                                <p className='text-lg font-bold capitalize'>{val?.name}</p>
                                <p className='text-xs capitalize'>{val?.company}</p>
                             </div>
                        </div>
                    ))
                )

            }

        </div>




    </div>
  )
}

export default Newchatpub