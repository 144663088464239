import React from 'react'
import { DatePicker } from 'react-rainbow-components';

const Mydate = ({tips,line, label, placeholder, onChange, value, name, type,width,required ,classname,multiline}) => {
  return (
    <div class="mb-4 m-1" className={classname} style={{position:'relative',width:width?width==1?null:width:250}}>
        <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label} {required && <span style={{color:'red',textAlign:'center',marginLeft:5}}> *</span>} </label>

        <DatePicker
            value={value}
            placeholder={placeholder}
            // label="DatePicker Label"
            onChange={onChange}
        />



        
        <p className='text-xs text-gray-500'>{tips}</p>
       
    </div>
  )
}

export default Mydate