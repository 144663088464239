import { Chip, Tooltip, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Mycard from '../../../../../COmponents/Mycard'
import Mymodal from '../../../../../COmponents/Mymodal'


import Addbankpub from './Addbankpub'

import { COLORS } from '../../../../../Constants'
import { Authcontext } from '../../../../../Context/Authcontext'
import gpeapi from '../../../../../apis/gpeapi'
import { useParams } from 'react-router-dom'

const Mainacountpub = () => {
    const {platformurl,pubid} = useParams()
    const token = localStorage.getItem('token')
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const { toggle } = useContext(Authcontext)
    const [userinfo,setuserinfo] = useState({})
    // const userinfo = JSON.parse(localStorage.getItem('userInfo'))
    const [bankmodel, setbankmodel] = useState(false)
    const [passmodel, setpassmodel] = useState(false)



    useEffect(()=>{
callforAPI()
    },[])

    const callforAPI = async()=>{
        const res = await gpeapi.get(`/${platformurl}/agency/getpublishers/${pubid}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setuserinfo(ress.data?.user)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))

    }


    const genratedate = (date) => {
        const d = new Date(date);
        let month = months[d.getMonth()];
        let year = d.getFullYear()
        return `${month} ${year}`
    }


    const openaddbankmodel = () => {
        setbankmodel(true)
    }



    return (
        <div className={toggle ? "main active" : "main"}>

            {/* <Mymodal width={'40%'} Components={<Addbankpub setmodal={setbankmodel} id={userinfo?.id} />} open={bankmodel} setopenmodal={setbankmodel} /> */}

            {/* <Mymodal width={'40%'} Components={<Changepasswordpub setmodal={setpassmodel} />} open={passmodel} setopenmodal={setpassmodel} /> */}

            <ToastContainer />


            <div className='sm:flex sm:flex-col md:flex md:flex-row md:flex-wrap'>

                <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                    <div>
                        <div className='flex justify-between items-center border-b p-5 pb-1'>

                            <div class="flex  items-center space-x-4">
                                <svg class=" w-10 h-10 rounded-full" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                                <div class="font-medium dark:text-white">
                                    <div className='uppercase'>{userinfo?.name}</div>
                                    <div class="text-sm text-gray-500 dark:text-gray-400">Joined in {genratedate(userinfo?.createdAt)}</div>
                                </div>
                            </div>

                            <div className='p-2 flex flex-col items-center'>

                                {/* <p onClick={()=>setpassmodel(true)} style={{ backgroundColor: COLORS.primary }} className='max-w-fit pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer m-2'>

                                    <p className='font-medium text-white text-sm'>
                                        Change Password
                                    </p>

                                </p> */}
                                {/* <p style={{ backgroundColor: COLORS.primary }} className='max-w-fit pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer m-2'>

                                    <p className='font-medium text-white text-sm'>
                                        Edit
                                    </p>

                                </p> */}

                            </div>





                        </div>

                        <div className='p-5 pt-2'>

                            <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                <p className='text-sm'>ID</p>

                                <p className='text-sm col-span-3'>{userinfo?.id}</p>
                            </div>

                            <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                <p className='text-sm '>Company</p>

                                <p className='text-sm col-span-3 capitalize'>{userinfo?.company}</p>
                            </div>
                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Email</p>

                                <p className='text-sm col-span-3'>{userinfo?.email}</p>
                            </div>

                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Mobile</p>

                                <p className='text-sm col-span-3'>+91{userinfo?.mobile}</p>
                            </div>
                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Currency</p>

                                <p className='text-sm col-span-3'>INR</p>
                            </div>
                            <div className='justify-center grid grid-cols-4  gap-4  pb-2 pt-2'>

                                <p className='text-sm '>Language</p>

                                <p className='text-sm col-span-3'>EN</p>
                            </div>













                        </div>


                    </div>
                } />

                <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                    <div>
                        <div className='flex justify-between items-center border-b p-5 pb-1'>


                            <div class="font-medium dark:text-white">
                                <div className='uppercase'>Payments Method</div>

                            </div>


                            <div className='p-2 flex flex-col items-center'>

                                <p style={{ backgroundColor: COLORS.primary }} className='max-w-fit pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer m-2'>

                                    {/* <p onClick={openaddbankmodel} className='font-medium text-white text-sm'>
                                        Edit
                                    </p> */}

                                </p>

                            </div>





                        </div>

                        <div className='p-5 pt-2'>

                            {
                                userinfo?.payment_method ? (
                                    <>
                                        <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                            <p className='text-sm'>Account Number</p>

                                            <p className='text-sm col-span-3'>{userinfo?.payment_method?.banknumber}</p>
                                        </div>

                                        <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                            <p className='text-sm'>Bank Holder Name</p>

                                            <p className='text-sm col-span-3'>{userinfo?.payment_method?.bankholdername}</p>
                                        </div>
                                        <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                            <p className='text-sm ' >Bank Name</p>

                                            <p className='text-sm col-span-3'>{userinfo?.payment_method?.bankname}</p>
                                        </div>

                                        <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                            <p className='text-sm'>Bank IFSC</p>

                                            <p className='text-sm col-span-3'>{userinfo?.payment_method?.bankifsc}</p>
                                        </div>

                                        <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                            <p className='text-sm'>Bank Branch</p>

                                            <p className='text-sm col-span-3'>{userinfo?.payment_method?.bankbranch}</p>
                                        </div>
                                    </>

                                ) : (

                                    <p>No Bank Account Found</p>
                                )
                            }
                        </div>




                    </div>
                } />
            </div>
        </div>
    )
}

export default Mainacountpub