import { SET_BASIC, SET_PRICING } from "../actiontypes/usertypes";

export const productsInitialState = [];

const pricingReducer = (state=productsInitialState,action)=>{
switch (action.type) {
    case SET_PRICING:
        return [...action.payload]
        break;

    default:
       return  state;
}
}

export default pricingReducer