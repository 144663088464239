import { Option, Select } from '@material-tailwind/react'
import React from 'react'

const Myselect = ({tips, label, data, placeholder, onChange, value, name, type, width, required, classname, multiline }) => {

    // console.log(value)
    return (
        <div class="mb-4 m-1" className={classname} style={{ position: 'relative', width: width ? width == 1 ? null : width : 250 }}>
            <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label} {required && <span style={{ color: 'red', textAlign: 'center', marginLeft: 5 }}> *</span>} </label>

            <Select  onChange={onChange}  name={name} value={value} size="lg" label={placeholder}>
                {data?.map((val,i)=>(
                    <Option  value={val.value} key={i}>{val.label}</Option>

                ))}
               
            </Select>
        <p className='text-xs text-gray-500'>{tips}</p>


            {/* <select onChange={onChange} name={name} value={value} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option disabled={true}  selected>{label}</option>
                {data?.map((val,i)=>(
                <option value={val.value}  key={i}>{val.label}</option>

                ))}
            </select> */}


        </div>
    )
}



export default Myselect