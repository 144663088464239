import React, { useContext, useEffect, useState } from 'react'
import { Label, Radio } from 'flowbite-react';
import { Select, Option } from "@material-tailwind/react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Myselect from '../../../../../../COmponents/Myselect';
import Myinput from '../../../../../../COmponents/Myinput';
import Mybtn from '../../../../../../COmponents/Mybtn';
import Mymodal from '../../../../../../COmponents/Mymodal';

import GoalComponew from './GoalComponew';
import { toast, ToastContainer } from 'react-toastify';
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import Mypopup from '../../../../../../COmponents/Mypopup';
import gpeapi from '../../../../../../apis/gpeapi';
import { fetchcampaign } from '../../../../../../Redux/Actions/Agency/campaignagencyaction';
import { fetchadvertiser } from '../../../../../../Redux/Actions/Agency/advertiseragencyaction';
import { Authcontext } from '../../../../../../Context/Authcontext';
import { COLORS } from '../../../../../../Constants';


const CreateCamp = () => {

    const { id } = useParams()

    const [loading, setloading] = useState(false)

    const [myid, setmyid] = useState(null)
    const [myindex, setmyindex] = useState(null)
    const [mygoaldta, setmygoaldta] = useState(null)
    const [openpop, setopenpop] = useState(false)

    const { toggle } = useContext(Authcontext)
    const token = localStorage.getItem('token')
    const [advloading, setadvloading] = useState(false)
    const [dataloading, setdataloading] = useState(false)

    const { platformurl } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        callforReducer()
        id && updateForm()
    }, [])

    const callforReducer = async () => {
        setadvloading(true)
        await dispatch(fetchadvertiser(platformurl))
        setadvloading(false)
    }

    const updateForm = async () => {
        setdataloading(true)
        const res = await gpeapi.get(`/${platformurl}/adm/getcampaign/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress.data?.user)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))
        setdataloading(false)
    }







    const advertidata = [

    ]

    const advertiserdta = useSelector((state) => state.advertiserAgencyReducer)[0]?.docs?.map((val) => advertidata.push({ label: `${val.name} (${val.company})`, value: val.id }))

    const ac_statusdta = [
        { label: 'Active', value: 'active' },
        { label: 'Paused', value: 'paused' },
        { label: 'Pending', value: 'pending' },

    ]
    const devices_dta = [
        { label: 'ALL', value: 'all' },
        { label: 'Desktop', value: 'desktop' },
        { label: 'Mobile', value: 'mobile' },
        { label: 'Tablet', value: 'tablet' },

    ]


    // console.log(advertiserdta)


    const campVisable = [
        { name: 'Public', value: 'public' },
        { name: 'Private', value: 'private' },
        { name: 'Ask for Permission', value: 'ask-for-permission' },


    ]

    const objectives = [
        { name: 'CPA',value:'CPA' },
        { name: 'CPI',value:'CPI' },
        { name: 'CPR',value:'CPR' },
        { name: 'CPS',value:'CPS' },
        { name: 'CPL' ,value:'CPL'},

    ]

    // const macros = ['{clickid}','{gaid}','{p1}','{p2}','{p3}','{p4}',]
    const macros = [
        { params: '{clickid}', content: 'Unique Click Id generated by our Platform' },
        { params: '{gaid}', content: 'GAID' },
        { params: '{p1}', content: 'Tracking Parameter 1 used to track your publishers software click id' },
        { params: '{p2}', content: 'Tracking Parameter 2' },
        { params: '{p3}', content: 'Tracking Parameter 3' },
        { params: '{p4}', content: 'Tracking Parameter 4' },
    ]
    const [opengaolmodal, setopengaolmodal] = useState(false)


    const [formValues, setFormValues] = useState({ id: '', title: "", value: "", currency: '', rev: '', po: '' })


    const [mydta, setmydta] = useState({
        title: '',
        advertiser: '',
        advertiserid: '',
        description: '',
        kpi: '',
        previewurl: '',
        conversiontracking: '',
        campaignurl: '',
        status: '',
        devices: '',
        category: '',
        operating_system: '',
        app_name: '',
        icon: '',
        note: '',
        campaignmodel: '',
        currency: '',
        default_goal: '',
        revenue: '',
        payout: '',
        goals: '',
        visibility: '',
        caps:''
    })

const navigate = useNavigate()





    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }


    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const addgoaltt = async (e) => {
        setmydta({ ...mydta, goals: mydta.goals.length ? [...mydta.goals, e] : [e] })
        setopengaolmodal(false)
    }

    const TABLE_HEAD = ["ID", "Title", "Value", "Revenue", "Payout", "Action"];

    const onEditUser = (id, i) => {
        setmyid(id)
        setmyindex(i)
        setopengaolmodal(true)
        const filterr = mydta?.goals?.filter((val) => val.id == id)[0]
        setmygoaldta(filterr)
    }

    const onDeleteUser = (id) => {
        setopenpop(true)
        setmyid(id)
    }

    const handleDeleteAdv = () => {
        const filterDta = mydta?.goals?.filter((val) => val.id !== myid)
        setmydta({ ...mydta, goals: filterDta })
        toast.success('Goal Removed')
        setopenpop(false)
        setmyid(null)
    }

    const onUpdategoal = async (data) => {
        const list = [...mydta?.goals]
        list[myindex] = data
        setmydta({ ...mydta, goals: list })
        setopengaolmodal(false)
    }
    const handlecreateCamp = async () => {

        if (mydta.advertiserid !== '') {
            if (mydta.title !== '') {
                if (mydta.campaignurl !== '') {
                    if (mydta.campaignmodel !== '') {
                        if (mydta.currency !== '') {
                            if (mydta.revenue !== '') {
                                if (mydta.payout !== '') {
                                    setloading(true)
                                    await gpeapi.post(`/${platformurl}/adm/createcampaign`, mydta, {
                                        headers: {
                                            "Authorization": `Beaer ${token}`
                                        }
                                    }).then((res) => {
                                        if (res.data.success) {
                                            toast.success('Campaign Created Successful')
                                            
                                            navigate(`/p/${platformurl}/agency/campaigns`)

                                            dispatch(fetchcampaign(platformurl))


                                        }

                                    }).catch((e) => {
                                        toast.error(e?.response?.data?.msg)

                                    })
                                    setloading(false)


                                } else {
                                    toast.warning('Please Enter Payout')
                                }

                            } else {
                                toast.warning('Please Enter Revenue')
                            }

                        } else {
                            toast.warning('Please Select Currency')
                        }

                    } else {
                        toast.warning('Please Select Objective')
                    }

                } else {
                    toast.warning('Please Enter Campaign URL')
                }

            } else {
                toast.warning('Please Enter Title')
            }

        } else {
            toast.warning('Please Select Advertiser ')
        }



    }

    const handleupdateCamp = async () => {
        setloading(true)
        await gpeapi.put(`/${platformurl}/adm/updatecampaign/${id}`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                toast.success('Campaign Updated Successful')
                // dispatch(fetchcampaign(platformurl))
                navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/campaigns`)

            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)
    }


    

    // console.log(mydta)
    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />


            <p className='text-2xl font-medium'> {id ? 'UPDATE' : 'CREATE'} CAMPAIGN</p>

            <Mymodal handleoutopen={() => setmyid(null)} width={'40%'} Components={<GoalComponew onupdategoal={onUpdategoal} data={mygoaldta} id={myid} onaddgoalFun={addgoaltt} setmodal={setopengaolmodal} />} open={opengaolmodal} setopenmodal={setopengaolmodal} />

            <Mypopup handleoutopen={() => setmyid(null)} title={`Are you Sure`} des={`you want to delete id ${myid}?`} open={openpop} setopenpop={setopenpop} onConfirm={handleDeleteAdv} />

            {dataloading ? <div className='flex justify-center items-center'>
                <Spinner className="h-10 w-10  text-blue-500/10" />
            </div>
                :
                <div className='bg-white rounded-md p-2 mt-3'>
                    <p>Details</p>

                    <div className='grid grid-cols-4 gap-4 m-8 '>
                        <p>Choose an Objective <span className='text-red-600 font-bold'>*</span></p>

                        <div className="flex items-center gap-2">
                            {objectives?.map((val) => (
                                <>
                                    <Radio
                                        checked={mydta.campaignmodel == val.value}
                                        
                                        defaultValue={mydta.campaignmodel}
                                        id={val.value}
                                        name='objectivedta'
                                        value={val.value}
                                        onChange={(e) => setmydta({ ...mydta, campaignmodel: e.target.value })}
                                    />
                                    <Label className='text-sm font-normal' htmlFor="united-state">
                                        {val.name}
                                    </Label>


                                </>

                            ))}

                        </div>
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Advertiser <span className='text-red-600 font-bold'>*</span></p>

                        {advloading ? <Spinner /> :


                            <Myselect value={mydta?.advertiserid} data={advertidata} width={'200%'} placeholder={'Select Advertiser'} name={'advertiserid'} onChange={(e) => handleChnageSelect(e, 'advertiserid')} />
                        }


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Campaign Name <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.title} name='title' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Description (Optional) </p>

                        <Myinput line={8} multiline={true} width={'200%'} onChange={handleChnage} value={mydta.description} name='description' />

                    </div>


                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>KPI (Optional) </p>

                        <Myinput line={8} multiline={true} width={'200%'} onChange={handleChnage} value={mydta.kpi} name='kpi' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Preview URL </p>

                        <Myinput tips={'Link to preview landing page which publishers can see'} width={'200%'} onChange={handleChnage} value={mydta.previewurl} name='previewurl' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Campaign URL <span className='text-red-600 font-bold'>*</span></p>
                        <div>
                            <Myinput tips={'The campaign URL where traffic will redirect to. Optional variabled can be used in URL.'} width={'200%'} onChange={handleChnage} value={mydta.campaignurl} name='campaignurl' />




                        </div>

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p></p>
                        <div>
                            <p className='font-medium m-2 text-xs'>Most Used URL tokens</p>

                            <div style={{ width: '200%' }} className='flex flex-wrap'>

                                {
                                    macros?.map((val, i) => (
                                        <Tooltip key={i} content={val.content}>


                                            <span onClick={() => setmydta({ ...mydta, campaignurl: mydta.campaignurl + val.params })} className='text-sm cursor-pointer p-1 m-1 pl-4 pr-4 bg-[#ebeced] rounded-xl border-2' key={i}>{val.params}</span>
                                        </Tooltip>
                                    ))
                                }
                            </div>


                        </div>
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Status</p>




                        <Myselect tips={'Active for publishers to run, paused, pending not visible to publishers'} value={mydta?.status} data={ac_statusdta} width={'200%'} placeholder={'Select Status'} name={'status'} onChange={(e) => handleChnageSelect(e, 'status')} />



                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Category</p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.category} name='category' />

                    </div>



                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Note (Optional)</p>

                        <Myinput multiline={true} line={4} width={'200%'} onChange={handleChnage} value={mydta.note} name='note' />

                    </div>


                    <div className='grid grid-cols-4 gap-4 m-8 '>
                        <p>Campaign Visibility</p>

                        <div className="flex items-center gap-2" style={{ width: '200%' }}>
                            {campVisable?.map((val) => (
                                <>
                                    <Radio
                                        checked={mydta.visibility == val.value}

                                        defaultValue={mydta.visibility}
                                        id={val.value}
                                        name="objective"
                                        value={val.value}
                                        onChange={(e) => setmydta({ ...mydta, visibility: e.target.value })}
                                    />
                                    <Label className='text-sm font-normal' htmlFor="united-state">
                                        {val.name}
                                    </Label>


                                </>

                            ))}

                        </div>
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Campaign Icon URL</p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.icon} name='icon' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>App Name (Optional)</p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.app_name} name='app_name' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Devices</p>
                        <Myselect value={mydta?.devices} data={devices_dta} width={'200%'} placeholder={'Select Devices'} name={'devices'} onChange={(e) => handleChnageSelect(e, 'devices')} />
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Currency <span className='text-red-600 font-bold'>*</span></p>
                        <Myselect value={mydta?.currency} data={[{ label: 'INR', value: 'inr' }]} width={'200%'} placeholder={'Select Currency'} name={'currency'} onChange={(e) => handleChnageSelect(e, 'currency')} />
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Revenue <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput type={'number'} placeholder={'Charged from advertiser. Eg: 15'} width={'200%'} onChange={handleChnage} value={mydta.revenue} name='revenue' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Payout<span className='text-red-600 font-bold'>*</span></p>

                        <Myinput type={'number'} placeholder={'Pay to Publisher'} width={'200%'} onChange={handleChnage} value={mydta.payout} name='payout' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Goals </p>

                        <div style={{ width: '350%' }}>

                            {mydta?.goals &&

                                <CardBody className="overflow-scroll px-0">
                                    <table className="w-full min-w-max table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head) => (
                                                    <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal leading-none opacity-70"
                                                        >
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mydta?.goals?.length && mydta?.goals.map(
                                                (val, index) => {
                                                    const isLast = index === mydta?.goals?.length - 1;
                                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={index}>
                                                            <td className={classes}>


                                                                <p className="text-xs">
                                                                    {val?.id}
                                                                </p>

                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {val?.title}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {val?.value}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="green" className="font-normal">
                                                                    {val?.rev}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue" className="font-normal">
                                                                    {val?.po}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Tooltip content="Edit">
                                                                    <IconButton onClick={() => onEditUser(val.id, index)} variant="text" color="blue-gray">
                                                                        <PencilIcon className="h-4 w-4" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip content="Delete">
                                                                    <IconButton onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                                        <TrashIcon className="h-4 w-4" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                            }




                            <Mybtn title={'Add Goals'} fontsize={12} radius={10} padding={'1px 10px'} onClick={() => setopengaolmodal(true)} />
                            {id && <p className='text-xs text-gray-400'>* After Goal Updated Please Click Update Campaign Button also</p>}
                        </div>







                    </div>


                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Caps</p>

                        <div>
                        <Myinput type={'number'} placeholder={'Daily'} width={'200%'} onChange={(e)=>setmydta({...mydta,caps:{...mydta.caps,daily:e.target.value}})} value={mydta?.caps?.daily}  />

                        <Myinput type={'number'} placeholder={'Monthly'} width={'200%'} onChange={(e)=>setmydta({...mydta,caps:{...mydta.caps,monthly:e.target.value}})} value={mydta?.caps?.monthly}  />

                        <Myinput type={'number'} placeholder={'Lifetime'} width={'200%'} onChange={(e)=>setmydta({...mydta,caps:{...mydta.caps,lifetime:e.target.value}})} value={mydta?.caps?.lifetime}  />

                        </div>


                    </div>






                    {
                        id ? <Mybtn loading={loading} onClick={handleupdateCamp} title={'Update '} />
                            : <Mybtn loading={loading} onClick={handlecreateCamp} title={'Create '} />
                    }







                </div>
            }
        </div>
    )
}

export default CreateCamp