import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'
import CountUp from 'react-countup';
import Mybtn from '../../COmponents/Mybtn';
import Myinput from '../../COmponents/Myinput';
import { ToastContainer, toast } from 'react-toastify';
import gpeapi from '../../apis/gpeapi';

const Contactus = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    document.title = `Contact Us - OfferBorn`

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const [ismsgsent, setismsgsent] = useState(false)
    const [loading, setloading] = useState(false)
    const [mydta, setmydta] = useState({
        name: '',
        mobile: '',
        email: '',
        message: ''
    })



    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handlelogin = async () => {
        if (mydta.name !== '') {

            if (mydta.email !== '') {

                if (mydta.mobile !== '') {

                    await setloading(true)
                    await gpeapi.post(`/sendmessage`, mydta).then((res) => {
                        if (res?.data.success) {
                            toast.success(res?.data.msg)
                            setismsgsent(true)



                        }
                    }).catch((e) => {

                        toast.error(e?.response?.data.msg)

                    })
                    setloading(false)

                } else {
                    toast.warn('Please Enter Mobile')
                }

            } else {
                toast.warn('Please Enter Email')
            }

        } else {
            toast.warn('Please Enter Name')
        }

    }
    return (
        <section style={{ marginTop: 50 }} class="section contact" id="contact" aria-label="contact">
            <ToastContainer />
            <div class="container">


                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Contact Us</h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src="https://assets.website-files.com/577a24f2280cf6ac333b2a71/63bbf22710f7d2fedab33ea2_contact%20us.svg" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <div className=' flex justify-center'>


                                <div className="w-full bg-white rounded-lg  shadow-2xl dark:border md:mt-0 sm:max-w-md xl:p-3 dark:bg-gray-800 dark:border-gray-700 flex justify-center flex-col">

                                    {/* <h3>Don’t hesitate to contact us for any information.​​</h3> */}


                                    <div className='m-3'>
                                        {/* <p className='text-3xl text-center text-black font-medium'>Create an Account</p> */}
                                        <p className='text-sm text-center mt-2 text-gray-400 '>Don’t hesitate to contact us for any information.</p>

                                    </div>


                                    <div className="p-3 space-y-4 md:space-y-6 sm:p-8">

                                        {
                                            ismsgsent ? (

                                                <div className="space-y-4 md:space-y-6" action="#">

                                                    <p>We have received your request and will get back to you in 24 hours</p>







                                                </div>

                                            ) : (

                                                <div className="space-y-4 md:space-y-6" action="#">

                                                    <Myinput width={'100%'} placeholder={'Name'} name='name' value={mydta.name} onChange={handleChnage} />

                                                    <Myinput width={'100%'} placeholder={'Email'} name='email' value={mydta.email} onChange={handleChnage} />


                                                    <Myinput width={'100%'} placeholder={'Mobile Number'} name='mobile' value={mydta.mobile} onChange={handleChnage} />



                                                    <Myinput width={'100%'} multiline={true} line={4} placeholder={'Message '} name='message' value={mydta.message} onChange={handleChnage} />








                                                    <div className='flex justify-end'>
                                                        <Mybtn loading={loading} onClick={handlelogin} title={'SEND MESSAGE'} />
                                                    </div>



                                                </div>

                                            )}
                                    </div>
                                </div>






                            </div>



                        </div>



                    </div>
                </section>

                <section className='mt-20'>

                    {/* <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p> */}

                    <div className='flex p-3 shadow-lg flex-col  justify-around items-center bg-white rounded-lg sm:flex-row md:flex-row xl:flex-row 2xl:flex-row  '>

                        

                            <li class="contact-item">
                                <div class="contact-card">

                                    <div class="card-icon">
                                        <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>
                                    </div>

                                    <div class="card-content">

                                        <h3 class="text-xl card-title font-medium">Mail Here</h3>

                                        <a href={`mailto:${basicData?.contact_email}`}class="text-base">                                            {basicData?.contact_email}</a>


                                    </div>

                                </div>
                            </li>

                            <li class="contact-item">
                                <div class="contact-card">

                                    <div class="card-icon">
                                        <ion-icon name="logo-skype" aria-hidden="true"></ion-icon>
                                    </div>

                                    <div class="card-content">

                                        <h3 class="text-xl card-title font-medium">Skype Here</h3>

                                        <address class="text-base">
                                            {basicData?.contact_skype}
                                        </address>

                                    </div>

                                </div>
                            </li>

                            <li class="contact-item">
                                <div class="contact-card">

                                    <div class="card-icon">
                                        <ion-icon name="headset-outline" aria-hidden="true"></ion-icon>
                                    </div>

                                    <div class="card-content">

                                        <h3 class="text-xl card-title font-medium">Call Here</h3>

                                        <a href={`tel:${basicData?.contact_mobile}`} class="text-base">{basicData?.contact_mobile}</a>


                                    </div>

                                </div>
                            </li>

                        



                    </div>


                </section>

                <section className='mt-20'>

                    <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p>

                    <div className='flex justify-center items-center'>
                        <div class="service-card" style={{ border: `1px solid ${COLORS.primary}`, borderRadius: 25, width: '40%' }}>

                            <img style={{ height: 40, width: 40, margin: 'auto' }} src='https://affise.com/wp-content/uploads/2022/05/india.png' />



                            <h3 class="h3">
                                <a href="#" class="text-lg">India</a>
                            </h3>

                            <p class="text-base">
                                {basicData?.address} {basicData?.pincode}
                            </p>
                            <p class="text-base">
                                {basicData?.contact_mobile}
                            </p>


                        </div>
                    </div>


                </section>






            </div>
        </section>
    )
}

export default Contactus