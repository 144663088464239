



import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import Mysidebar from '../../COmponents/Mysidebar';

import { MdOutlineDashboard, MdCampaign, MdPayment } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { FaFileInvoiceDollar, FaHandsHelping } from "react-icons/fa";

import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoAnalyticsSharp } from "react-icons/io5";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";
import { TbFriends, TbCreditCard } from "react-icons/tb";

import Header from '../../COmponents/Header';



import Mainloginadmin from '../Pages/Login/Mainloginadmin';
import Maindashboard from '../Pages/Dashboard/Maindashboard';
import gpeapi from '../../apis/gpeapi';
import Mainbasicdet from '../Pages/BasicDet/Mainbasicdet';
import Addbasicdet from '../Pages/BasicDet/Addbasicdet';
import Mainpricing from '../Pages/Pricing/Mainpricing';
import Addpricing from '../Pages/Pricing/Addpricing';
import Mainagencyadm from '../Pages/Agencys/Mainagencyadm';
import Addagencyadm from '../Pages/Agencys/Addagencyadm';
import { BiSolidUserCheck } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import Mainhomeagency from '../Pages/Agencys/Login/Pages/Home/Mainhomeagency';
import CreateCamp from '../Pages/Agencys/Login/Pages/Campaigns/CreateCamp';
import ViewCamp from '../Pages/Agencys/Login/Pages/Campaigns/ViewCamp';
import Maincampaign from '../Pages/Agencys/Login/Pages/Campaigns/Maincampaign';
import Mainadvertiseragency from '../Pages/Agencys/Login/Pages/Advertisers/Mainadvertiseragency';
import Mainpublishersagency from '../Pages/Agencys/Login/Pages/Publishers/Mainpublishersagency';
import Mainemployeeagency from '../Pages/Agencys/Login/Pages/Employee/Mainemployeeagency';
import Mainaccountagency from '../Pages/Agencys/Login/Pages/Account/Mainaccountagency';
import Billinginfo from '../Pages/Agencys/Login/Pages/Finance/Billinginfo';
import Performancerepoagency from '../Pages/Agencys/Login/Pages/Reports/Performancerepoagency';
import Dailyrepoagency from '../Pages/Agencys/Login/Pages/Reports/Dailyrepoagency';
import Pubrepoagency from '../Pages/Agencys/Login/Pages/Reports/Pubrepoagency';
import Advrepoagency from '../Pages/Agencys/Login/Pages/Reports/Advrepoagency';
import Conversionrepoagency from '../Pages/Agencys/Login/Pages/Reports/Conversionrepoagency';
import Mainhome from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Home/Mainhome';
import Maincampaigns from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Campaigns/Maincampaigns';
import Viewcampaign from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Campaigns/Viewcampaign';
import Approvedcamppub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Campaigns/Approvedcamppub';
import Needapprovalcamppub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Campaigns/Needapprovalcamppub';
import Mainacountpub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Account/Mainacountpub';
import Mainpostbackpub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Account/Mainpostbackpub';
import Performancereportpub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Reports/Performancereportpub';
import Clickidreportspub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Reports/Clickidreportspub';
import Conversionreportspub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Reports/Conversionreportspub';
import MainInvoicepub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Invoices/MainInvoicepub';
import Createinvoicepub from '../Pages/Agencys/Login/Pages/LoginasPub/Pages/Invoices/Createinvoicepub';



const Mainroutepubloginagencyadm = () => {
  const token = localStorage.getItem('token')
  const { platformurl, pubid } = useParams()

  const [isvalid, setisvalid] = useState(false)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    token && checkvalidtoken()
  }, [])


  const checkvalidtoken = () => {
    gpeapi.get('/checktokenadm', {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then((res) => {
      if (res.data.success) {

      } else {
        localStorage.clear()
        window.location.reload('')
      }
    })
    // console.log('runnn')
  }




  const menus = [
    { name: "dashboard", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/dashboard`, icon: MdOutlineDashboard },
    {
      name: "campaigns", link: "/", icon: MdCampaign, submenu: [
        { name: "all campaigns", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/campaigns` },
        { name: "approved campaigns", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/approvedcamp` },
        { name: "need approval", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/needapproval` },
      ]
    },
    {
      name: "reports", link: "/", icon: FiMessageSquare, submenu: [
        { name: "performance report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/performancerepo` },
        { name: "daily report", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/dailyrepo` },
        { name: "conversion reports", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/conversionrepo` },
      ]
    },

    {
      name: "invoice", link: "/", icon: FaFileInvoiceDollar, submenu: [
        { name: "manage invoices", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/manageinvoice` },
        { name: "create invoice", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/createinvoice` },

      ]
    },

    {
      name: "account", link: "/", icon: CgProfile, submenu: [
        { name: "profile", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/account` },
        { name: "postback", link: `/offerborn/amd/admin/agencylogin/${platformurl}/loginaspub/${pubid}/postback` },

      ]
    },
    // { name: "analytics", link: "/", icon: TbReportAnalytics, margin: true },
    // { name: "File Manager", link: "/", icon: FiFolder },
    // { name: "Cart", link: "/", icon: FiShoppingCart },
    // { name: "Saved", link: "/", icon: AiOutlineHeart, margin: true },
    // { name: "Setting", link: "/", icon: RiSettings4Line },
  ];


  return (


    <>





      {token && <>
        <Mysidebar menus={menus} />
        <div style={{ marginTop: 60 }}>
          <Header />
        </div>
      </>}

      <Routes>

        {
          token ? (
            <>


              <Route path='/*' element={<Mainhome />} />
              <Route path='/dashboard' element={<Mainhome />} />



              <Route path='/campaigns' element={<Maincampaigns />} />
              <Route path='/viewcamp/:id' element={<Viewcampaign />} />
              <Route path='/approvedcamp' element={<Approvedcamppub />} />
              <Route path='/needapproval' element={<Needapprovalcamppub />} />
              <Route path='/account' element={<Mainacountpub />} />
              <Route path='/postback' element={<Mainpostbackpub />} />
              {/* reports */}
              <Route path='/performancerepo' element={<Performancereportpub />} />
              <Route path='/dailyrepo' element={<Clickidreportspub />} />
              <Route path='/conversionrepo' element={<Conversionreportspub />} />


              {/* invoice */}
              <Route path='/manageinvoice' element={<MainInvoicepub />} />
              <Route path='/createinvoice' element={<Createinvoicepub />} />


              {/* <Route path='/*' element={<Maindashboard />} />
              <Route path='/dashboard' element={<Maindashboard />} /> */}
              {/* <Route path='/setting' element={<Mainbasicdet />} />
              <Route path='/createdetails' element={<Addbasicdet />} />
              <Route path='/updatedetails/:id' element={<Addbasicdet />} />
              <Route path='/pricing' element={<Mainpricing />} />
              <Route path='/addpricing' element={<Addpricing />} />
              <Route path='/updatepricing/:id' element={<Addpricing />} />


              <Route path='/agency' element={<Mainagencyadm />} />
              <Route path='/addagency' element={<Addagencyadm />} />
              <Route path='/updateagency/:id' element={<Addagencyadm />} /> */}

            </>

          ) : (
            <>
              <Route path='/*' element={<Mainloginadmin />} />
              <Route path='/login' element={<Mainloginadmin />} />
            </>
          )
        }

        {/* <Route path='/home' element={<Mainhome/>}/> */}

        {/* <Route path='/register' element={<Mainregister/>}/> */}


      </Routes>


    </>








  )
}

export default Mainroutepubloginagencyadm