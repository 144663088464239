


import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import { COLORS } from '../../../Constants'

const Changepasswordemployee = ({setmodal,id}) => {

    const [loading,setloading] =useState(false)
    const token = localStorage.getItem('token')

    const { platformurl } = useParams()


    const [mydta,setmydta] = useState({
        oldpassword:'',
        newpassword:'',
        

     
    })

    

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleCreateAdv = async()=>{
        if(mydta.oldpassword!==''){
            if(mydta.newpassword!==''){
                setloading(true)

                const res = await gpeapi.post(`/${platformurl}/changepasswordemp`, mydta, {
                    headers: {
                        "Authorization": `Beaer ${token}`
                    }
                }).then((ress) => {
                    toast.success(ress.data.msg)
                   setmodal(false)
                    
                }).catch((e) => {
                    toast.error(e?.response?.data?.msg)

                })
                
                setloading(false)

            }else{
                toast.warning('Please Enter New Password')
            }

        }else{
            toast.warning('Please Enter Old Password')
        }
    }
  return (
   
    <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

    <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

        <div className="flex flex-col items-start justify-center m-4">
            <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                Change Password

            </p>


        </div>
        <div className="p-2 ">

           

            

        <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput type={'password'} width={'100%'} label={'Current Password'} required={true}  onChange={handleChnage} name={'oldpassword'} value={mydta.oldpassword} />
            </div>

            <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput type={'password'} width={'100%'} label={'New Password'} required={true}  onChange={handleChnage} name={'newpassword'} value={mydta.newpassword} />
            </div>

           





        </div>








        <Mybtn loading={loading} onClick={handleCreateAdv} title={'Change Password'} />
    </div>
</div>
  )
}

export default Changepasswordemployee