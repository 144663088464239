import React from 'react' 
import { Button, Spinner } from 'react-rainbow-components'
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from '../Constants';




const Mybtn = ({title,icon,onClick,width,bgcolor,color,loading,margin,classname,padding,border,radius,bordercolor,fontsize}) => {
  return (
    <div>
      <Button disabled={loading}  onClick={onClick} style={{margin:margin?margin:5, padding:padding?padding:'0px 40px', border:border?border:0, color: color,borderColor:bordercolor?bordercolor:bgcolor,  borderRadius:radius?radius: 50,fontWeight:'500', fontSize:fontsize?fontsize:16,width:width,backgroundColor:bgcolor?bgcolor:COLORS.primary }}  variant="brand" class="rainbow-m-around_medium" className={classname}>
      {icon && <FontAwesomeIcon style={{fontWeight:'bold',marginRight:8}} icon={icon} className="rainbow-m-left_medium" /> }
              { !loading && title}
            {loading && <ReactLoading height={40} className='myreactlod' color='white' width={20 } type='spin' />}
        </Button>

        

        
    </div>
  )
}

export default Mybtn