import { Spinner, Typography } from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Myselect from '../../../COmponents/Myselect'
import { Authcontext } from '../../../Context/Authcontext'
import { fethbasic } from '../../../Redux/Actions/basicaction'

const Addbasicdet = () => {
    const { toggle } = useContext(Authcontext)
const dispatch = useDispatch()
const navigate = useNavigate()
    const { id } = useParams()
    const token = localStorage.getItem('token')
    const [loading, setloading] = useState(false)
    const [dataloading, setdataloading] = useState(false)

    const [myid, setmyid] = useState(null)
    

    const [trackingparams, settrackingparams] = useState({
        label: '',
        value: '',
    })

    const [cammodel, setcammodel] = useState({
        label: '',
        value: '',
    })


    const [mydta, setmydta] = useState({
        appname: '',
        icon: '',
        appdes: '',
        appurl: '',
        serverurl: '',
        version: '',
        isactive: '',
        telegramurl: '',
        smsapi: '',
        tandc: '',
        plans: '',
        trackingparams: '',
        campaignmodel: '',
        maintenancemode: '',
        maintenancecomment: '',
        company_email:'',
        company_name:'',
        address:'',
        pincode:'',
        city:'',
        country:'',
        taxid:'',
        invoice_email:'',
        sac_code:'',
        privacy_policy:'',
        tandc:'',
        contact_email:'',
        contact_mobile:'',
        contact_skype:''

    })

    const ac_statusdta = [
        { label: 'True', value: true },
        { label: 'False', value: false },


    ]

    const basiddetdata = useSelector((state) => state.basicReducer)[0]

    useEffect(()=>{
        id && callforUpate()
    },[])

    const callforUpate = async()=>{
        setmydta(basiddetdata)
    }



    
    











    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }


    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handlecreateCamp = async () => {
        setloading(true)
        await gpeapi.post(`/createbasic`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                toast.success('Setting Created Successful')
                navigate('/offerborn/amd/admin/setting')
                dispatch(fethbasic())


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const handleupdateCamp = async () => {
        setloading(true)
        await gpeapi.put(`/updatebasic/${id}`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                toast.success('Setting Updated Successful')
                navigate('/offerborn/amd/admin/setting')

                dispatch(fethbasic())
            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    // console.log(mydta)

    const handleaddmodel = ()=>{
        if(cammodel.label!==''){
            if(cammodel.value!==''){
                setmydta({...mydta,campaignmodel:mydta.campaignmodel.length?[...mydta.campaignmodel,cammodel]:[cammodel]})

                setcammodel({label:'',value:''})
            }else{
                toast.warning('Please Enter Model Value')
            }
        }else{
            toast.warning('Please Enter Model Label')
        }
    }

    const handleaddparams = ()=>{
        if(trackingparams.label!==''){
            if(trackingparams.value!==''){
                setmydta({...mydta,trackingparams:mydta.trackingparams.length?[...mydta.trackingparams,trackingparams]:[trackingparams]})

                settrackingparams({label:'',value:''})
            }else{
                toast.warning('Please Enter Tracking Value')
            }
        }else{
            toast.warning('Please Enter Tracking Label')
        }
    }
    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />


            <p className='text-2xl font-medium'> {id ? 'UPDATE' : 'CREATE'} DETAILS</p>





            {dataloading ? <div className='flex justify-center items-center'>
                <Spinner className="h-10 w-10  text-blue-500/10" />
            </div>
                :
                <div className='bg-white rounded-md p-2 mt-3'>
                    <p>Details</p>



                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>App Name <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.appname} name='appname' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>App Icon <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.icon} name='icon' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Description (Optional) </p>

                        <Myinput line={8} multiline={true} width={'200%'} onChange={handleChnage} value={mydta.appdes} name='appdes' />

                    </div>
                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>App URL <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.appurl} name='appurl' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Server URL <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.serverurl} name='serverurl' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Version <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.version} name='version' />


                    </div>








                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>isactive</p>
                        <Myselect value={mydta?.isactive} data={ac_statusdta} width={'200%'} placeholder={'Select isactive'} name={'isactive'} onChange={(e) => handleChnageSelect(e, 'isactive')} />
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>maintenancemode</p>
                        <Myselect value={mydta?.maintenancemode} data={ac_statusdta} width={'200%'} placeholder={'Select maintenancemode'} name={'maintenancemode'} onChange={(e) => handleChnageSelect(e, 'maintenancemode')} />
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Maintenance Comment</p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.maintenancecomment} name='maintenancecomment' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Telegram URL</p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.telegramurl} name='telegramurl' />

                    </div>



                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Terms & Condition</p>

                        <Myinput multiline={true} line={4} width={'200%'} onChange={handleChnage} value={mydta.tandc} name='tandc' />

                    </div>



                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Company Name</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.company_name} name='company_name' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Company Email</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.company_email} name='company_email' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Invoice Email</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.invoice_email} name='invoice_email' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Tax Id GST</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.taxid} name='taxid' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>SAC Code</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.sac_code} name='sac_code' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Pin Code</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.pincode} name='pincode' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>City</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.city} name='city' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Country</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.country} name='country' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Address</p>

                        <Myinput multiline={true} line={4} width={'200%'} onChange={handleChnage} value={mydta.address} name='address' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Terms and Condition</p>

                        <Myinput multiline={true} line={7} width={'200%'} onChange={handleChnage} value={mydta.tandc} name='tandc' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Privacy Policy</p>

                        <Myinput multiline={true} line={7} width={'200%'} onChange={handleChnage} value={mydta.privacy_policy} name='privacy_policy' />

                    </div>


                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Contact Email</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.contact_email} name='contact_email' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Contact Mobile</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.contact_mobile} name='contact_mobile' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Contact Skype</p>

                        <Myinput  width={'200%'} onChange={handleChnage} value={mydta.contact_skype} name='contact_skype' />

                    </div>












                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Tracking Params</p>

                        <div className='col-span-2'>
                        <div>
                           {
                                mydta?.trackingparams.length && mydta?.trackingparams?.map((val,i)=>(
                                    <div className='flex flex-wrap gap-2'>
                                        <Typography >
                                           Label : {val?.label} |
                                        </Typography>


                                        <Typography >
                                            Value : {val?.value}
                                        </Typography>
                                    </div>
                                ))
                            }
                           </div>
                        

                        <div className='flex flex-wrap col-span-2 justify-between items-center'>
                            <Myinput placeholder={'Enter Label'} width={'30%'} onChange={(e) => settrackingparams({...trackingparams, label: e.target.value })} value={trackingparams.label} name='label' />

                            <Myinput placeholder={'Enter Value'} width={'30%'} onChange={(e) => settrackingparams({...trackingparams, value: e.target.value })} value={trackingparams.value} name='value' />

                            <Mybtn title={'Add Params'} onClick={handleaddparams} fontsize={12} radius={10}   />
                        </div>

                        </div>



                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Campaign Models</p>

                        <div className='col-span-2'>

                           <div>
                           {
                                mydta?.campaignmodel.length && mydta?.campaignmodel?.map((val,i)=>(
                                    <div className='flex flex-wrap gap-2'>
                                        <Typography >
                                           Label : {val?.label} |
                                        </Typography>


                                        <Typography >
                                            Value : {val?.value}
                                        </Typography>
                                    </div>
                                ))
                            }
                           </div>

                        <div className='flex flex-wrap col-span-2 justify-between items-center'>
                            <Myinput placeholder={'Enter Label'} width={'30%'} onChange={(e) => setcammodel({...cammodel, label: e.target.value })} value={cammodel.label} name='label' />

                            <Myinput placeholder={'Enter Value'} width={'30%'} onChange={(e) => setcammodel({...cammodel, value: e.target.value })} value={cammodel.value} name='value' />

                            <Mybtn title={'Add Models'} onClick={handleaddmodel} fontsize={12} radius={10}   />
                        </div>
                        </div>




                    </div>











                    {/* <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Goals </p>

                        <div style={{ width: '350%' }}>

                            {mydta?.goals?.length &&

                                <CardBody className="overflow-scroll px-0">
                                    <table className="w-full min-w-max table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head) => (
                                                    <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal leading-none opacity-70"
                                                        >
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mydta?.goals?.length && mydta?.goals.map(
                                                (val, index) => {
                                                    const isLast = index === mydta?.goals?.length - 1;
                                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={index}>
                                                            <td className={classes}>


                                                                <p className="text-xs">
                                                                    {val?.id}
                                                                </p>

                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {val?.title}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {val?.value}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="green" className="font-normal">
                                                                    {val?.rev}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue" className="font-normal">
                                                                    {val?.po}

                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Tooltip content="Edit">
                                                                    <IconButton onClick={() => onEditUser(val.id, index)} variant="text" color="blue-gray">
                                                                        <PencilIcon className="h-4 w-4" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip content="Delete">
                                                                    <IconButton onClick={() => onDeleteUser(val.id)} variant="text" color="blue-gray">
                                                                        <TrashIcon className="h-4 w-4" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                            }




                            <Mybtn title={'Add Goals'} fontsize={12} radius={10} padding={'1px 10px'} onClick={() => setopengaolmodal(true)} />
                            {id && <p className='text-xs text-gray-400'>* After Goal Updated Please Click Update Campaign Button also</p>}
                        </div>







                    </div> */}






                    {
                        id ? <Mybtn loading={loading} onClick={handleupdateCamp} title={'Update '} />
                            : <Mybtn loading={loading} onClick={handlecreateCamp} title={'Create '} />
                    }







                </div>
            }
        </div>
    )
}

export default Addbasicdet