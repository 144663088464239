



import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import { COLORS } from '../../../Constants'

const Editprofileemployee = ({ setmodal, id }) => {

    const [loading, setloading] = useState(false)
    const token = localStorage.getItem('token')

    const { platformurl } = useParams()

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))



    const [mydta, setmydta] = useState({
        name: '',
        skype: '',
        country: '',
        address: '',
        



    })

    useEffect(() => {
        setmydta(userinfo)
    }, [])



    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleCreateAdv = async () => {
        if (mydta.name !== '') {
            if (mydta.address !== '') {
                setloading(true)

                const res = await gpeapi.put(`/${platformurl}/empupdate`, mydta, {
                    headers: {
                        "Authorization": `Beaer ${token}`
                    }
                }).then((ress) => {
                    toast.success(ress.data.msg)
                    // localStorage.setItem('userInfo', JSON.stringify(res.data.suc));

                    window.location.reload()
                    setmodal(false)

                }).catch((e) => {
                    toast.error(e?.response?.data?.msg)

                })

                setloading(false)

            } else {
                toast.warning('Please Enter Address')
            }

        } else {
            toast.warning('Please Enter Name')
        }
    }
    return (

        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        Edit Profile

                    </p>


                </div>
                <div className="p-2 ">





                    <Myinput type={'text'} width={'100%'} label={'Name'} onChange={handleChnage} name={'name'} value={mydta.name} />









                    <Myinput type={'text'} width={'100%'} label={'Skype ID'} onChange={handleChnage} name={'skype'} value={mydta.skype} />


                    <Myinput type={'text'} width={'100%'} label={'Address'} onChange={handleChnage} name={'address'} value={mydta.address} />



                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                        <Myinput type={'text'} width={'100%'} label={'Country'} onChange={handleChnage} name={'country'} value={mydta.country} />
                    </div>











                </div>








                <Mybtn loading={loading} onClick={handleCreateAdv} title={'Update Profile'} />
            </div>
        </div>
    )
}

export default Editprofileemployee