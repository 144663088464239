
import { Option, Select } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { CheckboxToggle } from 'react-rainbow-components';

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Myselect from '../../../COmponents/Myselect'
import NewMySelect from '../../../COmponents/NewMySelect'
import { COLORS } from '../../../Constants'


const Addmessageadmemployee = ({ setmodal, id }) => {

    const token = localStorage.getItem('token')

    const DataTable = [
        "Module Permission", 'Read', "Update", "Create", "Delete"
    ]

    const dispatch = useDispatch()
    const { platformurl } = useParams()
    const [loading, setloading] = useState(false)
    const ac_manag = [
        { label: 'Manish Singh', value: `12- Manish Singh` },
        { label: 'Rahul Singh', value: `15- Rahul Singh` },
    ]

     const jobrole_data = [
        { label: 'TRUE', value: true },
        { label: 'FALSE', value: false },
        
    ]

    const ac_statusdta = [
        { label: 'Connect', value: 'connect' },
        { label: 'Pending', value: 'pending' },
        { label: 'Done', value: 'done' },
        
    ]

    const [mydta, setmydta] = useState({
        name: '',
        email: '',
        message: '',
        mobile: '',
        is_connected: '',
        note: '',
        msg_status: '',
      
        

    })

    useEffect(() => {
        id && updateForm()
    }, [id])

    // console.log(mydta)

    const updateForm = async () => {
        const res = await gpeapi.get(`/getmessagebyidaemp/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress.data?.user)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))

    }

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }

    const handleUpdate = async () => {
        setloading(true)
        await gpeapi.put(`/updatemessageaemp/${id}`, mydta,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res) => {
            if (res.data.success) {
                toast.success('Message Deatils Updated')
                setmodal(false)
                


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

   

    const handleCreateAdv = async () => {

       

    }

    // console.log(mydta)
    return (


        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        {id ? 'Update' : 'Add'} an Employee

                    </p>
                    <p>Deatils</p>

                </div>


                <div className="p-6 ">




                    <Myinput type={'text'} label={'Full Name'} width={'100%'} name={'name'} value={mydta.name} onChange={handleChnage} required={true} placeholder={'The name of your Employee'} />

                    <Myinput type={'email'} label={'Email ID'} width={'100%'} name={'email'} value={mydta.email} onChange={handleChnage} required={true} placeholder={'Enter Unique email of your Employee'} />

                    <Myinput label={'Mobile Number'} width={'100%'} name={'mobile'} value={mydta.mobile} onChange={handleChnage} required={true} placeholder={'Enter Mobile Number of your Employee'} />

                    <Myinput label={'Message'} type={'text'} multiline={true} width={'100%'} name={'message'} value={mydta.message} onChange={handleChnage} required={true} placeholder={'Enter Message '} />

                   

                    <Myselect value={mydta.msg_status} data={ac_statusdta} label={'Message Status'} width={'100%'} placeholder={'Select Account Status'} name={'msg_status'} onChange={(e) => handleChnageSelect(e, 'msg_status')} />

                    {/* <Myselect value={mydta?.ac_manager} data={ac_manag} label={'Account Manager'} width={'100%'} name={'ac_manager'} placeholder='Select Account Manager' onChange={(e) => handleChnageSelect(e, 'ac_manager')} /> */}

                    <Myselect value={mydta?.is_connected} data={jobrole_data} label={'Is Connected'} width={'100%'} name={'is_connected'} placeholder='Select Is Conected' onChange={(e) => handleChnageSelect(e, 'is_connected')} />


                    

                    

                   

                    

                    <Myinput multiline={true} tips={'The content will not be displayed to Message user'} label={'Notes'} width={'100%'} name={'note'} value={mydta.note} onChange={handleChnage} />





                </div>

                

                {
                    id ? <Mybtn loading={loading} onClick={handleUpdate} title={'Update Message'} />
                        : <Mybtn loading={loading} onClick={handleCreateAdv} title={'Create Message'} />
                }
            </div>
        </div>



    )
}

export default Addmessageadmemployee