import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'
import Cardview from '../Components/Cardview'

const Services = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]
    document.title = `Services - OfferBorn`

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])


  return (
    <section style={{ marginTop: 50 }} class="section contact" id="contact" aria-label="contact">
    <div class="container">

        <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Services</h2>

        <p class="section-text">

        </p>


        

        <section className='mt-20'>

            {/* <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
            <p class="section-text" style={{ color: COLORS.primary }}>

                We work with brands and publishers around the clock
            </p> */}

            <div className='flex p-3 shadow-lg flex-col  justify-around items-center bg-white rounded-lg sm:flex-row md:flex-row xl:flex-row 2xl:flex-row  flex-wrap'>

                <Cardview img={'flame-outline'} text={'Postback Management'} subtext={' informed about user engagement behavior for better optimization'}/>
                <Cardview img={'analytics-outline'} text={'Reports Management'} subtext={'You can export any date like Reports from any format like PDF,CSV'}/>
                <Cardview img={'planet-outline'} text={'Feature Rich API'} subtext={'Draw data from multiple third-party softwares onto your dashboard'}/>
                <Cardview img={'planet-outline'} text={'Junk & Bots Traffic Controller'} subtext={' We have facility to filter junk and bots traffic. and stop the traffic .....'}/>
                <Cardview img={'planet-outline'} text={'Real Time Reports'} subtext={'Real-time reporting helps you to monitor performance of your camp'}/>
                <Cardview img={'planet-outline'} text={'Manage Publishers Invoice'} subtext={'your publisher can create invoice with 1 click and manage by Month '}/>

                

               



            </div>


        </section>

        






    </div>
</section>
  )
}

export default Services