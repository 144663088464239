import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'
import CountUp from 'react-countup';

const Aboutus = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    document.title = `About Us - OfferBorn`

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <section style={{ marginTop: 50 }} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>About Us</h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src="https://blog.vantagecircle.com/content/images/2020/08/teamwork-and-team-building.png" width="720" height="673" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>About OfferBorn</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                OfferBorn was founded in 2023 with the goal to simplify partnerships for brands, agencies, and publishers alike.
                                <br />
                                <br />
                                The idea was born from our team’s own need: a platform that can handle large data loads, is user-friendly, and offers competitive pricing suitable to the market. OfferBorn was born in the cloud, a SaaS platform from the start, built by a passionate team with real-world experience in the web and mobile marketing sectors

                                <br />
                                <br />
                                At the time, our team didn't know they were building a platform that would eventually disrupt the traditional vendors—moving from Cost Per Mille (CPM) to Cost Per Action (CPA). This led to OfferBorn growing into a trusted platform where influencers, publishers, and B2B partners come together to help brands reach their consumers in a more impactful way.

                            </p>



                        </div>



                    </div>
                </section>

                <section className='mt-20'>

                    {/* <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p> */}

                    <div style={{backgroundColor:'#045385'}} className='flex p-3 shadow-lg flex-col  justify-around items-center bg-white rounded-lg sm:flex-row md:flex-row xl:flex-row 2xl:flex-row  '>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <CountUp
                                start={0}
                                end={100}
                                duration={2.75}
                                separator=" "
                                style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
                                suffix="+"
                            />

                            <p className='text-xl text-white'>Global Clients</p>
                        </div>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <CountUp
                                start={0}
                                end={4}
                                duration={2.75}
                                separator=" "
                                style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
                                suffix="+"
                            />

                            <p className='text-xl text-white'>Years Experience</p>
                        </div>

                        <div className='flex flex-col justify-center p-5 items-center'>
                            <CountUp
                                start={0}
                                end={97}
                                duration={2.75}
                                separator=" "
                                style={{ fontSize: 50, color: 'white', fontWeight: 'bold' }}
                                suffix="%"
                            />

                            <p className='text-xl text-white'>Positive Feedbacks</p>
                        </div>



                    </div>


                </section>

                <section className='mt-20'>

                    <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Our Office</h2>
                    <p class="section-text" style={{ color: COLORS.primary }}>

                        We work with brands and publishers around the clock
                    </p>

                    <div className='flex justify-center items-center'>
                        <div class="service-card" style={{ border: `1px solid ${COLORS.primary}`, borderRadius: 25, width: '40%' }}>

                            <img style={{ height: 40, width: 40, margin: 'auto' }} src='https://affise.com/wp-content/uploads/2022/05/india.png' />



                            <h3 class="h3">
                                <a href="#" class="text-lg">India</a>
                            </h3>

                            <p class="text-base">
                                {basicData?.address} {basicData?.pincode}
                            </p>
                            <p class="text-base">
                                {basicData?.contact_mobile}
                            </p>


                        </div>
                    </div>


                </section>






            </div>
        </section>
    )
}

export default Aboutus