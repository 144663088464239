import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-rainbow-components';

const Mymodal = ({open,Components,setopenmodal,width,handleoutopen}) => {

const [isOpen,setisOpen] = useState(false)

const handleOnClose = ()=>{
    setisOpen(false)
    setopenmodal(false)
    handleoutopen && handleoutopen()
}

useEffect(()=>{
    setisOpen(open)

},[open])


    return (
        <div >
            
            <Modal style={{width:width?width:600}} id="modal-1" isOpen={isOpen} onRequestClose={handleOnClose}>
              {Components}
            </Modal>
        </div>
    )
}

export default Mymodal