

import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import Header from '../../COmponents/Header';


import { HiMenuAlt3 } from "react-icons/hi";
import { MdCampaign, MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { TbFriends, TbReportAnalytics } from "react-icons/tb";
import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FiMessageSquare, FiFolder, FiShoppingCart, FiRadio } from "react-icons/fi";
import Mysidebar from '../../COmponents/Mysidebar';
import gpeapi from '../../apis/gpeapi';
import Page404Found from '../../COmponents/Page404Found';

import { CgProfile } from "react-icons/cg";

import { GrAnnounce } from 'react-icons/gr';
import { Spinner } from 'react-rainbow-components';
import Mainhomeemployee from '../Pages/Home/Mainhomeemployee';
import Mainloginemployee from '../Pages/Login/Mainloginemployee';
import Mainregisteremployee from '../Pages/Register/Mainregisteremployee';
import Mainforgotpasswordemployee from '../Pages/Login/Mainforgotpasswordemployee';
import Mainaccountemployee from '../Pages/Account/Mainaccountemployee';
import { IoAnalyticsSharp } from 'react-icons/io5';
import { FaHandsHelping } from 'react-icons/fa';
import { BiSolidUserCheck } from 'react-icons/bi';
import CreateCamp from '../Pages/Campaigns/CreateCamp';
import ViewCamp from '../Pages/Campaigns/ViewCamp';
import Maincampaign from '../Pages/Campaigns/Maincampaign';
import Mainpublishersagency from '../Pages/Publishers/Mainpublishersagency';
import Mainadvertiseremployee from '../Pages/Advertiser/Mainadvertiseremployee';
import Mainemployeebyemployee from '../Pages/Employee/Mainemployeebyemployee';
import Performancerepoagency from '../Pages/Reports/Performancerepoagency';
import Dailyrepoagency from '../Pages/Reports/Dailyrepoagency';
import Pubrepoagency from '../Pages/Reports/Pubrepoagency';
import Advrepoagency from '../Pages/Reports/Advrepoagency';
import Conversionrepoagency from '../Pages/Reports/Conversionrepoagency';


const Mainrouteemployee = () => {

    const token = localStorage.getItem('token')
    const [isvalid, setisvalid] = useState(null)
    const [loading, setloading] = useState(false)

    const { platformurl } = useParams()

    useEffect(() => {
        callForCheckPlatform()
        token && checkvalidtoken()


    }, [])

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))



    const callForCheckPlatform = async () => {
        setloading(true)
        await gpeapi.get(`/${platformurl}/checkagencyplatform`).then((res) => setisvalid(res.data.success)).catch((e) => setisvalid(e?.response?.data?.success))
        setloading(false)
    }

    const checkvalidtoken = () => {
        gpeapi.get(`/${platformurl}/checkemptoken`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then(async (res) => {
            if (res.data.success) {
                await localStorage.setItem('userInfo', JSON.stringify(res.data.user));

            } else {
                localStorage.clear()
                window.location.reload('')
            }
        })
        // console.log('runnn')
    }


    const testwithadd = (name) => {
        const datata = userinfo?.accesspermision?.filter((val) => val?.name == name)[0]
        return datata
    }




    const menus = [
        { name: "dashboard", link: `/p/${platformurl}/employee/dashboard`, icon: MdOutlineDashboard },
        testwithadd('Campaigns')?.read ? {
            name: "campaigns", link: "/", icon: MdCampaign, submenu: [
                testwithadd('Campaigns')?.read && { name: "all campaigns", link: `/p/${platformurl}/employee/campaigns` },
                testwithadd('Campaigns')?.create && { name: "create campaigns", link: `/p/${platformurl}/employee/createcamp` },

            ]
        } : null,
        testwithadd('Reports')?.read ? {
            name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
                testwithadd('Reports')?.read && { name: "campaigns report", link: `/p/${platformurl}/employee/performancerepo` },
                testwithadd('Reports')?.read && { name: "daily report", link: `/p/${platformurl}/employee/dailyreport` },
                testwithadd('Reports')?.read && { name: "publishers report", link: `/p/${platformurl}/employee/publisherreport` },
                testwithadd('Reports')?.read && { name: "advertisers report", link: `/p/${platformurl}/employee/advertiserreport` },
                testwithadd('Reports')?.read && { name: "conversion report", link: `/p/${platformurl}/employee/conversionreport` },
                // { name: "clicks report", link: "/" },
                // { name: "sub-id reports", link: "/" },
            ]
        } : null,
        testwithadd('Publisher')?.read ? {
            name: "publishers", link: "/", icon: TbFriends, submenu: [
                testwithadd('Publisher')?.read && { name: "all publishers", link: `/p/${platformurl}/employee/publishers` },


            ]
        } : null,
        testwithadd('Advertiser')?.read ? {
            name: "advertisers", link: "/", icon: FaHandsHelping, submenu: [
                testwithadd('Advertiser')?.read && { name: "all advertisers", link: `/p/${platformurl}/employee/advertisers` },


            ]
        } : null,

        testwithadd('Employee')?.read ? {
            name: "employees", link: "/", icon: BiSolidUserCheck, submenu: [
                testwithadd('Employee')?.read && { name: "all employees", link: `/p/${platformurl}/employee/employees` },


            ]
        } : null,
        { name: "account", link: `/p/${platformurl}/employee/account`, icon: CgProfile },



        // { name: "analytics", link: "/", icon: TbReportAnalytics, margin: true },
        // { name: "File Manager", link: "/", icon: FiFolder },
        // { name: "Cart", link: "/", icon: FiShoppingCart },
        // { name: "Saved", link: "/", icon: AiOutlineHeart, margin: true },
        // { name: "Setting", link: "/", icon: RiSettings4Line },
    ].filter(x => x);


    // console.log(menus)






    return (
        isvalid == null ? <Spinner style={{ display: 'none' }} /> : isvalid ?

            <>

                {
                    token && <>
                        <Mysidebar menus={menus} />
                        <div style={{ marginTop: 60 }}>
                            <Header />

                        </div></>
                }


                <Routes>

                    {
                        token ? (
                            <>
                                <Route path='/*' element={<Mainhomeemployee />} />
                                <Route path='/dashboard' element={<Mainhomeemployee />} />
                                {/* <Route path='/campaigns' element={<Maincampaigns />} /> */}
                                {/* <Route path='/viewcamp/:id' element={<Viewcampaign />} /> */}
                                <Route path='/account' element={<Mainaccountemployee />} />




                                <Route path='/createcamp' element={<CreateCamp />} />
                                <Route path='/updatecamp/:id' element={<CreateCamp />} />
                                <Route path='/viewcamp/:id' element={<ViewCamp />} />
                                <Route path='/advertisers' element={<Mainadvertiseremployee />} />
                                <Route path='/publishers' element={<Mainpublishersagency />} />
                                <Route path='/campaigns' element={<Maincampaign />} />
                                <Route path='/employees' element={<Mainemployeebyemployee />} />



                                {
                                    testwithadd('Reports')?.read &&
                                    <>
                                        <Route path='/performancerepo' element={<Performancerepoagency />} />
                                        <Route path='/dailyreport' element={<Dailyrepoagency />} />
                                        <Route path='/conversionreport' element={<Conversionrepoagency />} />
                                        <Route path='/publisherreport' element={<Pubrepoagency />} />
                                        <Route path='/advertiserreport' element={<Advrepoagency />} />
                                    </>

                                }



                            </>
                        ) : (
                            <>
                                <Route path='/*' element={<Mainloginemployee />} />
                                <Route path='/login' element={<Mainloginemployee />} />
                                {/* <Route path='/register' element={<Mainregisteremployee />} /> */}
                                <Route path='/forgot-password' element={<Mainforgotpasswordemployee />} />
                            </>
                        )
                    }









                </Routes>


            </> :
            <Page404Found />







    )
}

export default Mainrouteemployee