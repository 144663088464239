import React, { useContext, useState } from 'react'
import Mycard from '../../../COmponents/Mycard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Authcontext } from '../../../Context/Authcontext'
import { IoMdArrowRoundBack } from 'react-icons/io'
import Myinput from '../../../COmponents/Myinput'
import { RiSendPlane2Fill } from 'react-icons/ri'
import { Spinner } from '@material-tailwind/react'
import { ToastContainer, toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import { useEffect } from 'react'

const Chatscreenagency = ({ data }) => {

    const { state } = useLocation()
    const { platformurl } = useParams()
    const [loading, setloading] = useState(false)
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const { toggle } = useContext(Authcontext)

    let pubdata = state.pubid

    const [msg, setmsg] = useState('')
    const [msgloading, setmsgloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(30)

    useEffect(() => {
        callforAPI()
      }, [limit])



    const callforAPI = async (show) => {

        
        show==true? setmsgloading(false): setmsgloading(true)
        const res = await gpeapi.get(`/${platformurl}/agency/getmessage?limit=${limit}&pubid=${pubdata?.id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((ress) => {
    
          setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
          toast.error(e?.response?.data?.msg)
          
    
        })
        setmsgloading(false)
    
      }



    const handlenavigate = (val) => {
        navigate(`/p/${platformurl}/agency/chats`)

    }

    const msgsent = async () => {

        const mydta = {
            pubid: pubdata?.id,
            message: msg,

        }

        if (msg !== '') {
            setloading(true)
            await gpeapi.post(`/${platformurl}/agency/createchat`, mydta,{headers:{
                "Authorization":`Beaer ${token}`
            }}).then((res) => {
                if (res.data.success) {
                    setmsg('')
                    callforAPI(true)
                    // toast.success('Employee Account Created Successful')

                }

            }).catch((e) => {
                toast.error(e?.response?.data?.msg)

            })
            setloading(false)
        }

    }


    // console.log(statsdata)
    // console.log(pubdata)

    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />


            <div style={{ height: '90vh', position: 'relative' }} className={`rounded-md shadow-md  m-2  sm:w-full md:w-[95%] bg-[#efeae2] `}>

                <div  >


                    <div className='flex items-center p-5 bg-[#412e78] text-white'>

                        <div onClick={handlenavigate} className='ml-2 cursor-pointer'>{React.createElement(IoMdArrowRoundBack, { size: "25" })}</div>

                        <div className='ml-5'>
                            <p className='text-lg font-bold capitalize'>{pubdata?.name}</p>
                            <p className='text-xs capitalize'>{pubdata?.company}</p>
                        </div>



                    </div>

                    <div style={{overflow:'auto',maxHeight:'65vh'}} className='p-5'>

                        {
                           msgloading? <Spinner/> : statsdata && statsdata?.docs?.map((val,i)=>(
                                <div style={{display:'flex',justifyContent: val?.sentby=='publisher'?'flex-start':'flex-end'}} className='m-2 mb-3'>
                                    <p style={{width:'max-content',maxWidth:'80%',wordWrap:'warp',}} className={`${val?.sentby=='publisher'? 'bg-white text-black' :'bg-[#412e78] text-white'}   p-2 pl-4 pr-4 rounded-md`}>{val?.message}</p>
                                </div>
                            ))
                        }
                        
                    </div>

                    <div style={{ position: 'absolute', bottom: 10, left: 0, width: '100%' }} className='flex items-center p-5'>
                        <input value={msg} onChange={(e) => setmsg(e.target.value)} style={{ borderRadius: 50 }} type={'text'} placeholder={'Message'} class="bg-white rounded-full border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" />

                        <div onClick={msgsent} className='ml-2 p-2 rounded-full cursor-pointer text-white bg-[#412e78]'> {loading ? <Spinner /> : React.createElement(RiSendPlane2Fill, { size: "25" })}</div>

                    </div>


                    {/* <Mybtn title={'NEW CHAT'} onClick={()=>setnewchatmodel(true)}/> */}




                </div>
            </div>

        </div>




    )
}

export default Chatscreenagency