import { SET_AGENCYADV, SET_AGENCYPUB, SET_PUBCAMP } from "../../actiontypes/usertypes";


const pubcampaignReducer = (state=[],action)=>{
    switch (action.type) {
        case SET_PUBCAMP:
            return [...action.payload]
            break;

           
    
        default:
           return state;
    }
}

export default pubcampaignReducer