import React, { useEffect, useState } from 'react'
import Myinput from '../../../COmponents/Myinput'
import { CheckboxGroup } from 'react-rainbow-components';
import { Checkbox, Spinner } from '@material-tailwind/react';
import Mybtn from '../../../COmponents/Mybtn';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchpublisher } from '../../../Redux/Actions/Agency/publisheragencyaction';
import Myselect from '../../../COmponents/Myselect';
import gpeapi from '../../../apis/gpeapi';
import { toast } from 'react-toastify';

const Filtercompo = ({ setmdata, sdata, handlereste }) => {
    const token = localStorage.getItem('token')

    const [mydta, setmydta] = useState(sdata)
    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const [advloading, setadvloading] = useState(false)
    const [adverr,setadverr] = useState(false)
    const [pubdatata,setpubdatata] = useState()


    useEffect(() => {
        callforReducer()

    }, [])

    const [advertidata,setadvertidata] = useState([])

    const callforReducer = async () => {
        setadvloading(true)
        const res = await gpeapi.get(`/${platformurl}/employee/getpublishers`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setpubdatata(ress.data?.TotalUsers?.docs)
            let tempdta = []
            ress?.data?.TotalUsers?.docs?.map((val) => tempdta.push({ label: `${val.name} (${val.company})`, value: val.id }))
        setadvertidata(tempdta)
        
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) =>{
            toast.error(e?.response.data.msg)
            setadverr(true)
        })

        
        setadvloading(false)
    }
    // useEffect(()=>{
    //     setmydta(sdata)
    // },[])



    useEffect(() => {
        setmdata(mydta)
    }, [mydta])

   





    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }



    return (
        <div>

            <div>



                <Myinput type={'text'} label={'Click ID'} width={'100%'} name={'clickid'} value={mydta.clickid} onChange={handleChnage} placeholder={'CLICK ID'} />


                <div className='grid grid-cols-4  gap-4   pb-2 pt-2'>
                    {advloading ? <Spinner /> :
                        <div className='text-sm col-span-4 text-blue-400'>



                            <Myselect tips={'Select Publisher for Specfic Report'} value={mydta?.pubid} data={advertidata} width={'100%'} placeholder={'Select Publisher'} onChange={(e) => {
                                // console.log(e)
                                setmydta({ ...mydta, pubid: e })
                               
                            }} />
                        </div>
                    }
                </div>




                <Myinput type={'text'} label={'Search'} width={'100%'} name={'conversionid'} value={mydta.conversionid} onChange={handleChnage} placeholder={'Search-  P1,P2,P3,P4,GAID'} />



                <div>
                    <Checkbox checked={mydta.p1} onChange={(e) => setmydta({ ...mydta, p1: !mydta.p1 })} value={mydta.p1} label='P1' />
                    <Checkbox checked={mydta.p2} onChange={(e) => setmydta({ ...mydta, p2: !mydta.p2 })} value={mydta.p2} label='P2' />
                    <Checkbox checked={mydta.p3} onChange={(e) => setmydta({ ...mydta, p3: !mydta.p3 })} value={mydta.p3} label='P3' />
                    <Checkbox checked={mydta.p4} onChange={(e) => setmydta({ ...mydta, p4: !mydta.p4 })} value={mydta.p4} label='P4' />
                    <Checkbox checked={mydta.gaid} onChange={(e) => setmydta({ ...mydta, gaid: !mydta.gaid })} value={mydta.gaid} label='GAID' />

                    <Checkbox checked={mydta.sub1} onChange={(e) => setmydta({ ...mydta, sub1: !mydta.sub1 })} value={mydta.sub1} label='SUB1' />
                    <Checkbox checked={mydta.sub2} onChange={(e) => setmydta({ ...mydta, sub2: !mydta.sub2 })} value={mydta.sub2} label='SUB2' />
                    <Checkbox checked={mydta.sub3} onChange={(e) => setmydta({ ...mydta, sub3: !mydta.sub3 })} value={mydta.sub3} label='SUB3' />
                    <Checkbox checked={mydta.sub4} onChange={(e) => setmydta({ ...mydta, sub4: !mydta.sub4 })} value={mydta.sub4} label='SUB4' />

                    <Checkbox checked={mydta.ip} onChange={(e) => setmydta({ ...mydta, ip: !mydta.ip })} value={mydta.ip} label='IP' />

                    <Checkbox checked={mydta.agent} onChange={(e) => setmydta({ ...mydta, agent: !mydta.agent })} value={mydta.agent} label='User Agent' />
                    <Checkbox checked={mydta.browser} onChange={(e) => setmydta({ ...mydta, browser: !mydta.browser })} value={mydta.browser} label='Browser' />
                    <Checkbox checked={mydta.note} onChange={(e) => setmydta({ ...mydta, note: !mydta.note })} value={mydta.note} label='Note' />

                    <Mybtn title={'Reset'} onClick={handlereste} />

                </div>


            </div>


        </div>
    )
}

export default Filtercompo