import { combineReducers } from "redux";
import advertiserAgencyReducer from "./Agency/advertiseragencyreducer";
import campaignAgencyReducer from "./Agency/campaignagencyreducer";
import publisherAgencyReducer from "./Agency/publisheragencyreducer";
import basicReducer from "./basicreducer";
import pricingReducer from "./pricingreducer";
import pubcampaignReducer from "./Pub/campiagnpubreducer";

const rootReducer = combineReducers({
    basicReducer,advertiserAgencyReducer,publisherAgencyReducer,campaignAgencyReducer,pricingReducer,pubcampaignReducer
})
export default rootReducer