import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import { COLORS } from '../../../Constants'

const Addbankpub = ({setmodal,id}) => {

    const [loading,setloading] =useState(false)
    const token = localStorage.getItem('token')
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const { platformurl } = useParams()


    const [mydta,setmydta] = useState({
        bankname:'',
        banknumber:'',
        bankifsc:'',
        bankholdername:'',
        bankbranch:''

     
    })

    useEffect(()=>{
        if(userInfo?.payment_method){
          
            setmydta(userInfo?.payment_method)
        }
    },[])

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleCreateAdv = async()=>{
        if(mydta.banknumber!==''){
            if(mydta.bankholdername!==''){
                if(mydta.bankname!==''){
                    if(mydta.bankifsc!==''){
                        setloading(true)

                        const res = await gpeapi.put(`/${platformurl}/pubupdate`, { payment_method: mydta }, {
                            headers: {
                                "Authorization": `Beaer ${token}`
                            }
                        }).then((ress) => {
                            toast.success('Bank Added Successfull')
                           setmodal(false)
                            
                        }).catch((e) => {
                            toast.error('Bank Not Added Please Try Again')

                        })
                        setloading(false)

                    }else{
                        toast.warning('Please Enter Bank account IFSC Code')
                    }

                }else{
                    toast.warning('Please Enter Bank Name')
                }

            }else{
                toast.warning('Please Enter Account Owner Name')
            }

        }else{
            toast.warning('Please Enter Bank Account Number')
        }
    }
  return (
   
    <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

    <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

        <div className="flex flex-col items-start justify-center m-4">
            <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                Bank Account Info

            </p>


        </div>
        <div className="p-2 ">

           

            

        <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput width={'100%'} label={'Bank Account Number'} required={true}  onChange={handleChnage} name={'banknumber'} value={mydta.banknumber} />
            </div>

            <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput width={'100%'} label={'Account Owner Name'} required={true}  onChange={handleChnage} name={'bankholdername'} value={mydta.bankholdername} />
            </div>

            <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput width={'100%'} label={'Bank Name'} required={true}  onChange={handleChnage} name={'bankname'} value={mydta.bankname} />
            </div>

            <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput width={'100%'} label={'Bank IFSC Code'} required={true}  onChange={handleChnage} name={'bankifsc'} value={mydta.bankifsc} />
            </div>

            <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>
                <Myinput width={'100%'} label={'Bank Branch Name'} required={true}  onChange={handleChnage} name={'bankbranch'} value={mydta.bankbranch} />
            </div>





        </div>








        <Mybtn loading={loading} onClick={handleCreateAdv} title={'Save Bank'} />
    </div>
</div>
  )
}

export default Addbankpub