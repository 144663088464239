import React, { useEffect, useState } from 'react'
import { Drawer, Button } from 'react-rainbow-components';
import Mybtn from './Mybtn';

const Mydrwaer = ({ open, Components, setopenmodal,header, width, handleoutopen, position,size,handleclose,handlesave }) => {

    const [isOpen, setisOpen] = useState(false)

    const handleOnClose = () => {
        setisOpen(false)
        setopenmodal(false)
        handleoutopen && handleoutopen()
    }

    useEffect(() => {
        setisOpen(open)

    }, [open])
    return (
        <div>

            <Drawer
            size={size?size:'medium'}
                id="drawer-7"
                header={header}
                slideFrom= {position?position:'right'}
                // footer={
                //     <DrawerFooter
                //         onCancel={() => closeDrawer()}
                //         onSave={() => closeDrawer()}
                //     />
                // }
                isOpen={isOpen}
                onRequestClose={handleOnClose}
                footer={
                   <div style={{display:'flex'}}>
                    <Mybtn title={'Close'} onClick={handleOnClose}/>
                    <Mybtn title={'Save'} onClick={()=>{
                        handlesave()
                        handleOnClose()
                    }}/>
                   </div>
                }
            >
                {Components}
            </Drawer>
        </div>
    )
}

export default Mydrwaer