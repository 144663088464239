

import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import Resendemail from './Resendmail'
import Myselect from '../../../COmponents/Myselect'


const Mainloginagency = () => {
    const navigate = useNavigate()
    const { platformurl } = useParams()
    const [loading,setloading] =useState(false)
    const [emailnotverify,setemailnotverify] = useState(false)
    const [openemailmodal,setopenemailmodal] = useState(false)
    const [mydta,setmydta] = useState({
        email:'',
        password:'',
    })

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        setmydta({ ...mydta, [name]: value })
    }

    const loginresponse = async (res) => {

        await localStorage.setItem('token', res.token);
        await localStorage.setItem('userInfo', JSON.stringify(res.user));
        window.location.reload(``)
        toast.success(`Welcome ${res.user.name}`)
     
    }

    const handlelogin =async ()=>{
        await setloading(true)
        if (mydta.email !== '' && mydta.password !== '') {

           await gpeapi.post(`${platformurl}/agencylogin`, mydta).then((res) => loginresponse(res.data)).catch((e) => {

            toast.error(e?.response?.data.msg)
            if(e?.response?.data?.code===1){
                setemailnotverify(true)
                // console.log(e?.response?.data?.code)
            }
                


            })

        } else {
            toast.error('Please fill all Data')
           
        }
        await setloading(false)

    }

    const whenEmailSentCOm = ()=>{
        setemailnotverify(false)
        setopenemailmodal(false)
    }

    const loginasdata = [
        {label:'Agency',value:'agency'},
        {label:'Publisher',value:'publisher'},
        {label:'Advertiser',value:'advertiser'},
        {label:'Employee',value:'employee'},
    ]

    const handleloginaschange = (e)=>{
        if(e=='publisher'){
            navigate(`/p/${platformurl}/publisher`)

        }else if(e=='advertiser'){
            navigate(`/p/${platformurl}/advertiser`)

        }else if(e=='employee'){
            navigate(`/p/${platformurl}/employee`)

        }
        // console.log(e)
    }

    return (


        <section className="bg-gray-50 dark:bg-gray-900">

<Mymodal Components={<Resendemail complete={whenEmailSentCOm}/>} open={openemailmodal}setopenmodal={setopenemailmodal} />

            <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <ToastContainer />

                <div className="w-full bg-white rounded-lg  shadow-2xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                

                    <div className="flex flex-col items-center justify-center m-4">
                        <a href="#" style={{color:COLORS.primary}} className="font-bold flex items-center mb-6 text-2xl  text-gray-900 dark:text-white">

                        {(platformurl).toUpperCase()}   
                        </a>

                        <div className=" m-1 ">
                <Myselect label={'Login As:'} data={loginasdata} width={'50%'} placeholder={'Agency'}  onChange={handleloginaschange} />
                    </div>


                        <p className='font-extralight '>Agency Login</p>
                    </div>

                    {emailnotverify && <div style={{backgroundColor:'#9adbb1',padding:10,margin:2,borderRadius:5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <p>Please Verify Your Email Address <span style={{textDecorationLine:'underline',cursor:'pointer',color:'blue'}} onClick={()=>setopenemailmodal(true)}>Click Here to Resend Verification Mail</span></p>
                    </div>}


                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

                        <div className="space-y-4 md:space-y-6" action="#">
                            <div>
                                {/* <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label> */}
                                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" placeholder="Email" required="" onChange={handleChnage} value={mydta.email}  />
                            </div>
                            <div>
                                {/* <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label> */}
                                <input type="password" name="password" id="password" placeholder="Password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none" required="" onChange={handleChnage} value={mydta.password} />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                                    </div>
                                </div>
                                <Link to={`/p/${platformurl}/agency/forgot-password`} className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</Link>
                            </div>

                            <div className='flex justify-end'>
                                <Mybtn loading={loading} onClick={handlelogin} title={'Login'} />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mainloginagency