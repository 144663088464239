


import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
// import '../mygloble.css'


import { Spinner } from '@material-tailwind/react';


import { ToastContainer, toast } from 'react-toastify';
import Mainhome from '../Pages/Home/Mainhome';
import Headerweb from '../Components/Headerweb';
import Signupforweb from '../Pages/Signup/Signupforweb';
import Mainpricingweb from '../Pages/Pricing/Mainpricingweb';
import Footerweb from '../Components/Footerweb';
import Privacypoliciy from '../Pages/Privacypolicy';
import Tandc from '../Pages/Tandc';
import Aboutus from '../Pages/Aboutus';
import Thankyou from '../Pages/Thankyou';
import Contactus from '../Pages/Contactus';
import Productandsolution from '../Pages/Productandsolution';
import Services from '../Pages/Services';
import Trackingpage from '../Pages/Productsolution/Trackingpage';
import Powerfulltools from '../Pages/Productsolution/Powerfulltools';
import AntiFraudpage from '../Pages/Productsolution/AntiFraudpage';
import Offermanagmentpage from '../Pages/Productsolution/Offermanagmentpage';
import Reposrtsmanagpage from '../Pages/Productsolution/Reposrtsmanagpage';
import Headerwebnew from '../Components/Headerwebnew';


const Mainrouteweb = () => {

    document.title = `OfferBorn Affordable Performance Marketing Platform`


    useEffect(()=>{
       
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function () {
                var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = 'https://embed.tawk.to/66af46c532dca6db2cb9cc05/1i4eaot3h';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();
        
    },[])


        // <!--Start of Tawk.to Script-->
 

// <!--End of Tawk.to Script-->




    //   useEffect(()=>{
    //     callForCheckPlatform()
    //     token && checkvalidtoken()

    //   },[])




    //   const callForCheckPlatform =async ()=>{
    //     setloading(true)
    //     await gpeapi.get(`/${platformurl}/checkagencyplatform`).then((res)=>setisvalid(res.data.success)).catch((e)=>setisvalid(e?.response?.data?.success))
    //     setloading(false)
    //   }




    //   const checkvalidtoken = ()=>{
    //     gpeapi.get(`/${platformurl}/checkagencytoken`,{headers:{
    //       "Authorization":`Beaer ${token}`
    //   }}).then((res)=>{
    //     if(res.data.success){
    //       setinfo(res.data.user)
    //        localStorage.setItem('userInfo', JSON.stringify(res.data.user));

    //        if(res.data.user?.extendplanended){
    //         toast.warning('Your Plan Ended Please Purchase a Plan to Continue Services')
    //        }

    //        if(res.data.user?.planended){
    //         toast.warning('Your Plan Ended Please Purchase a Plan to Continue Services')
    //        }



    //     }else{
    //       localStorage.clear()
    //       window.location.reload('')
    //     }
    //   })
    //   // console.log('runnn')
    //   }

    //   const menus = [
    //     { name: "dashboard", link: `/${platformurl}/agency/dashboard`, icon: MdOutlineDashboard },
    //     {
    //       name: "campaigns", link: "/", icon: MdCampaign, submenu: [
    //         { name: "all campaigns", link: `/${platformurl}/agency/campaigns` },
    //         { name: "create campaign", link: `/${platformurl}/agency/createcamp` },

    //       ]
    //     },
    //     {
    //       name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
    //         { name: "campaigns report", link: `/${platformurl}/agency/performancerepo` },
    //         { name: "daily report", link: `/${platformurl}/agency/dailyreport` },
    //         { name: "publishers report", link: `/${platformurl}/agency/publisherreport` },
    //         { name: "advertisers report", link: `/${platformurl}/agency/advertiserreport` },
    //         { name: "conversion report", link: `/${platformurl}/agency/conversionreport` },
    //         // { name: "clicks report", link: "/" },
    //         // { name: "sub-id reports", link: "/" },
    //       ]
    //     },
    //     { name: "publishers", link: "/", icon: TbFriends,submenu:[
    //       { name: "all publishers", link: `/${platformurl}/agency/publishers` },


    //     ] },
    //     { name: "advertisers", link: "/", icon: FaHandsHelping,submenu:[
    //       { name: "all advertisers", link: `/${platformurl}/agency/advertisers` },


    //     ]  },

    //     { name: "account", link: `/${platformurl}/agency/account`, icon: CgProfile 
    //   },

    //   { name: "billing", link: `/${platformurl}/agency/billing`, icon: MdPayment 
    //   },
    //     // { name: "Cart", link: "/", icon: FiShoppingCart },
    //     // { name: "Billing", link: "/", icon: TbCreditCard },
    //     // { name: "Setting", link: "/", icon: RiSettings4Line },
    //   ];

    // console.log(info)


    return (


    <>

        {/* <Router> */}

        <Headerweb />
        {/* <Headerwebnew/> */}
        <Routes>


            {/* <Route path='/' element={<Mainhome />} /> */}
            <Route path='/*' element={<Mainhome />} />
            <Route path='/signup' element={<Signupforweb />} />
            <Route path='/price' element={<Mainpricingweb />} />
            <Route path='/privacy-policy' element={<Privacypoliciy />} />
            <Route path='/terms-&-conditions' element={<Tandc />} />
            <Route path='/about' element={<Aboutus />} />
            <Route path='/contact' element={<Contactus />} />
            <Route path='/solution' element={<Productandsolution />} />
            <Route path='/service' element={<Services />} />
            <Route path='/thankyou' element={<Thankyou />} />


            <Route path='/tracking' element={<Trackingpage />} />
            <Route path='/powerful-tool' element={<Powerfulltools />} />
            <Route path='/anti-fraud' element={<AntiFraudpage />} />
            <Route path='/offer-management' element={<Offermanagmentpage />} />
            <Route path='/reports-management' element={<Reposrtsmanagpage />} />
            {/* <Route path='/privacy-policy' element={<Privacypoliciy />} /> */}

            {/* <Route path='*' element={<Signupforweb/>}/> */}




        </Routes>
        <Footerweb />


        {/* </Router> */}











    </>








)
}

export default Mainrouteweb