import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { COLORS } from '../Constants'

const DetailsCardcom = ({name,icon,t,y,m,color}) => {
    return (
        <div style={{borderWidth:0.2,borderStyle:'solid' ,borderColor:COLORS.primary}} className=" bg-white rounded-md  shadow-md dark:border  sm:max-w-sm xl:p-0 dark:bg-gray-800 dark:border-gray-700 m-4 ml-1 mr-1">
            <div style={{display:'flex',alignItems:'center',marginLeft:10}}>
                {icon && <FontAwesomeIcon style={{fontWeight:'bold',marginRight:8}} color={COLORS.primary} icon={icon} className="rainbow-m-left_medium" />}
            
                <p style={{color:COLORS.primary}} className='font-semibold text-lg '>{(name)?.toUpperCase()}</p>
            </div>
            <div className='flex justify-around items-center'>

            <div className=" flex-col w-full flex justify-center items-center p-1  md:space-y-3 sm:p-5">
                <p className='text-gray-500 font-medium italic'>Today</p>
                <p className='text-lg font-body'>{t}</p>

            </div>
            <div className="flex-col w-full flex justify-center items-center p-1  md:space-y-3 sm:p-5 ">
                <p className='text-gray-500 font-medium italic'>Yesterday</p>
                <p className='text-lg '>{y}</p>

            </div>
            <div className=" flex-col w-full flex justify-center items-center p-1  md:space-y-3 sm:p-5 ">
                <p className='text-gray-500 font-medium italic'>MTD</p>
                <p className='text-lg '>{m}</p>

            </div>
            </div>
        </div>
    )
}

export default DetailsCardcom