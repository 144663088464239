import React from 'react'
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,


} from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';

import { useState } from 'react';
import { getSubdomain } from '../../../../../../COmponents/Getsubdomain';
import { useContext } from 'react';

import { useEffect } from 'react';
import Mybtn from '../../../../../../COmponents/Mybtn';
import { Empty, Spin } from 'antd';

import { useNavigate, useParams } from 'react-router-dom';

import { Suspense } from 'react';
import { Descriptions, Select, Input, TextArea } from 'antd';
import Myinput from '../../../../../../COmponents/Myinput';
import gpeapi from '../../../../../../apis/gpeapi';
import { Authcontext } from '../../../../../../Context/Authcontext';

const Viewcampinvoiceagency = () => {

    const token = localStorage.getItem('token')
    const {platformurl}= useParams()

    // useEffect(() => {
    //     setplatformname(getSubdomain(window.location.host))
    // }, [])

    const navigate = useNavigate()
    const TABLE_HEAD = ["Item (Campaign)", "Quantity (Conversion)", "Price (Payout)", "Total"];

    const { id } = useParams()
    const { toggle } = useContext(Authcontext)
    const [newchatmodel, setnewchatmodel] = useState(false)
    const [statsdata, setstatdata] = useState(null)
    const [loading, setloading] = useState(false)
    const [btnloading, setbtnloading] = useState(false)
    const [fetchload, setfetchload] = useState(false)
    const [invoicetotal, setinvoicetotal] = useState(0)


    const [showinput, setshowinput] = useState({
        index: null,
        enable: false
    })



    const [addgst, setaddgst] = useState(true)

    const getdatetimevia = (date) => {
        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var second = mydate.getSeconds();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        second = second < 10 ? '0' + second : second;

        var strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;

        return `${todate}-${month + 1}-${year} at ${strTime}`
    }

    useEffect(() => {
        platformurl && callforAPI()
    }, [platformurl])

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/getcampinvoiceagencyadminbyid/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.user)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const [selstatus, setselstatus] = useState('selected')
    const [comment, setcomment] = useState('')
    const [paymentid, setpaymentid] = useState('')


    // console.log(statsdata)

    const dtat = [
        {
            key: '1',
            label: 'ID',
            children: statsdata?.pubid,
        },
        {
            key: '2',
            label: 'Name',
            children: statsdata?.pubdata?.name,
        },
        {
            key: '3',
            label: 'Company',
            children: statsdata?.pubdata?.company,
        },
        {
            key: '4',
            label: 'Month',
            children: statsdata?.invoicetitle?.split(' ')[1],
        },

        {
            key: '5',
            label: 'Total',
            children: `${statsdata?.total} ${statsdata?.currency} ${statsdata?.addgst ? '(+18%)' : ''}`,
        },

        {
            key: '6',
            label: 'Status',
            children: `${statsdata?.status}`,
        },
        statsdata?.paymentid?{
            key: '7',
            label: 'PaymentID',
            children: `${statsdata?.paymentid}`,
        }:null,
        statsdata?.comment?{
            key: '8',
            label: 'Comment',
            children: `${statsdata?.comment}`,
        }:null,
        statsdata?.paiddate?{
            key: '9',
            label: 'Paid Date',
            children: `${getdatetimevia(statsdata?.paiddate)}`,
        }:null,


    ].filter(x=>x)

    console.log(dtat)


    const handleupdatestatus = async () => {
        setbtnloading(true)

        const dtaa = {
            status: selstatus,
            comment: comment,
            paymentid: paymentid
        }

        if (selstatus == 'paid') {
            if (comment !== '') {
                if (paymentid !== '') {
                    const dtaa = {
                        status: selstatus,
                        comment: comment,
                        paymentid: paymentid
                    }

                    const res = await gpeapi.put(`/${platformurl}/updatecampaigninvoiceadmin/${id}`, dtaa, {
                        headers: {
                            "Authorization": `Beaer ${token}`
                        }
                    }).then((ress) => {
                        toast.success(ress.data.msg)
                        // navigate('/agency/manageinvoice')
        navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/manageinvoice`)


                        // setmydta(ress.data?.user)
                        // console.log('from add adveve', ress.data?.user)
                    }).catch((e) => toast.error(e?.response.data.msg))



                } else {
                    toast.warn('Please enter Payment ID')
                }

            } else {
                toast.warn('Please enter Comment')
            }


        } else if (selstatus == 'rejected') {
            if (comment !== '') {

                const dtaa = {
                    status: selstatus,
                    comment: comment,

                }

                const res = await gpeapi.put(`/${platformurl}/updatecampaigninvoiceadmin/${id}`, dtaa, {
                    headers: {
                        "Authorization": `Beaer ${token}`
                    }
                }).then((ress) => {
                    toast.success(ress.data.msg)
                    navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/manageinvoice`)


                    // setmydta(ress.data?.user)
                    // console.log('from add adveve', ress.data?.user)
                }).catch((e) => toast.error(e?.response.data.msg))





            } else {
                toast.warn('Please enter Comment')
            }

        } else if (selstatus == 'validated') {


            const dtaa = {
                status: selstatus,

            }

            const res = await gpeapi.put(`/${platformurl}/updatecampaigninvoiceadmin/${id}`, dtaa, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }).then((ress) => {
                toast.success(ress.data.msg)
                navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/manageinvoice`)


                // setmydta(ress.data?.user)
                // console.log('from add adveve', ress.data?.user)
            }).catch((e) => toast.error(e?.response.data.msg))





        }

     


        setbtnloading(false)
    }

    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />





            <div>

                <div className='flex justify-between items-center m-2'>

                    <p className='font-semibold text-lg'>VIEW INVOICE</p>

                    <div className='sm:w-full md:w-[40%] flex '>

                        <div className=''>

                            {/* <Mydatepick value={monthrange} placeholder={'Select Month'} onChange={(e) => {

                        setmonthrange(e.target.value)
                        setrange(`${e.target.value}-14`)
                    }} /> */}



                            <div className='flex flex-wrap justify-center mt-1'>

                                {/* {
                                calendertypetda?.map((val) => (

                                    <p onClick={() => handlecalendtypeclk(val.value)} style={{ backgroundColor: 'gray' }} className='text-xs text-white m-0.5 cursor-pointer p-0.5 capitalize rounded-md font-semibold'>{val.label}</p>
                                ))
                            } */}
                            </div>

                        </div>



                        <div>

                            {/* <Button disabled={fetchload} className='ml-1 mr-1' onClick={handlefetchdata} size="sm">{fetchload ? <Spinner /> : 'Fetch'}</Button> */}
                        </div>


                    </div>



                </div>










            </div>



            <Spin spinning={loading}>

                <div className='bg-white pt-5'>


                    {
                        statsdata?.invoicedata?.length ? (

                            <div >
                                <Descriptions style={{ margin: 10, padding: 10, border: '1px solid darkblue', borderRadius: 8 }} title="Publisher Info" items={dtat} />

                            </div>

                        ) : null
                    }



                    {
                        statsdata?.invoicedata?.length ? <CardBody className="overflow-scroll px-0">
                            <table id='table' className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!statsdata?.invoicedata?.length ? (
                                        <td colSpan={TABLE_HEAD?.length}>

                                            <div className='flex justify-center text-center m-2'>
                                                <Empty />
                                            </div>
                                        </td>
                                    ) : (
                                        <>

                                            {statsdata?.invoicedata?.map(
                                                (val, index) => {
                                                    const isLast = index === statsdata?.length - 1;
                                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={index}>

                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                                    {val?.campaign} <span className='text-xs text-black'>{val.event && `(${val.event})`}</span>
                                                                </Typography>
                                                            </td>

                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal ">
                                                                    {val?.conversion}

                                                                </Typography>
                                                            </td>


                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal ">
                                                                    ₹{val?.payout}
                                                                    {/* {
                                                                    showinput.index == index && showinput.enable ? <span className='ml-10 text-sm font-semibold text-blue-500 cursor-pointer' onClick={() => setshowinput({ enable: false, index: null })}>Done</span> : <span className='ml-10 text-sm font-semibold text-blue-500 cursor-pointer' onClick={() => setshowinput({ enable: true, index: index })}>Edit</span>

                                                                } */}

                                                                </Typography>

                                                                {
                                                                    showinput.index == index && showinput.enable && <Myinput type={'number'} value={val.payout} name={'payout'} placeholder={'Optional for Update Payout'} />
                                                                }
                                                            </td>


                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    ₹{val?.conversion * val.payout}

                                                                </Typography>
                                                            </td>



                                                        </tr>
                                                    );
                                                },
                                            )}

                                            {
                                                statsdata?.addgst ? <tr >

                                                    <td className='p-4'>
                                                        <Typography variant="small" color="black" className="font-semibold uppercase">
                                                            GST (18%)
                                                        </Typography>
                                                    </td>


                                                    <td className='p-4'>
                                                        <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                        </Typography>
                                                    </td>

                                                    <td className='p-4'>
                                                        <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                        </Typography>
                                                    </td>

                                                    <td className='p-4'>
                                                        <Typography variant="small" color="blue-gray" className="font-bold">
                                                            ₹{(statsdata?.total - (statsdata?.total / (1 + (18 / 100)))).toFixed(2)}


                                                        </Typography>
                                                    </td>





                                                </tr> : null
                                            }

                                            <tr >

                                                <td className='p-4'>
                                                    <Typography variant="small" color="black" className="font-semibold uppercase">
                                                        Total
                                                    </Typography>
                                                </td>


                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                    </Typography>
                                                </td>

                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-normal cursor-pointer ">


                                                    </Typography>
                                                </td>

                                                <td className='p-4'>
                                                    <Typography variant="small" color="blue-gray" className="font-bold">
                                                        ₹{(statsdata?.total).toFixed(2)}

                                                    </Typography>
                                                </td>





                                            </tr>
                                        </>
                                    )

                                    }
                                </tbody>
                            </table>
                        </CardBody> : null
                    }


                    {
                        statsdata?.invoicedata?.length ? <div className='flex flex-col items-center justify-center '>



                            {
                                statsdata?.status !== 'paid' ? <div>
                                    <div className='flex flex-col items-center m-5'>

                                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', margin: 10 }}>
                                            <p className='mr-2'>Status: </p>
                                            <Select value={selstatus} onChange={(e) => setselstatus(e)} style={{ width: 200 }}>
                                                <Select.Option selected disabled value='selected'>Select Status</Select.Option>
                                                <Select.Option disabled={statsdata?.status=='validated' | statsdata?.status=='rejected' | statsdata?.status=='paid'} value="validated">Validated</Select.Option>
                                                <Select.Option value="paid">Paid</Select.Option>
                                                <Select.Option value="rejected">Rejected</Select.Option>
                                            </Select>
                                        </div>

                                        {
                                            selstatus == 'paid' ? <div style={{ width: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10 }}>
                                                <p className='mr-2'>Payment ID: </p>
                                                <Myinput placeholder={'UTR Number,txnid,refid,paymentid'} value={paymentid} onChange={(e) => setpaymentid(e.target.value)} />
                                            </div> : null
                                        }

                                        {
                                            selstatus == 'rejected' | selstatus == 'paid' ? <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10 }}>
                                                <p className='mr-2'>Comment: </p>
                                                <Myinput placeholder={'Enter Comment'} value={comment} onChange={(e) => setcomment(e.target.value)} multiline={true} line={4} />
                                            </div> : null
                                        }

                                    </div>


                                    <Mybtn loading={btnloading} onClick={handleupdatestatus} radius={10} title={'Update Status'} />
                                </div> : null
                            }


                        </div> : null
                    }







                </div>
            </Spin>













        </div>
    )
}

export default Viewcampinvoiceagency