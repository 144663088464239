import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import gpeapi from '../../apis/gpeapi';
import Forgotpassword from '../Pages/Login/Forgotpassword';
import MainLogin from '../Pages/Login/MainLogin';
import Mainregister from '../Pages/Register/Mainregister';
import { FiMessageSquare, FiFolder, FiShoppingCart, FiRadio } from "react-icons/fi";
import { MdCampaign, MdOutlineDashboard } from "react-icons/md";
import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import Page404Found from '../../COmponents/Page404Found';
import Mainhomeadv from '../Pages/Home/Mainhomeadv';
import Mysidebar from '../../COmponents/Mysidebar';
import Header from '../../COmponents/Header';
import Maincampaign from '../Pages/Campaigns/Maincampaign';
import Viewadvcamp from '../Pages/Campaigns/Viewadvcamp';
import Mainaccountadv from '../Pages/Account/Mainaccountadv';
import { CgProfile } from 'react-icons/cg';
import { GrAnnounce } from 'react-icons/gr';
import Performancerepoadv from '../Pages/Reports/Performancerepoadv';
import Conversionrepoadv from '../Pages/Reports/Conversionrepoadv';
import { Spinner } from 'react-rainbow-components';


const AdvMainroutes = () => {
  const token = localStorage.getItem('token')
  const [isvalid, setisvalid] = useState(null)
  const [loading, setloading] = useState(false)

  const { platformurl } = useParams()

  useEffect(() => {
    callForCheckPlatform()
    token && checkvalidtoken()


  }, [])


  const callForCheckPlatform = async () => {
    setloading(true)
    await gpeapi.get(`/${platformurl}/checkagencyplatform`).then((res) => setisvalid(res.data.success)).catch((e) => setisvalid(e?.response?.data?.success))
    setloading(false)
  }

  const checkvalidtoken = () => {
    gpeapi.get(`/${platformurl}/checkadvtoken`, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then((res) => {
      if (res.data.success) {

      } else {
        localStorage.clear()
        window.location.reload('')
      }
    })
    // console.log('runnn')
  }


  const menus = [
    { name: "dashboard", link: `/p/${platformurl}/advertiser/dashboard`, icon: MdOutlineDashboard },
    {
      name: "campaigns", link: `/p/${platformurl}/advertiser/campaigns`, icon: MdCampaign
    },
    { name: "reports", link: "/", icon: FiMessageSquare , submenu: [
      { name: "campaigns", link: `/p/${platformurl}/advertiser/performancerepo` },
      // { name: "daily report", link: `/${platformurl}/publisher/dailyrepo` },
      { name: "conversion", link: `/p/${platformurl}/advertiser/conversionrepo` },
  ]},

    { name: "account", link: `/p/${platformurl}/advertiser/account`, icon: CgProfile },
   
  ];
  return (

    isvalid==null?<Spinner style={{display:'none'}}/>:isvalid ?

      <>

        {
          token && <>
            <Mysidebar menus={menus} />
            <div style={{ marginTop: 60 }}>
              <Header />
            </div></>
        }


        <Routes>

          {
            token ? (
              <>
                <Route path='/*' element={<Mainhomeadv />} />
                <Route path='/dashboard' element={<Mainhomeadv />} />
                <Route path='/campaigns' element={<Maincampaign />} />
              <Route path='/viewcamp/:id' element={<Viewadvcamp />} />
              <Route path='/account' element={<Mainaccountadv />} />

              <Route path='/performancerepo' element={<Performancerepoadv />} />
              <Route path='/conversionrepo' element={<Conversionrepoadv />} />



              </>
            ) : (
              <>
                <Route path='/*' element={<MainLogin />} />
                <Route path='/login' element={<MainLogin />} />
                <Route path='/register' element={<Mainregister />} />
                <Route path='/forgot-password' element={<Forgotpassword />} />
              </>
            )
          }









        </Routes>

      </> :
      <Page404Found />

  )
}

export default AdvMainroutes