

import { faCoffee, faHandPointer,faMoneyBillTransfer,faUserCheck } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailsCardcom from '../../../COmponents/DetailsCardcom'
import { Authcontext } from '../../../Context/Authcontext'

import gpeapi from '../../../apis/gpeapi'
import { toast } from 'react-toastify'
import { Spinner } from 'react-rainbow-components'
import Homestatsadv from '../Stats/Homestatsadv'

const Mainhomeadv = () => {
  const token = localStorage.getItem('token')

  const { toggle } = useContext(Authcontext)

  const {platformurl} = useParams()

  const [loading, setloading] = useState(false)
  const [statsdata, setstatdata] = useState()

  useEffect(() => {
      callforAPI()
       
    }, [])

  const callforAPI = async () => {
      setloading(true)
      const res = await gpeapi.get(`/${platformurl}/advdashboardcount`, {
          headers: {
              "Authorization": `Beaer ${token}`
          }
      }).then((ress) => {
          setstatdata(ress?.data?.data)
      }).catch((e) => {
          toast.error(e?.response?.data?.msg)

      })
      setloading(false)

  }

 
  return (
    <div className={toggle ? "main active" : "main"}>

      <div>
        <Homestatsadv />
      </div>

      {
        loading? <Spinner/> :(

        
      <div style={{display:'flex',flexWrap:'wrap'}}>

        {
          statsdata?.map((val,i)=>(

            <DetailsCardcom key={i} name={val?.name} icon={val.name=='conversion'?faUserCheck:val.name=='spend'?faMoneyBillTransfer:faHandPointer} m={val?.name=='spend'? '₹'+val?.mtd :val?.mtd} t={val?.name=='spend'?'₹'+val?.today:val?.today} y={val?.name=='spend'?'₹'+val?.yesterday:val?.yesterday}/>
          ))
        }
        
      </div>

      )
      }

      
      
      </div>
  )
}

export default Mainhomeadv