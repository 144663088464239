import { SET_AGENCYADV, SET_AGENCYPUB } from "../../actiontypes/usertypes";


const publisherAgencyReducer = (state=[],action)=>{
    switch (action.type) {
        case SET_AGENCYPUB:
            return [...action.payload]
            break;

           
    
        default:
           return state;
    }
}

export default publisherAgencyReducer