



import { Chip, Tooltip, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import Mycard from '../../../COmponents/Mycard'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Myinput from '../../../COmponents/Myinput'

import Changepasswordemployee from './Changepasswordemployee'
import Editprofileemployee from './Editprofileemployee'



const Mainaccountemployee = () => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const { toggle } = useContext(Authcontext)
    const basicDta = useSelector((state) => state.basicReducer)[0]
    const { platformurl } = useParams()

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))
    const [postback, setpostback] = useState('')
    const [passmodel, setpassmodel] = useState(false)
    const [editmodel, seteditmodel] = useState(false)
    // const [plandata,setplandata] = useState(null)


    // useEffect(() => {
    //     setpostback(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${userinfo?.security_token}`)
    //     // callforplandata()
    // }, [])


   

    // console.log(userinfo)
    const genratedate = (date) => {
        const d = new Date(date);
        let month = months[d.getMonth()];
        let year = d.getFullYear()
        return `${month} ${year}`
    }


  


    // console.log(plandata)



    return (
        <div className={toggle ? "main active" : "main"}>



            <Mymodal width={'40%'} Components={<Changepasswordemployee setmodal={setpassmodel} />} open={passmodel} setopenmodal={setpassmodel} />

            <Mymodal width={'60%'} Components={<Editprofileemployee setmodal={seteditmodel} />} open={editmodel} setopenmodal={seteditmodel} />

            <ToastContainer />


            <div className='sm:flex sm:flex-col md:flex md:flex-row md:flex-wrap'>

                <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                    <div>
                        <div className='flex justify-between items-center border-b p-5 pb-1'>

                            <div class="flex  items-center space-x-4">
                                <svg class=" w-10 h-10 rounded-full" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                                <div class="font-medium dark:text-white">
                                    <div className='uppercase'>{userinfo?.name}</div>
                                    <div class="text-sm text-gray-500 dark:text-gray-400">Joined in {genratedate(userinfo?.createdAt)}</div>
                                </div>
                            </div>

                            <div className='p-2 flex flex-col items-center'>

                                <p onClick={() => seteditmodel(true)} style={{ backgroundColor: COLORS.primary }} className='max-w-fit pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer m-1'>

                                    <p className='font-medium text-white text-sm'>
                                        Edit Profile
                                    </p>

                                </p>
                                <p onClick={() => setpassmodel(true)} style={{ backgroundColor: COLORS.primary }} className='max-w-fit pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer m-1'>

                                    <p className='font-medium text-white text-sm'>
                                        Change Password
                                    </p>

                                </p>

                            </div>





                        </div>

                        <div className='p-5 pt-2'>

                            <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                <p className='text-sm'>ID</p>

                                <p className='text-sm col-span-3'>{userinfo?.id}</p>
                            </div>

                            <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                <p className='text-sm '>Job Role</p>

                                <p className='text-sm col-span-3 capitalize'>{userinfo?.job_role?.replace('_'," ")}</p>
                            </div>

                          
                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Email</p>

                                <p className='text-sm col-span-3'>{userinfo?.email}</p>
                            </div>

                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Mobile</p>

                                <p className='text-sm col-span-3'>+91{userinfo?.mobile}</p>
                            </div>

                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Address</p>

                                <p className='text-sm col-span-3'>{userinfo?.address}</p>
                            </div>

                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Country</p>

                                <p className='text-sm col-span-3'>{userinfo?.country}</p>
                            </div>
                            <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                <p className='text-sm '>Currency</p>

                                <p className='text-sm col-span-3'>INR</p>
                            </div>
                            <div className='justify-center grid grid-cols-4  gap-4  pb-2 pt-2'>

                                <p className='text-sm '>Language</p>

                                <p className='text-sm col-span-3'>EN</p>
                            </div>













                        </div>


                    </div>
                } />

                


              
            </div>
        </div>
    )
}

export default Mainaccountemployee