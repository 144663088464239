import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'


const Resendemail = ({ complete }) => {
    const { platformurl } = useParams()

    const [email, setemail] = useState('')
    const [loading, setloading] = useState(false)

    const data = {
        email:email
    }

    const onSubmitBtn = async () => {
        setloading(true)
        if(email!==''){
            await gpeapi.post(`/${platformurl}/resendemailpub`, data).then((res) => {
                toast.success(res.data.msg)
                
            }).catch((e) => {
    
                toast.error(e?.response?.data.msg)
    
            })
            complete && complete()
        }else{
            toast.error('Please Enter Email ID!')

        }
       

        setloading(false)
    }

    return (
        <div style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
            <h6>Resend Email Verification Mail</h6>

            <div>

                <div class="relative mb-6">
                    <Myinput type={'email'} value={email} onChange={(e) => setemail(e.target.value)} name={'email'} label={'Email Address'} width={1} classname={'"inline-block w-full    pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white  transition duration-150 ease-in-out  '} />
                </div>

                <Mybtn onClick={onSubmitBtn} loading={loading} classname={" w-full rounded bg-primary  text-sm font-medium uppercase leading-normal text-white "} title={'RESEND EMAIL'} />


            </div>

        </div>
    )
}

export default Resendemail