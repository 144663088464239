import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { Authcontext } from '../../../Context/Authcontext'
import Addpostbackpub from './Addpostbackpub'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";


const Mainpostbackpub = () => {
    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { platformurl } = useParams()
  const dispatch = useDispatch()
  const { toggle } = useContext(Authcontext)

  const [myid,setmyid] = useState(null)

  const [openmodal,setopenmodal] = useState(false)
  const TABLE_HEAD = ["EVENT","TYPE", "STATUS", "POSTBACK URL", "ACTION"];

  const updatePostback = (e,i)=>{
    setopenmodal(true)
    setmyid({id:i,data:e})
  }
 

  return (
    <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>

<Mymodal width={'40%'} Components={<Addpostbackpub id={myid} setmodal={setopenmodal}  />} open={openmodal} setopenmodal={setopenmodal} handleoutopen={()=>setmyid(null)} />

      <ToastContainer />

      <p className='text-2xl font-medium'>MANAGE POSTBACKS</p>

      <div>
        <Mybtn onClick={()=>setopenmodal(true)} title={'ADD NEW'} padding={'0px 15px'} radius={12} fontsize={14} />
      </div>


      <div>
        {
            userinfo?.globlepostback ? (

                <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {userinfo?.globlepostback?.map(
                                    (val, index) => {
                                        const isLast = index === userinfo?.globlepostback?.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>
                                                <td className={classes}>


                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        conversion
                                                    </Typography>

                                                </td>
                                               
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal ">
                                                       Postback
                                                    </Typography>
                                                </td>
                                                
                                                

                                                

                                                

                                                <td className={classes}>
                                                    <div className="w-max">
                                                        <Chip
                                                            size="sm"
                                                            variant="ghost"
                                                            value={'Active'}
                                                            color={
                                                                "green" 
                                                            }
                                                        />
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <Typography variant="small" color="blue" className="font-normal ">
                                                        {val}

                                                    </Typography>
                                                </td>
                                                
                                                <td className={classes}>
                                                <Tooltip content="Edit">
                                                                <IconButton onClick={()=>updatePostback(val,index)} variant="text" color="blue-gray">
                                                                    <PencilIcon className="h-4 w-4" />
                                                                </IconButton>
                                                            </Tooltip>
                                                </td>
                                               
                                                
                                                
                                              
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>

            ):(
                <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography>
            )
        }
      </div>


    </div>
  )
}

export default Mainpostbackpub