import React from 'react'
import { COLORS } from '../../Constants'

const Cardview = ({img,text,subtext,differ}) => {
  return (
    <div  class="service-card" style={differ &&{backgroundColor:'#1b2959',borderRadius:25}}>

                <div class="card-icon" style={{backgroundColor:COLORS.primary}} >
                  <ion-icon name={img}></ion-icon>
                </div>

                <h3 class="h3">
                  <a href="#" style={differ&&{color:'white'}} class="text-xl">{text}</a>
                </h3>

                <p style={differ&&{color:'white',lineHeight:1.5}} class="text-base">
                  {subtext}
                </p>

              </div>
  )
}

export default Cardview