import React from 'react'
import { COLORS } from '../../../Constants'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Mainpricingweb = () => {

    document.title = `Pricing - OfferBorn`


    const navigate = useNavigate()

    const pricingdta = useSelector((state) => state.pricingReducer).filter((val)=>val.isfree==false && val.iscustom==false)

    const handlepurchaseclick = async(data)=>{
        // console.log(data)
        navigate(`/signup`)
    }

  return (
    <div style={{ marginTop: 50 }}>

    <ToastContainer />
    <section class="section service" id="service" aria-label="service">
        <div class="container">

            <h2 class="h3 section-title" style={{ color: COLORS.primary, fontWeight: 'bold' }}>Pricing</h2>

            <p class="section-text">
            Trusted by 50+ happy companies
            </p>


            <div className=' flex justify-center'>



            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    {/* <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Designed for business teams like yours</h2>
          <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
      </div> */}
                    <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                        {/* <!-- Pricing Card --> */}

                        {
                            pricingdta.length ? pricingdta?.map((val, i) => (
                                <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-[#292e57] shadow-lg dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white ">
                                    <h3 class="mb-4 text-2xl font-semibold">{val?.name}</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">{val?.des}</p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">₹{val?.price}</span>
                                        <span class="text-gray-500 dark:text-gray-400">/ {val?.days}Days</span>
                                    </div>

                                    <div>
                                    Additional Tax 18%
                                    </div>
                                    {/* <!-- List --> */}
                                    <ul role="list" class="mb-8 space-y-4 text-left">

                                        {
                                            val?.note?.split('\n')?.map((mval)=>(

                                                <li class="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>{mval}</span>
                                        </li>

                                            ))
                                        }


                                      
                                    </ul>
                                    <a onClick={()=>handlepurchaseclick(val)} style={{ backgroundColor: COLORS.primary }} class="text-white cursor-pointer bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Signup Now</a>
                                </div>
                            )):null
                        }


                        
                        
                        

                    </div>
                </div>


              






            </div>

        </div>
    </section>
</div>
  )
}

export default Mainpricingweb