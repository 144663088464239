
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import CountUp from 'react-countup';
import { COLORS } from '../../../Constants';

const AntiFraudpage = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    // document.title = `About Us - OfferBorn`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section style={{ marginTop: 50 ,backgroundColor:'white'}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h3 section-title font-bold" style={{ color: COLORS.primary }}>Fraud Detection Tools
                </h2>

                <p class="section-text">

                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" >

                        <figure class="hero-banner">
                            <img src={require('../../img/anti-fraud3-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>A security Tool
</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Anti-fraud is a high-security tool that protects your network from unwanted traffic. This tool is extremely beneficial in protecting your network from the wrong kind of traffic. That traffic can be anything from fake to unhealthy, which your affiliate network does not want. Thus, this tool is beneficial for the overall security of your network. Empower with Knowledge
                            OfferBorn provide you a Security Token
                                
                            </p>



                        </div>
                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/anti-fraud2-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Avoid Fraud Sources
</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Avoid fraud sources like fake conversion, fake traffic sources, and fake hits or clicks. With our astounding feature, you will immediately get to know about fake sources and this will help you take action immediately to protect yourself in this kind of situation. You will have 100 percent clarity on what is really going on in your system. Filter the Fakes
                            </p>



                        </div>
                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'ltr',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/anti-fraud1-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Alert Users

</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            Make the user attentive, so that no fraud traffic passes through your network. This feature of OfferBorn helps you protect your system from unwanted and unsafe traffic and immediately alerts you if any wrong kind of traffic enters your system. This helps in building a safe and secure network. Stay Alert, Stay Ahead
                              
                                
                            </p>



                        </div>
                    </div>
                </section>

            



                <section class="section hero" id="home" aria-label="hero">
                    <div class="container" style={{ direction: 'rtl',marginTop:10 }}>

                        <figure class="hero-banner">
                            <img src={require('../../img/anti-fruad-4-1.png')} width="200" height="100" alt="hero banner" class="w-100" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Traffic Source Validator


</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                            It is extremely important to validate the sources of incoming traffic to your website. There are various type of traffic fraud that can harm you. Our traffic source validator ensures that the traffic coming to your website is true and genuine. It quickly detects irregularities in traffic behaviour. Our traffic source validator is your reliable partner is combatting fraudulent traffic. Validate to Elevate
                            </p>



                        </div>
                    </div>
                </section>

              






            





            </div>
        </section>
    )
}

export default AntiFraudpage