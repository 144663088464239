import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route, useLocation, useRoutes, useParams
} from "react-router-dom";
import Header from '../../COmponents/Header';
import Mainsidebar from '../Components/Sidebar/Mainsidebar';
import TestnewSidebar from '../Components/Sidebar/TestnewSidebar';
import Mainhome from '../Pages/Home/Mainhome';
import MainLogin from '../Pages/Login/MainLogin';
import Mainregister from '../Pages/Register/Mainregister';
import { HiMenuAlt3 } from "react-icons/hi";
import { MdCampaign, MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FiMessageSquare, FiFolder, FiShoppingCart, FiRadio } from "react-icons/fi";
import Mysidebar from '../../COmponents/Mysidebar';
import gpeapi from '../../apis/gpeapi';
import Page404Found from '../../COmponents/Page404Found';
import Forgotpassword from '../Pages/Login/Forgotpassword';
import Maincampaigns from '../Pages/Campaigns/Maincampaigns';
import Viewcampaign from '../Pages/Campaigns/Viewcampaign';
import Myfooter from '../../COmponents/Myfooter';
import Mainacountpub from '../Pages/Account/Mainacountpub';
import Addpostbackpub from '../Pages/Account/Addpostbackpub';
import { CgProfile } from "react-icons/cg";
import Mainpostbackpub from '../Pages/Account/Mainpostbackpub';
import Approvedcamppub from '../Pages/Campaigns/Approvedcamppub';
import Needapprovalcamppub from '../Pages/Campaigns/Needapprovalcamppub';
import Performancereportpub from '../Pages/Reports/Performancereportpub';
import Clickidreportspub from '../Pages/Reports/Clickidreportspub';
import Conversionreportspub from '../Pages/Reports/Conversionreportspub';
import { GrAnnounce } from 'react-icons/gr';
import { Spinner } from 'react-rainbow-components';
import Mainchatagency from '../Pages/Chat/Mainchatagency';
import Chatscreenagency from '../Pages/Chat/Chatscreenagency';


const MainRoutes = () => {

  const token = localStorage.getItem('token')
  const [isvalid,setisvalid] = useState(null)
  const [loading,setloading] = useState(false)

  const {platformurl} = useParams()

  useEffect(()=>{
    callForCheckPlatform()
    token && checkvalidtoken()

    
  },[])


  const callForCheckPlatform =async ()=>{
    setloading(true)
    await gpeapi.get(`/${platformurl}/checkagencyplatform`).then((res)=>setisvalid(res.data.success)).catch((e)=>setisvalid(e?.response?.data?.success))
    setloading(false)
  }

  const checkvalidtoken = ()=>{
    gpeapi.get(`/${platformurl}/checkpubtoken`,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then(async(res)=>{
    if(res.data.success){
      await localStorage.setItem('userInfo', JSON.stringify(res.data.user));
     
    }else{
      localStorage.clear()
      window.location.reload('')
    }
  })
  // console.log('runnn')
  }


  const menus = [
    { name: "dashboard", link: `/p/${platformurl}/publisher/dashboard`, icon: MdOutlineDashboard },
    {
        name: "campaigns", link: "/", icon: MdCampaign, submenu: [
            { name: "all campaigns", link: `/p/${platformurl}/publisher/campaigns` },
            { name: "approved campaigns", link: `/p/${platformurl}/publisher/approvedcamp` },
            { name: "need approval", link: `/p/${platformurl}/publisher/needapproval` },
        ]
    },
    { name: "reports", link: "/", icon: FiMessageSquare , submenu: [
        { name: "performance report", link: `/p/${platformurl}/publisher/performancerepo` },
        { name: "daily report", link: `/p/${platformurl}/publisher/dailyrepo` },
        { name: "conversion reports", link: `/p/${platformurl}/publisher/conversionrepo` },
    ]},
    { name: "account", link: "/", icon: CgProfile , submenu: [
      { name: "profile", link:  `/p/${platformurl}/publisher/account`},
      { name: "postback", link:  `/p/${platformurl}/publisher/postback` },
      
  ]},
    // { name: "analytics", link: "/", icon: TbReportAnalytics, margin: true },
    // { name: "File Manager", link: "/", icon: FiFolder },
    // { name: "Cart", link: "/", icon: FiShoppingCart },
    // { name: "Saved", link: "/", icon: AiOutlineHeart, margin: true },
    // { name: "Setting", link: "/", icon: RiSettings4Line },
];

  return (
    isvalid==null?<Spinner style={{display:'none'}}/>:isvalid?

    <>

      {
        token && <>
          <Mysidebar menus={menus} />
        <div style={{marginTop:60}}>
        <Header/>
        
        </div></>
      }


      <Routes>

        {
          token ? (
            <>
              <Route path='/*' element={<Mainhome />} />
              <Route path='/dashboard' element={<Mainhome />} />
              <Route path='/campaigns' element={<Maincampaigns />} />
              <Route path='/viewcamp/:id' element={<Viewcampaign />} />
              <Route path='/account' element={<Mainacountpub />} />
              <Route path='/postback' element={<Mainpostbackpub />} />
              <Route path='/approvedcamp' element={<Approvedcamppub />} />
              <Route path='/needapproval' element={<Needapprovalcamppub />} />
              {/* reports */}
              <Route path='/performancerepo' element={<Performancereportpub />} />
              <Route path='/dailyrepo' element={<Clickidreportspub />} />
              <Route path='/conversionrepo' element={<Conversionreportspub />} />

              <Route path='/chats' element={<Mainchatagency />} />
              <Route path='/startchat' element={<Chatscreenagency />} />


              
            </>
          ) : (
            <>
              <Route path='/*' element={<MainLogin />} />
              <Route path='/login' element={<MainLogin />} />
              <Route path='/register' element={<Mainregister />} />
              <Route path='/forgot-password' element={<Forgotpassword />} />
            </>
          )
        }









      </Routes>
      

    </>:
    <Page404Found/>







  )
}

export default MainRoutes