
export const SET_BASIC = 'SET_BASIC';
export const SET_PRICING = 'SET_PRICING';


//AGENCY
export const SET_AGENCYADV = 'SET_AGENCYADV';
export const SET_AGENCYPUB = 'SET_AGENCYPUB';
export const SET_AGENCYCAMP = 'SET_AGENCYCAMP';


//PUB
export const SET_PUBCAMP = 'SET_PUBCAMP';





