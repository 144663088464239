import React, { useEffect, useState } from 'react'
import Myinput from '../../../COmponents/Myinput'
import { COLORS } from '../../../Constants'
import Mybtn from '../../../COmponents/Mybtn'
import { toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import { useParams } from 'react-router-dom'

const Editconversionagency = ({ setmodal, id, defdata ,isdonefun}) => {

    const token = localStorage.getItem('token')

    const { platformurl } = useParams()
    const [loading, setloading] = useState(false)

    const [data, setdata] = useState({
        goal_revenue: '',
        goal_payout: '',
        note: ''
    })

    useEffect(() => {
        defdata?.length && setdata({ ...data, goal_payout: defdata[1], goal_revenue: defdata[0] })
    }, [id])

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setdata({ ...data, [name]: value })
    }

    const handleUpdate = async () => {

        if (data.goal_revenue !== '') {

            if (data.goal_payout !== '') {

                if (data.note !== '') {

                    setloading(true)
                    await gpeapi.put(`/${platformurl}/agency/updateconversionrate/${id}`, data, {
                        headers: {
                            "Authorization": `Beaer ${token}`
                        }
                    }).then((res) => {
                        if (res.data.success) {
                            toast.success(res.data.msg)
                            setmodal(false)
                            isdonefun && isdonefun()


                        }

                    }).catch((e) => {
                        toast.error(e?.response?.data?.msg)

                    })
                    setloading(false)



                } else {
                    toast.warn('Please enter NOTE')
                }
            } else {
                toast.warn('Please enter Payout')
            }
        } else {
            toast.warn('Please enter Revenue')
        }
    }
    return (

        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        {id ? 'Update' : 'Add'} Conversion

                    </p>
                    <p>Deatils</p>

                </div>


                <div className="p-6 ">




                    <Myinput type={'number'} label={'Revenue'} width={'100%'} name={'goal_revenue'} value={data.goal_revenue} onChange={handleChnage} placeholder={'Goal Revenue'} />

                    <Myinput type={'number'} label={'Payout'} width={'100%'} name={'goal_payout'} value={data.goal_payout} onChange={handleChnage} placeholder={'Goal Payout'} />

                    <Myinput type={'text'} multiline={true} label={'Note'} width={'100%'} name={'note'} value={data.note} onChange={handleChnage} placeholder={'Note'} />



                    <div>

                        <Mybtn loading={loading} onClick={handleUpdate} title={'Update Conversion'} />
                    </div>




                </div>
            </div>

        </div>
    )
}

export default Editconversionagency


