
import { Option, Select, Tooltip } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../../../../../../apis/gpeapi'
import Mybtn from '../../../../../../../../COmponents/Mybtn'
import Myinput from '../../../../../../../../COmponents/Myinput'
import Myselect from '../../../../../../../../COmponents/Myselect'

import { Label, Radio } from 'flowbite-react';
import { COLORS } from '../../../../../../../../Constants'




const Addpostbackpub = ({ setmodal, id, mygoals }) => {

    const token = localStorage.getItem('token')

    // console.log(mygoals)

    const dispatch = useDispatch()
    const { platformurl,pubid } = useParams()
    const [loading, setloading] = useState(false)

    function makeid(length) {
        let result = '';
        const characters = '1234567890123456789012345678901234567890abcdefghi0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const macros = [

        { params: '{p1}', content: 'Tracking Parameter 1 used to track your publishers software click id' },
        { params: '{p2}', content: 'Tracking Parameter 2' },
        { params: '{p3}', content: 'Tracking Parameter 3' },
        { params: '{p4}', content: 'Tracking Parameter 4' },
        { params: '{gaid}', content: 'GAID' },
    ]

    const [mydta, setmydta] = useState({
        id: '',
        type: 'postback_url',
        event: '',
        data: '',
        goal: ''

    })

    const [event, setevent] = useState([
        { name: 'Conversion', value: 'conversion' },
    ])

    const [mgoal, setmgoal] = useState([
        { label: 'ALL', value: 'all' }
    ])


    const isValidURL = (string) => {
        try { return Boolean(new URL(string)); }
        catch (e) { return false; }
    }





    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }


    const mmdata = []

    useEffect(() => {

        setmydta({ ...mydta, id: makeid(12) })
        if (mygoals?.length) {
            setevent([{ name: 'Conversion', value: 'conversion' }, { name: 'Goal', value: 'goal' }])
            mygoals?.map((val, i) => {
                mmdata.push({ label: val?.title, value: val?.value })
            

            })

            setmgoal(mmdata)

        }
    }, [])

 







    const handleCreateAdv = async () => {

        if (mydta.event !== '') {

            if (isValidURL(mydta.data)) {
                setloading(true)

                if (mydta.event == 'goal') {
                    if (mydta.goal !== '') {
                        const res = await gpeapi.post(`/${platformurl}/${pubid}/admpubagn/addpostbackpub/${id}`, { postbacks: mydta }, {
                            headers: {
                                "Authorization": `Beaer ${token}`
                            }
                        }).then((ress) => {
                            toast.success(ress.data.msg)
                            setmodal(false)
                            
                        }).catch((e) => {
                            toast.error(e?.response?.data?.msg)

                        })
                    } else {
                        toast.warning('Please Select Goal')
                    }

                } else {
                    const res = await gpeapi.post(`/${platformurl}/${pubid}/admpubagn/addpostbackpub/${id}`, { postbacks: mydta }, {
                        headers: {
                            "Authorization": `Beaer ${token}`
                        }
                    }).then((ress) => {
                        toast.success(ress.data.msg)
                        setmodal(false)
                        
                    }).catch((e) => {
                        toast.error(e?.response?.data?.msg)

                    })

                }
                setloading(false)

            } else {
                toast.warning('Please Enter Valid URL')

            }

            // setloading(true)
            // const res = await gpeapi.post(`/${platformurl}/addpostbackpub/${id}`, { postbacks: mydta }, {
            //     headers: {
            //         "Authorization": `Beaer ${token}`
            //     }
            // }).then((ress) => {

            //     toast.success(ress.data.msg)
            //     window.location.reload('')
            // }).catch((e) => {
            //     toast.error(e?.response?.data?.msg)

            // })
            // setloading(false)

        } else {
            toast.warning('Please Select Event')
        }

    }

    // console.log(mydta)
    return (


        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        Add New PostBack

                    </p>

                    <p>*Postback-request will be send to specified URL using method GET.</p>


                </div>
                <div className="p-2 ">

                    <div className='grid grid-cols-2 gap-1 m-8 mt-1 mb-4'>
                        <label htmlFor="default-input" class="block  text- text-left font-medium text-gray-900 dark:text-gray-300">Event</label>
                        <div className="flex items-center gap-2">
                            {event?.map((val) => (
                                <>
                                    <Radio
                                        checked={mydta.event == val.value}

                                        defaultValue={mydta.event}
                                        id={val.value}
                                        name='objectivedta'
                                        value={val.value}
                                        onChange={(e) => setmydta({ ...mydta, event: e.target.value })}
                                    />
                                    <Label className='text-sm font-normal' htmlFor="united-state">
                                        {val.name}
                                    </Label>


                                </>

                            ))}

                        </div>
                    </div>

                    {
                        mydta?.event == 'goal' &&
                        <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1'>

                            <Myselect width={'100%'} label={'GOAL'} required={true} value={mydta.goal} onChange={(e) => handleChnageSelect(e, 'goal')} data={mgoal} />
                        </div>

                    }

                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1'>

                        <Myselect width={'100%'} label={'Type'} required={true} value={mydta.type} onChange={(e) => handleChnageSelect(e, 'type')} data={[{ label: 'Postback URL', value: 'postback_url' }]} />
                    </div>

                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1 '>



                        <Myinput width={'100%'} label={'Data'} required={true} multiline={true} onChange={handleChnage} name={'data'} value={mydta.data} />


                    </div>




                    <div className='grid grid-cols-1 gap-1 m-8 mt-1 mb-1'>

                        <div>
                            <p className='font-medium m-2 text-xs'>Most Used URL tokens</p>

                            <div style={{ width: '200%' }} className='flex flex-wrap'>

                                {
                                    macros?.map((val, i) => (
                                        <Tooltip key={i} content={val.content}>


                                            <span onClick={() => setmydta({ ...mydta, data: mydta.data + val.params })} className='text-sm cursor-pointer p-1 m-1 pl-4 pr-4 bg-[#ebeced] rounded-xl border-2' key={i}>{val.params}</span>
                                        </Tooltip>
                                    ))
                                }
                            </div>


                        </div>
                    </div>

                </div>








                <Mybtn loading={loading} onClick={handleCreateAdv} title={'Save'} />
            </div>
        </div>



    )
}

export default Addpostbackpub