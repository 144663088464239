import { Option, Select } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import gpeapi from '../../../../../../apis/gpeapi'
import Mybtn from '../../../../../../COmponents/Mybtn'
import Myinput from '../../../../../../COmponents/Myinput'
import Myselect from '../../../../../../COmponents/Myselect'
import NewMySelect from '../../../../../../COmponents/NewMySelect'
import { COLORS } from '../../../../../../Constants'


const Addadvertiseragency = ({ setmodal, id }) => {

    const token = localStorage.getItem('token')

    const dispatch = useDispatch()
    const { platformurl } = useParams()
    const [loading, setloading] = useState(false)
    const ac_manag = [
        { label: 'Manish Singh', value: `12- Manish Singh` },
        { label: 'Rahul Singh', value: `15- Rahul Singh` },
    ]

    const ac_statusdta = [
        { label: 'Active', value: 'active' },
        { label: 'Pending', value: 'pending' },
        { label: 'Disabled', value: 'disabled' },
        { label: 'Rejected', value: 'rejected' },
    ]

    const [mydta, setmydta] = useState({
        name: '',
        email: '',
        password: '',
        mobile: '',
        company: '',
        refid: '',
        notes: '',
        ac_manager: '',
        country: '',
        ac_status: '',
        isemailverified: true

    })

    useEffect(() => {
        id && updateForm()
    }, [id])

    // console.log(mydta)

    const updateForm = async () => {
        const res = await gpeapi.get(`/${platformurl}/adm/getadvertisers/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress.data?.user)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))

    }

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }

    const handleUpdate = async () => {
        setloading(true)
        await gpeapi.put(`/${platformurl}/adm/updateadvertiser/${id}`, mydta,{headers:{
            "Authorization":`Beaer ${token}`
        }}).then((res) => {
            if (res.data.success) {
                toast.success('Advertiser Deatils Updated')
                setmodal(false)
                


            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const handleCreateAdv = async () => {

        if (mydta.name !== '') {
            if (mydta.email !== '') {
                if (mydta.mobile !== '') {
                    if (mydta.password !== '') {
                        setloading(true)
                        await gpeapi.post(`/${platformurl}/advsignup`, mydta).then((res) => {
                            if (res.data.success) {
                                toast.success('Advertiser Account Created Successful')
                                setmodal(false)
                                


                            }

                        }).catch((e) => {
                            toast.error(e?.response?.data?.msg)

                        })
                        setloading(false)


                    } else {
                        toast.warning('Please Enter Password')
                    }

                } else {
                    toast.warning('Please Enter Mobile Number')
                }

            } else {
                toast.warning('Please Enter Email ID')
            }

        } else {
            toast.warning('Please Enter Full Name')
        }

    }

    // console.log(mydta)
    return (


        <div className="flex  flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">

            <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                <div className="flex flex-col items-start justify-center m-4">
                    <p style={{ color: COLORS.primary }} className="font-bold flex items-start mb-6 text-2xl  text-gray-900 dark:text-white">
                        {id ? 'Update' : 'Add'} an Advertiser

                    </p>
                    <p>Deatils</p>

                </div>


                <div className="p-6 ">




                    <Myinput type={'text'} label={'Full Name'} width={'100%'} name={'name'} value={mydta.name} onChange={handleChnage} required={true} placeholder={'The name of your advertiser/website/resource'} />

                    <Myinput type={'email'} label={'Email ID'} width={'100%'} name={'email'} value={mydta.email} onChange={handleChnage} required={true} placeholder={'Enter Unique email of your advertiser'} />

                    <Myinput label={'Mobile Number'} width={'100%'} name={'mobile'} value={mydta.mobile} onChange={handleChnage} required={true} placeholder={'Enter Mobile Number of your advertiser'} />

                    <Myinput label={'Password'} type={'password'} width={'100%'} name={'password'} value={mydta.password} onChange={handleChnage} required={true} placeholder={'Enter advertiser account password'} />

                    <Myselect value={mydta.ac_status} data={ac_statusdta} label={'Account Status'} width={'100%'} placeholder={'Select Account Status'} name={'ac_status'} onChange={(e) => handleChnageSelect(e, 'ac_status')} />

                    {/* <Myselect value={mydta?.ac_manager} data={ac_manag} label={'Account Manager'} width={'100%'} name={'ac_manager'} placeholder='Select Account Manager' onChange={(e) => handleChnageSelect(e, 'ac_manager')} /> */}


                    

                    <Myinput label={'Company Name'} width={'100%'} name={'company'} value={mydta.company} onChange={handleChnage} placeholder={'Company/Organization name'} />

                    <Myinput label={'Reference ID'} width={'100%'} name={'refid'} value={mydta.refid} onChange={handleChnage} placeholder={'Reference ID'} />

                    <Myinput multiline={true} tips={'The content will not be displayed to advertiser or publisher'} label={'Notes'} width={'100%'} name={'notes'} value={mydta.notes} onChange={handleChnage} />



                </div>
                {
                    id ? <Mybtn loading={loading} onClick={handleUpdate} title={'Update Advertiser'} />
                        : <Mybtn loading={loading} onClick={handleCreateAdv} title={'Create Advertiser'} />
                }
            </div>
        </div>



    )
}

export default Addadvertiseragency