

import { Spinner, Typography } from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Myselect from '../../../COmponents/Myselect'
import { Authcontext } from '../../../Context/Authcontext'
import { fetchpricing } from '../../../Redux/Actions/pricingaction'

const Addpricing = () => {
    const { toggle } = useContext(Authcontext)
const navigate = useNavigate()
    const { id } = useParams()
    const token = localStorage.getItem('token')
    const [loading, setloading] = useState(false)
    const [dataloading, setdataloading] = useState(false)


    const dispatch = useDispatch()




    const [mydta, setmydta] = useState({
        name: '',
        price: '',
        des: '',
        days: '',
        isfree: '',
        conversions: '',
        clicks: '',
        offers: '',
        publishers: '',
        advertisers: '',
        employees:'',
        iscustom:'',
        note:''


    })

    const ac_statusdta = [
        { label: 'True', value: true },
        { label: 'False', value: false },


    ]

    useEffect(() => {
        id && updateForm()
    }, [])



    const updateForm = async () => {
        setdataloading(true)
        const res = await gpeapi.get(`/getpricing/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress.data?.signupamt)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))
        setdataloading(false)
    }

    const handleChnageSelect = (e, name) => {
        setmydta({ ...mydta, [name]: e })

    }


    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const handlecreateCamp = async () => {
        if (mydta.name !== '') {
            if (mydta.days !== '') {
                if (mydta.isfree !== '') {
                    if (mydta.price !== '') {
                        if (mydta.conversions !== '') {
                            setloading(true)
                            await gpeapi.post(`/createpricing`, mydta, {
                                headers: {
                                    "Authorization": `Beaer ${token}`
                                }
                            }).then((res) => {
                                if (res.data.success) {
                                    toast.success('Pricing Created Successful')
                                    navigate('/offerborn/amd/admin/pricing')
                                    dispatch(fetchpricing())


                                }

                            }).catch((e) => {
                                toast.error(e?.response?.data?.msg)

                            })
                            setloading(false)

                        } else {
                            toast.warning('Enter Conversion')
                        }

                    } else {
                        toast.warning('Enter Price')
                    }

                } else {
                    toast.warning('Select is Free ')
                }

            } else {
                toast.warning('Enter Days')
            }

        } else {
            toast.warning('Enter Name')
        }

    }

    const handleupdateCamp = async () => {
        setloading(true)
        await gpeapi.put(`/updatepricing/${id}`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                toast.success('Pricing Updated Successful')
                navigate('/offerborn/amd/admin/pricing')

                dispatch(fetchpricing())
            }

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    // console.log(mydta)


    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />


            <p className='text-2xl font-medium'> {id ? 'UPDATE' : 'CREATE'} PRICING</p>





            {dataloading ? <div className='flex justify-center items-center'>
                <Spinner className="h-10 w-10  text-blue-500/10" />
            </div>
                :
                <div className='bg-white rounded-md p-2 mt-3'>
                    <p>Details</p>



                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Pricing Name <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.name} name='name' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Price <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.price} name='price' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Description (Optional) </p>

                        <Myinput line={4} multiline={true} width={'200%'} onChange={handleChnage} value={mydta.des} name='des' />

                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Note -</p>

                        <Myinput line={6} multiline={true} width={'200%'} onChange={handleChnage} value={mydta.note} name='note' />

                    </div>
                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Days<span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.days} name='days' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Conversions <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.conversions} name='conversions' />


                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Clicks <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.clicks} name='clicks' />


                    </div>
                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Offers <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.offers} name='offers' />


                    </div>
                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Publishers <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.publishers} name='publishers' />


                    </div>
                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Advertisers <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.advertisers} name='advertisers' />
                    </div>
                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>Employees <span className='text-red-600 font-bold'>*</span></p>

                        <Myinput width={'200%'} onChange={handleChnage} value={mydta.employees} name='employees' />
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>is Custom</p>
                        <Myselect value={mydta?.iscustom} data={ac_statusdta} width={'200%'} placeholder={'Select iscustom'} name={'iscustom'} onChange={(e) => handleChnageSelect(e, 'iscustom')} />
                    </div>

                    <div className='grid grid-cols-4 gap-4 m-8 mt-0 mb-0 items-center'>
                        <p>is Free</p>
                        <Myselect value={mydta?.isfree} data={ac_statusdta} width={'200%'} placeholder={'Select isfree'} name={'isfree'} onChange={(e) => handleChnageSelect(e, 'isfree')} />
                    </div>


                    {
                        id ? <Mybtn loading={loading} onClick={handleupdateCamp} title={'Update '} />
                            : <Mybtn loading={loading} onClick={handlecreateCamp} title={'Create '} />
                    }







                </div>
            }
        </div>
    )
}

export default Addpricing