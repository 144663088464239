import axios from 'axios'
import gpeapi from '../../apis/gpeapi';

import {SET_BASIC, SET_PRICING } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fetchpricing = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/getpricing',{headers:{
            "Authorization":`Beaer ${token}`
        }})

      

        dispatch(setBasic(res.data.signupamt));
       
    }
}






export const setBasic = (data = null) => {
    if (data) {
        return {
            type: SET_PRICING,
            payload: data,
        };
    }

    return {
        type: SET_PRICING,
        payload: [],
    };
};


