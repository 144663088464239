import React, { useState, useEffect } from 'react';

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return(
        <div className='bg-white w-screen h-screen flex justify-center items-center'>

            <div>

                <img src='https://helpdeskgeek.com/wp-content/pictures/2020/06/no-internet.png'/>
               <p className='text-center'>No Internet Connection</p>

                {/* <div>No Internet Connection</div> */}

            </div>



        </div>

        // <h1>no internet connection</h1>
        )
    }
}

// const NoInternetConnection = () => {
//     return (
//         <div className='bg-white w-screen h-screen flex justify-center items-center'>

//             <div>

//                 <img src='https://helpdeskgeek.com/wp-content/pictures/2020/06/no-internet.png' />

//                 <p className='text-center'>No Internet Connection</p>

//                 {/* <div>No Internet Connection</div> */}

//             </div>



//         </div>
//     )
// }



export default NoInternetConnection;

