import React from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { COLORS } from '../../Constants'

const Ourmission = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    // document.title = `Our Mission - OfferBorn`
    return (

        <section style={{ marginTop: 50, backgroundColor: 'white' }} class="section contact" id="contact" aria-label="contact">
            <ToastContainer />
            <div class="container">


                <h2 class="text-lg section-title font-bold" style={{ color: COLORS.primary }}>Our Mission</h2>

                <p class="text-xl text-center">
                    Wanna Build Your Own Performance Advertising Network？
                    That's Exactly What OfferBorn Can Help
                </p>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src={require('../img/spel1.webp')} width="500" height="200" alt="hero banner" class=" " />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Control All Your Ad Campaigns in One Place</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                <ol style={{ listStyleType: 'decimal' }}>
                                    <li>Track the affiliate programs in a smart way</li>
                                    <li>Analyze real-time data using customized reports</li>
                                    <li>Optimize ad campaigns with our smart tools</li>
                                </ol>

                            </p>



                        </div>



                    </div>
                </section>

                <section class="section hero" id="home" aria-label="hero">
                    <div class="container " style={{ direction: 'rtl' }}>

                        <figure class="hero-banner">
                            <img src={require('../img/spel2.webp')} width="500" height="200" alt="hero banner" class="" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Automate Ad Process for Greater Efficiency</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                <ol>
                                    <li>Master everything by using built-in automizers</li>
                                    <li>Streamline workflows with no unnecessary operations</li>
                                    <li>Improve work efficiency and save your time</li>
                                </ol>
                            </p>



                        </div>



                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src={require('../img/spel3.png')} width="500" height="200" alt="hero banner" class="" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Build Your Brand with Stable Partners</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                <ol style={{ listStyleType: 'decimal' }}>
                                    <li>A customizable SaaS platform to meet all needs</li>
                                    <li>High profitability for both you and your partners</li>
                                    <li>Gain partners' trust through high security</li>
                                </ol>

                            </p>



                        </div>



                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container " style={{ direction: 'rtl' }}>

                        <figure class="hero-banner">
                            <img src={require('../img/spel4.png')} width="500" height="200" alt="hero banner" class="" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Server to Server(S2S) Integration (Postback)</h1>
                           
                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                <ol>
                                    <li>We support all Server-to-Server integration with all third-party platform. Our developers work directly with partners to provide wide range of services.</li>
                                    <br/>
                                   
                                        <li>Appsflyer</li>
                                        <li>Adjust</li>
                                        <li>Branch</li>
                                        <li>Kochava</li>
                                        
                                   
                                    
                                </ol>
                            </p>



                        </div>



                    </div>
                </section>



                <section class="section hero" id="home" aria-label="hero">
                    <div class="container">

                        <figure class="hero-banner">
                            <img src={require('../img/spel5.png')} width="500" height="200" alt="hero banner" class="" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>Chat with Your Publishers,Advertisers</h1>

                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                <ol style={{ listStyleType: 'decimal' }}>
                                    <li className='capitalize'>in our Platform we have future you can directly chat with your publishers or advertisers and you will we notifed when publisher or advertiser message come ,this is amazing future in our platform</li>
                                  
                                </ol>

                            </p>



                        </div>



                    </div>
                </section>


                <section class="section hero" id="home" aria-label="hero">
                    <div class="container " style={{ direction: 'rtl' }}>

                        <figure class="hero-banner">
                            <img src={require('../img/spel6.png')} width="500" height="200" alt="hero banner" class="" />
                        </figure>

                        <div class="hero-content">



                            <h1 class="h3 hero-title font-bold" style={{ color: COLORS.primary, fontSize: 30 }}>With One Click Invoice Create </h1>
                           
                            <p class="text-lg font-medium " style={{ color: COLORS.primary }}>
                                <ol>
                                    <li className='capitalize'>This is amazing future Invoice generator with one click,your publisher can create invoice with one click with all campaigns runing in last month and send to you (agency), agency will get all publishers invoice in Publishers invoices option </li>
                                    
                                   
                                       
                                        
                                   
                                    
                                </ol>
                            </p>



                        </div>



                    </div>
                </section>
              














            </div>
        </section>
    )
}

export default Ourmission