import { SET_AGENCYADV, SET_AGENCYCAMP, SET_AGENCYPUB } from "../../actiontypes/usertypes";


const campaignAgencyReducer = (state=[],action)=>{
    switch (action.type) {
        case SET_AGENCYCAMP:
            return [...action.payload]
            break;

           
    
        default:
           return state;
    }
}

export default campaignAgencyReducer