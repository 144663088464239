import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'
import { Link } from 'react-router-dom'
import {
  CaretDownOutlined,RiseOutlined,BugOutlined,ApartmentOutlined,FileDoneOutlined ,SoundOutlined
} from '@ant-design/icons';
// import '../assets/img/gwalaxmedia-logo.png'
// import  mstyle from '../../mgloble.module.css'


const Headerweb = () => {

  const basicData = useSelector((state) => state.basicReducer)[0]



  const [open, setopen] = useState(false)
  // console.log(open)

  const [dropopen, setdropopen] = useState({
    name: '',
    index: '',
    open: false
  })

  return (
    <header className="header" data-header>
      <div className="container">

        <a href='/' className="logo"><img classNameName='logoimg' style={{ height: 58, width: 250 }} src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1696658866/offerborn/logos/offerborn_big_logo_main_transparent_laql5c.png' /></a>



        <nav className={`navbar container  ${open && 'active'}`} data-navbar>
          <ul className="navbar-list">

            <li>
              <div onMouseOver={() => setdropopen({ index: 0, name: 'Product & Solutions', open: true })} onMouseOut={() => setdropopen({ index: 0, name: 'Product & Solutions', open: false })}>


                <Link  className="navbar-link " style={{ fontWeight: 'bolder', color: COLORS.primary }} data-nav-link>Product & Solutions {<CaretDownOutlined className='align-middle' />}</Link>
                {
                  dropopen?.name == 'Product & Solutions' && dropopen.open == true &&
                  <div className='shadow-md py-7 px-10 rounded-xl' style={{ position: 'absolute', backgroundColor: 'white', marginLeft: -20 }}>
                    <a href='/tracking' className='text-lg cursor-pointer my-0 navbar-link flex flex-row  items-center'>{<RiseOutlined className='align-middle mr-2' />}Tracking & Analytics</a>
                    <a href='/powerful-tool' className='text-lg cursor-pointer my-2 navbar-link flex flex-row  items-center'><ApartmentOutlined className='align-middle mr-2'/>Powerful Tools</a>
                    <a href='/anti-fraud' className='text-lg cursor-pointer my-2 navbar-link flex flex-row  items-center'> <BugOutlined className='align-middle mr-2'/>Anti Fraud</a>
                    <a href='/reports-management' className='text-lg cursor-pointer my-2 navbar-link flex flex-row  items-center'><FileDoneOutlined className='align-middle mr-2'/>Reports & Communication</a>
                    <a href='/offer-management' className='text-lg cursor-pointer my-2 navbar-link flex flex-row  items-center'><SoundOutlined className='align-middle mr-2'/>Campaign Management</a>

                  </div>
                }

              </div>

            </li>

            <li>
              <a onClick={() => setopen(false)} href="/service" style={{ fontWeight: 'bolder', color: COLORS.primary }} className="navbar-link" data-nav-link>Services</a>
            </li>

            <li>
              <a onClick={() => setopen(false)} href="/price" style={{ fontWeight: 'bolder', color: COLORS.primary }} className="navbar-link" data-nav-link>Pricing</a>
            </li>
            {/* 
          <li>
            <a href="#project" className="navbar-link" data-nav-link>About us</a>
          </li> */}

            <li>
              <a onClick={() => setopen(false)} href="/about" style={{ fontWeight: 'bolder', color: COLORS.primary }} className="navbar-link" data-nav-link>About Us</a>
            </li>

            {/* <li>
            <a href="#blog" className="navbar-link" data-nav-link>Blog</a>
          </li> */}

            <li>
              <a onClick={() => setopen(false)} href="/contact" style={{ fontWeight: 'bolder', color: COLORS.primary }} className="navbar-link" data-nav-link>Contact Us</a>
            </li>

            <li>
              <a onClick={() => setopen(false)} href="/signup" style={{ borderRadius: 100 }} className="btn btn-primary">Free Trial</a>
            </li>

          </ul>
        </nav>

        <button onClick={() => setopen(!open)} className="nav-toggle-btn" aria-label="Toggle menu" data-nav-toggler>


          {
            open ? <ion-icon name="close-outline" className="close"></ion-icon> : <ion-icon name="menu-outline" className="open"></ion-icon>
          }
        </button>

      </div>
    </header>
  )
}

export default Headerweb

